import React, { Component } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'react-apollo';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import CloseIcon from 'mdi-material-ui/Close';
import CancelIcon from 'mdi-material-ui/Cancel';

import OnMutationEvent from './OnMutationEvent';
import MutationSnackbars from './MutationSnackbars';

const styles = theme => ({
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    margin: theme.spacing.unit * 2,
    '& > *': {
      marginRight: theme.spacing.unit * 2,
    },
    '& > *:last-child': {
      marginRight: 0,
    },
  },
  cancelButton: {
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacing.unit * 0,
    right: theme.spacing.unit * 0,
  },
  content: {
    paddingTop: theme.spacing.unit * 1,
  },
});

class MutationDialog extends Component {
  static propTypes = {
    onClose: PropTypes.func,
    onOpen: PropTypes.func,
    dialogProps: PropTypes.object,
    trigger: PropTypes.node,
    title: PropTypes.string,
    classes: PropTypes.object.isRequired,
    fullScreen: PropTypes.bool.isRequired,
    children: PropTypes.node,
    actions: PropTypes.node,
    mutationProvider: PropTypes.node.isRequired,
    showCancelButton: PropTypes.bool,
    showCloseButton: PropTypes.bool,
    closeOnSuccess: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    maxWidth: PropTypes.string,
    disableBackdropClick: PropTypes.bool,
    disableEscapeKeyDown: PropTypes.bool,
    open: PropTypes.bool,
  }

  static defaultProps = {
    onOpen: null,
    showCancelButton: true,
    showCloseButton: true,
    closeOnSuccess: true,
    maxWidth: 'md',
    disableBackdropClick: true,
    disableEscapeKeyDown: true,
    onClose: null,
    dialogProps: null,
    title: '',
    children: null,
    actions: null,
    open: undefined,
    trigger: null,
  }

  constructor(props, context) {
    super(props, context);

    this.state = {
      open: false,
    };
  }

  handleOpen = () => {
    const { onOpen } = this.props;
    this.setState({ open: true });
    if (onOpen) onOpen();
  };

  handleClose = () => {
    const { onClose } = this.props;
    this.setState({ open: false });
    if (onClose) onClose();
  };

  handleSuccess = (mutation, result) => {
    const { closeOnSuccess } = this.props;
    if (typeof closeOnSuccess === 'function') {
      if (closeOnSuccess(mutation, result)) {
        this.handleClose();
      }
    } else if (closeOnSuccess) {
      this.handleClose();
    }
  };

  render() {
    const { open: stateOpen } = this.state;
    const { open: propsOpen, disableBackdropClick, disableEscapeKeyDown, dialogProps, maxWidth, showCloseButton, showCancelButton, classes, fullScreen, trigger, title, children, actions, mutationProvider } = this.props;
    const open = propsOpen !== undefined ? propsOpen : stateOpen;

    return (
      <>
        {trigger ? React.cloneElement(trigger, { onClick: this.handleOpen }) : null}
        <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={this.handleClose}
          scroll="paper"
          maxWidth={maxWidth}
          {...dialogProps}
          disableEscapeKeyDown={disableEscapeKeyDown}
          disableBackdropClick={disableBackdropClick}
        >
          {React.cloneElement(mutationProvider, null, (
            <>
              <OnMutationEvent onSuccess={this.handleSuccess} />
              {showCloseButton ? <IconButton className={classes.closeButton} onClick={this.handleClose}><CloseIcon /></IconButton> : null}
              <DialogTitle>{title}</DialogTitle>
              <DialogContent className={classes.content}>
                {children}
              </DialogContent>
              <DialogActions className={classes.actions}>
                {showCancelButton
                  ? (
                    <Button className={classes.cancelButton} variant="outlined" onClick={this.handleClose}>
                      <CancelIcon />
                      <span>Cancel</span>
                    </Button>
                  )
                  : null}
                {actions}
              </DialogActions>
            </>
          ))}
        </Dialog>
      </>
    );
  }
}

MutationDialog = compose(
  withMobileDialog(),
  withStyles(styles),
)(MutationDialog);

export default MutationDialog;
