export default {
  types: {
    staircase_set: {

      renderers: {
        inline: node => node.label,
      },

      selections: {
        default: 'id label config staircases{id staircase{id label}} users{id user{id displayName email role{id name label}}}',
      },

      fields: {
        label: {
          label: 'Title',
          type: 'String',
        },
        config: {
          type: 'Json',
        },
        staircases: {
          type: 'staircase',
          hasMany: true,
        },
        users: {
          type: 'user',
          hasMany: true,
        },
      },
    },
  },
};
