import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

import { MutationContext } from './MutationContext';

export default class MutateButton extends Component {
  static contextType = MutationContext;

  static propTypes = {
    mutationName: PropTypes.string.isRequired,
    mutationMeta: PropTypes.object,
    children: PropTypes.node,
  }

  static defaultProps = {
    mutationMeta: null,
    children: null,
  }


  handleClick = () => {
    const { mutate } = this.context;
    const { mutationName, mutationMeta } = this.props;
    mutate(mutationName, mutationMeta);
  }

  render() {
    const { mutationName, children, mutationMeta, ...rest } = this.props;
    const { pendingMutation } = this.context;

    return (
      <Button onClick={this.handleClick} variant="contained" color="primary" disabled={pendingMutation} {...rest}>
        {children}
      </Button>
    );
  }
}
