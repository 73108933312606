import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import AdminList from '/components/AdminList';
import { Helmet } from 'react-helmet';

const styles = theme => ({
  root: {
    width: '100%',
  },
});

class StaircaseSetListPage extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    onTitleChange: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    props.onTitleChange('Staircase Sets');
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Helmet>
          <title>Staircase Sets</title>
        </Helmet>

        <AdminList
          type="staircase_set"
          likeFilterProps={{ path: 'label', label: 'Search by title' }}
          renderTitle={node => `${node.label}`}
          editUrl={node => `/admin/staircase-sets/${node.id}/edit`}
          viewUrl={node => `/admin/staircase-sets/${node.id}/edit`}
          insertUrl="/admin/staircase-sets/new/insert"
          insertTitle="Create new Staircase Set"
          showViewButton={false}
          showEditButton={false}
        />;
      </div>
    );
  }
}

StaircaseSetListPage = compose(
  withStyles(styles),
)(StaircaseSetListPage);

export default StaircaseSetListPage;
