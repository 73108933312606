import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import _ from 'lodash';

import EditorSection from './EditorSection';
import { MutationContext } from '/../../kiska/src/components/MutationContext/MutationContext'; // eslint-disable-line import/no-extraneous-dependencies
import TextField from '/../../kiska/src/components/MutationContext/FormFields/TextField'; // eslint-disable-line import/no-extraneous-dependencies
import FileUplaoder from '/../../kiska/src/components/MutationContext/FormFields/FileField'; // eslint-disable-line import/no-extraneous-dependencies
import BooleanField from '/../../kiska/src/components/MutationContext/FormFields/BooleanField';
import SelectField from '/../../kiska/src/components/MutationContext/FormFields/SelectField';

class StaircaseInheritedConfigEditor extends React.Component {
  static contextType = MutationContext

  static propTypes = {
    showForceInherit: PropTypes.bool,
  }

  static defaultProps = {
    showForceInherit: false,
  }

  render() {
    const { fields } = this.context;
    const { showForceInherit } = this.props;

    return (
      <Grid container spacing={16}>
        {/* Display & Timeouts */}
        <Grid item xs={12} md={6}>
          <EditorSection title="Display & Timing">
            <Grid container spacing={16}>
              {showForceInherit ? (
                <Grid item xs={12}>
                  <BooleanField name="config.inheritDisplayConfig" label="Force all staircases to inherit these settings" initialValue />
                </Grid>
              ) : null}

              <Grid item xs={8}>
                <TextField
                  name="config.keyFaceTimeout"
                  label="Key Face Timeout"
                  helperText="Time until key face dissapears."
                  type="number"
                  initialValue={2000}
                  disabled={_.get(fields, 'config.value.neverKeyFaceTimeout')}
                  InputProps={{ endAdornment: <InputAdornment position="end">milliseconds</InputAdornment> }}
                />
              </Grid>
              <Grid item xs={4}>
                <BooleanField name="config.neverKeyFaceTimeout" label="Never" />
              </Grid>

              <Grid item xs={8}>
                <TextField
                  name="config.optionFaceTimeout"
                  label="Option Faces Timeout"
                  helperText="Time until option faces dissapear."
                  type="number"
                  initialValue={3000}
                  disabled={_.get(fields, 'config.value.neverOptionFaceTimeout')}
                  InputProps={{ endAdornment: <InputAdornment position="end">milliseconds</InputAdornment> }}
                />
              </Grid>
              <Grid item xs={4}>
                <BooleanField name="config.neverOptionFaceTimeout" label="Never" initialValue />
              </Grid>

              <Grid item xs={8}>
                <TextField
                  name="config.responseTimeout"
                  label="Response Timeout"
                  helperText={`Time after which the staircase advances even without the participant responding and the response is logged as "timeout" rather than "correct" or "incorrect"."`}
                  type="number"
                  initialValue={5000}
                  disabled={_.get(fields, 'config.value.neverResponseTimeout')}
                  InputProps={{ endAdornment: <InputAdornment position="end">milliseconds</InputAdornment> }}
                />
              </Grid>
              <Grid item xs={4}>
                <BooleanField name="config.neverResponseTimeout" label="Never" initialValue />
              </Grid>

              <Grid item xs={8}>
                <TextField
                  name="config.fadeInDuration"
                  label="Fade In Duration"
                  helperText="Time it takes the faces to appear from 0% opacity to 100% opacity."
                  type="number"
                  initialValue={150}
                  InputProps={{ endAdornment: <InputAdornment position="end">milliseconds</InputAdornment> }}
                />
              </Grid>

              <Grid item xs={8}>
                <TextField
                  name="config.fadeOutDuration"
                  label="Fade In Duration"
                  helperText="Time it takes the faces to disappear from 100% opacity to 0% opacity."
                  type="number"
                  initialValue={300}
                  InputProps={{ endAdornment: <InputAdornment position="end">milliseconds</InputAdornment> }}
                />
              </Grid>

              <Grid item xs={12}>
                <SelectField
                  name="config.optionSizes"
                  label="Option Faces Size Variations"
                  options={[
                    { value: '0', primary: 'No variation' },
                    { value: '-10,0,10', primary: '-10%, 0, +10%' },
                    { value: '-20,0,20', primary: '-20%, 0, +20%' },
                    { value: '-20,-10,0,10,20', primary: '-20%, -10%, 0, +10%, +20%' },
                    { value: '-30,0,30', primary: '-30%, 0, +30%' },
                    { value: '-30,-20,-10,0,10,20,30', primary: '-30%, -20%, -10%, 0, +10%, +20%, +30%' },
                    { value: '-40,-30,-20,-10,0,10,20,30,40', primary: '-40%, -30%, -20%, -10%, 0, +10%, +20%, +30%, +40%' },
                    { value: '-50,0,50', primary: '-50%, 0, +50%' },
                  ]}
                  helperText="Randomly vary the size of the option (bottom) faces by a percentage of the key (top) face."
                  initialValue="-10,0,10"
                />
              </Grid>

            </Grid>
          </EditorSection>
        </Grid>

        {/* Promotion & Demotion */}
        <Grid item xs={12} md={6}>
          <EditorSection title="Promotion & Demotion">
            <Grid container spacing={16}>
              {showForceInherit ? (
                <Grid item xs={12}>
                  <BooleanField name="config.inheritPromoDemoConfig" label="Force all staircases to inherit these settings" initialValue />
                </Grid>
              ) : null}

              <Grid item xs={12}>
                <SelectField
                  name="config.promotionMode"
                  label="Promotion Mode"
                  options={[
                    { value: 'total', primary: 'Total correct responses' },
                    { value: 'consecutive', primary: 'Consecutive correct responses' },
                  ]}
                  helperText="This mode affects how Promotion Threshold is interpreted."
                  initialValue="total"
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  name="config.promotionThreshold"
                  label="Promotion Threshold"
                  type="number"
                  helperText="Depending on mode, participant is promoted after this many total or consecutive correct responses."
                  initialValue={3}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  name="config.promotionSteps"
                  label="Promotion Steps"
                  type="number"
                  helperText="How many levels up the user is promoted upon reaching the promotion criteria."
                  initialValue={1}
                />
              </Grid>

              <Grid item xs={12}>
                <SelectField
                  name="config.demotionMode"
                  label="Demotion Mode"
                  options={[
                    { value: 'total', primary: 'Total incorrect responses' },
                    { value: 'consecutive', primary: 'Consecutive incorrect responses' },
                  ]}
                  helperText="This mode affects how Demotion Threshold is interpreted."
                  initialValue="total"
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  name="config.demotionThreshold"
                  label="Demotion Threshold"
                  type="number"
                  helperText="Depending on mode, participant is demoted after this many total or consecutive incorrect responses."
                  initialValue={1}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  name="config.demotionSteps"
                  label="Demotion Steps"
                  type="number"
                  helperText="How many levels down the user is demoted upon reaching the demotion criteria."
                  initialValue={3}
                />
              </Grid>

            </Grid>
          </EditorSection>
        </Grid>

        {/* Feedback */}
        <Grid item xs={12} md={6}>
          <EditorSection title="Feedback">
            <Grid container spacing={16}>
              {showForceInherit ? (
                <Grid item xs={12}>
                  <BooleanField name="config.inheritFeedbackConfig" label="Force all staircases to inherit these settings" initialValue />
                </Grid>
              ) : null}

              <Grid item xs={12}>
                <BooleanField
                  name="config.selectionFeedback"
                  label="Enable Selection Feedback"
                  helperText="Give feedback on which option face was selected."
                  initialValue
                />
              </Grid>
              <Grid item xs={12}>
                <BooleanField
                  name="config.correctFeedback"
                  label="Correct Feedback"
                  helperText="Show the participant when they select the correct option."
                  initialValue
                />
              </Grid>
              <Grid item xs={12}>
                <BooleanField
                  name="config.incorrectFeedback"
                  label="Incorrect Feedback"
                  helperText="Show the participant when they select the incorrect option."
                  initialValue
                />
              </Grid>

            </Grid>
          </EditorSection>
        </Grid>

        {/* Completion */}
        <Grid item xs={12} md={6}>
          <EditorSection title="Completion">
            <Grid container spacing={16}>
              {showForceInherit ? (
                <Grid item xs={12}>
                  <BooleanField name="config.inheritCompletionConfig" label="Force all staircases to inherit these settings" initialValue />
                </Grid>
              ) : null}

              <Grid item xs={12}>
                <TextField
                  name="config.reversalThreshold"
                  label="Reversal Threshold"
                  type="number"
                  helperText="Number of reversals of any level after which the completion countdown begins."
                  initialValue={12}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="config.postReversalThresholdTrialsCount"
                  label="Post Reversal Threshold Trials Count"
                  type="number"
                  helperText="Number of trials the must be completed after the reversal threshold is reached."
                  initialValue={200}
                />
              </Grid>

            </Grid>
          </EditorSection>
        </Grid>

      </Grid>
    );
  }
}

export default StaircaseInheritedConfigEditor;
