import { createMuiTheme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import green from '@material-ui/core/colors/green';
import orange from '@material-ui/core/colors/orange';

const defaultTheme = createMuiTheme({ typography: { useNextVariants: true } });

const commons = {
  typography: {
    useNextVariants: true,
  },

  palette: {
    primary: {
      ...blue,
      contrastText: '#FFF',
    },
    secondary: orange,
  },

  overrides: {
    MuiButton: {
      label: {
        '& svg:first-child:not(:only-child)': {
          marginRight: defaultTheme.spacing.unit * 1,
        },
      },
    },

    MuiTooltip: {
      tooltip: {
        fontSize: '1em',
        color: '#FFF',
        backgroundColor: 'rgba(0, 0, 0, .7)',
      },
    },
  },
};

const lightTheme = createMuiTheme({
  ...commons,
});

const darkTheme = createMuiTheme({
  ...commons,
  palette: {
    ...commons.palette,
    type: 'dark',
    primary: {
      ...orange,
      contrastText: 'rgba(0,0,0,.55)',
    },
    secondary: blue,
  },
});

const themes = {
  light: lightTheme,
  dark: darkTheme,
};

export { themes };
