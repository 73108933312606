import React, { Fragment } from 'react';
import _ from 'lodash';
import parseParenthesis from 'parenthesis';
// import formatDate from 'date-fns/format';
// import parseISO from 'date-fns/parseISO';

const kiskaDir = `${__dirname.slice(0, __dirname.lastIndexOf('kiska'))}kiska`;

const fetchJson = ({ url, body }) => new Promise((resolve, reject) => {
  window.fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
    credentials: 'include',
  })
    .then((response) => {
      response.json()
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          console.error(`Error fetching JSON from ${url}: `, error);
          reject(error);
        });
    })
    .catch((error) => {
      console.error(`Error fetching from URL ${url}: `, error);
      reject(error);
    });
});

const addIdsToSelections = (selections) => {
  if (selections.includes('id')) return selections;
  selections += ' id';
  selections = selections.replace(/}/g, ' id}');
  return selections;
};

const getTopLevelSelections = (selections) => {
  // Parse to nested arrays
  let selectionsArray = parseParenthesis(selections);
  selectionsArray = selectionsArray
    .filter(s => typeof s === 'string') // remove nested objects
    .map(s => s.replace(/[{}]/g, '')) // strip braces
    .reduce((acc, s) => [...acc, ...s.split(/\s/)], []) // merge
    .filter(s => !!s); // remove empties

  return selectionsArray;
};

const getRelationSchemaType = (field, type, schema) => {
  const relationSchemaType = _.get(schema, `types.${field.type}`);
  if (!relationSchemaType) throw new Error(`Cannot find schema type "${field.type}" for ${type.name}.${field.name}`);
  return relationSchemaType;
};

const getClosestDomParent = (elem, selector) => {
  for (; elem && elem !== document; elem = elem.parentNode) {
    if (elem.matches(selector)) return elem;
  }
  return null;
};

const replaceNewLinesWithBreaks = (str) => {
  if (!str) return '';
  return str.split('\n').map((line, index) => (
    <Fragment key={index}>
      {line}
      <br />
    </Fragment>
  ));
};


export {
  kiskaDir,
  addIdsToSelections, getTopLevelSelections,
  getRelationSchemaType,
  getClosestDomParent, replaceNewLinesWithBreaks,
  fetchJson,
};
