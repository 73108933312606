import React, { Component } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { compose, withApollo } from 'react-apollo';
import FormControl from '@material-ui/core/FormControl';
import NoSsr from '@material-ui/core/NoSsr';
import FormLabel from '@material-ui/core/FormLabel';
import { withStyles } from '@material-ui/core/styles';
import AsyncSelect from 'react-select/lib/Async';
import makeAnimated from 'react-select/lib/animated';
import _ from 'lodash';
import Fade from '@material-ui/core/Fade';

import { addIdsToSelections } from '../utils'; // eslint-disable-line import/no-extraneous-dependencies
import { SchemaContext } from './contexts/SchemaContext'; // eslint-disable-line import/no-extraneous-dependencies
import HelperText from './MutationContext/FormFields/HelperText';

const styles = theme => ({
  root: {
    width: '100%',
  },
  optionContent: {

  },
  formControlLabel: {
    marginBottom: theme.spacing.unit * 1,
  },
  selectRoot: {
    flex: 1,
  },
  row: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
});

const asyncSelectStyles = {
  menu: provided => ({
    ...provided,
    backgroundColor: 'rgba(255,255,255,1)',
  }),
  menuPortal: provided => ({
    ...provided,
    zIndex: 10000,
  }),
};

const defaultComponents = makeAnimated();

// defaultComponents.MultiValueContainer = compose(
//   withStyles(styles),
// )(({ innerProps, innerRef, children, classes, data }) => {
//   const { style: innerPropsStyle, ...restInnerProps } = innerProps;
//   const label = data ? data.label || data.title : '';
//   const charCount = label ? label.length : 20;
//   const style = {
//     width: `${charCount * 0.7}em`,
//     ...innerProps.style,
//   };

//   console.log('data', data);

//   return (
//     <div ref={innerRef} {...restInnerProps} style={style}>
//       hello:
//     </div>
//   );
// });

class NodeSelector extends Component {
  static contextType = SchemaContext;

  static propTypes = {
    type: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    where: PropTypes.object,
    searchFields: PropTypes.array,
    selections: PropTypes.string,
    renderOption: PropTypes.func,
    isMulti: PropTypes.bool,
    helperText: PropTypes.node,
    label: PropTypes.node,
    value: PropTypes.any,
    placeholder: PropTypes.node,
    autoWildcards: PropTypes.bool,
    orderBy: PropTypes.array,
    limit: PropTypes.number,
    client: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
  }

  static defaultProps = {
    renderOption: null,
    where: {},
    isMulti: false,
    helperText: '',
    label: '',
    value: undefined,
    placeholder: null,
    selections: '',
    searchFields: undefined,
    autoWildcards: true,
    orderBy: undefined,
    limit: 25,
  }

  static buildQuery = (props, context) => {
    const schema = context;
    const { type } = props;
    const schemaType = schema.types[type];
    let selections = props.selections || schemaType.selections.default;
    selections = addIdsToSelections(selections);

    const query = gql`
      query GetNodeSelectorOptions($where: ${type}_bool_exp!, $orderBy: [${type}_order_by!]){
        options: ${type}(where: $where, limit: ${props.limit}, order_by: $orderBy){
          ${selections}
        }
      }
    `;

    return query;
  }

  constructor(props, context) {
    super(props, context);
    const { type } = props;
    const schema = context;
    this.schemaType = schema.types[type];

    this.state = {
      inputValue: '',
      mountDelayFinished: false,
    };

    this.query = NodeSelector.buildQuery(props, context);

    this.searchFields = props.searchFields;
    if (!this.searchFields) {
      this.searchFields = _.filter(this.schemaType.fields, field => field.type === 'String').map(field => field.name);
    }

    if (this.schemaType.fields.updatedAt) {
      this.defaultOrderBy = [{ updatedAt: 'desc_nulls_last' }];
    } else {
      this.defaultOrderBy = [{ id: 'desc_nulls_last' }];
    }
  }

  componentDidMount = () => {
    setTimeout(() => this.setState({ mountDelayFinished: true }), 1000);
  }

  renderOptionInner = (option) => {
    const { renderOption } = this.props;

    if (renderOption) return renderOption(option);

    const isManyToMany = option.__typename.includes('__x__');
    if (isManyToMany) option = option[this.schemaType.name];

    const render = _.get(this.schemaType, 'renderers.inline');
    if (render) {
      const content = render(option);
      return content;
    }
    return option.title || option.name || `ID: ${option.id}` || '<no value>';
  }

  renderOption = (option) => {
    const { classes } = this.props;

    return (
      <span className={classes.optionContent}>
        {this.renderOptionInner(option)}
      </span>
    );
  }

  handleChange = (value) => {
    const { onChange } = this.props;
    onChange(value);
  }

  loadOptions = inputValue => new Promise(async (resolve, reject) => {
    const { type, where: fixedWhere, client, autoWildcards, orderBy } = this.props;


    if (autoWildcards) {
      inputValue = `%${inputValue}%`;
    }

    let where = {};

    if (inputValue && inputValue.length) {
      where._or = this.searchFields.map((field) => {
        const condition = {};
        _.set(condition, `${field}._ilike`, inputValue);
        return condition;
      });
    }

    where = { ...where, ...fixedWhere };

    const variables = {
      where,
      orderBy: orderBy || this.defaultOrderBy,
    };

    client.query({ query: this.query, variables })
      .then((result) => {
        resolve(result.data.options);
      })
      .catch((error) => {
        console.error(`Error fetching options for ${type}`, error);
        reject(error);
      });
  })

  getOptionValue = (option) => {
    const isManyToMany = option.__typename.includes('__x__');
    if (isManyToMany) option = option[this.schemaType.name];

    return option.id;
  }

  noOptionsMessage = ({ inputValue }) => (inputValue ? `No ${this.schemaType.pluralLabel} found.` : 'Type to search')

  getDefaultOptions = () => {
    // Could return an array here.
    // Return true to trigger react-select to call loadOptions
    return true;
  }

  render() {
    const { mountDelayFinished } = this.state;
    const { isMulti, label, value, classes, helperText, placeholder } = this.props;
    const cPlaceholder = placeholder || 'Type to search...';

    let finalLabel = label;
    if (label === undefined) {
      if (isMulti) finalLabel = this.schemaType.pluralLabel;
      else finalLabel = this.schemaType.label;
    }

    return (
      <FormControl className={classes.root}>
        <FormLabel className={classes.formControlLabel}>{finalLabel}</FormLabel>
        <div className={classes.row}>
          {mountDelayFinished ? (
            <Fade in={mountDelayFinished} timeout={1000} appear>
              <AsyncSelect
                appear
                className={classes.selectRoot}
                loadOptions={this.loadOptions}
                value={value}
                onChange={this.handleChange}
                components={defaultComponents}
                isMulti={isMulti}
                isClearable
                getOptionValue={this.getOptionValue}
                getOptionLabel={this.renderOption}
                placeholder={cPlaceholder}
                noOptionsMessage={this.noOptionsMessage}
                defaultOptions
                styles={asyncSelectStyles}
                menuPortalTarget={typeof document !== 'undefined' ? document.body : undefined}
              />
            </Fade>
          ) : null}
        </div>
        <HelperText text={helperText} errors={[]} />
      </FormControl>
    );
  }
}

NodeSelector = compose(
  withStyles(styles),
  withApollo,
)(NodeSelector);

export default NodeSelector;
