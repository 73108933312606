import React, { Component, createContext } from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import _ from 'lodash';

import { SchemaContext } from '../contexts/SchemaContext'; // eslint-disable-line import/no-extraneous-dependencies
import buildQuery from './buildQuery';
import { getTopLevelSelections, addIdsToSelections } from '../../utils';

const NodeListContext = createContext({});
const NodeListContextConsumer = NodeListContext.Consumer;

class NodeList extends Component {
  static contextType = SchemaContext;

  static propTypes = {
    type: PropTypes.string.isRequired,
    initialLimit: PropTypes.number,
    initialOffset: PropTypes.number,
    initialOrderBy: PropTypes.array,
    initialWhere: PropTypes.object,
    children: PropTypes.node,
    selections: PropTypes.string,
    selectionSet: PropTypes.string,
  }

  static defaultProps = {
    children: null,
    initialLimit: 25,
    initialOffset: 0,
    initialOrderBy: undefined,
    initialWhere: {},
    selections: '',
    selectionSet: 'default',
  }

  constructor(props, context) {
    super(props, context);

    const { type } = props;
    const schema = context;
    const schemaType = _.get(schema, `types[${type}]`);
    if (!schemaType) throw new Error(`Could not find schema for type "${type}".`);
    this.selections = props.selections || _.get(schemaType, `selections.${props.selectionSet}`);
    this.topLevelSelections = getTopLevelSelections(this.selections);

    let defaultOrderBy;
    if (schemaType.fields.updatedAt) {
      defaultOrderBy = [{ updatedAt: 'desc_nulls_last' }];
    } else {
      defaultOrderBy = [{ id: 'desc_nulls_last' }];
    }

    this.query = buildQuery({
      type,
      schema,
      selections: this.selections,
    });

    this.state = {
      limit: props.initialLimit,
      offset: props.initialOffset,
      where: JSON.parse(JSON.stringify(props.initialWhere)),
      orderBy: JSON.parse(JSON.stringify(props.initialOrderBy || defaultOrderBy)),
    };
  }

  setVariables = (cb) => {
    this.setState(({ limit, offset, where, orderBy }) => {
      const value = cb({ limit, offset, where, orderBy });
      const deepClone = JSON.parse(JSON.stringify(value));
      return deepClone;
    });
  }

  renderNodes = nodes => nodes.map(node => (
    <div key={node.id}>
      ID:
      {' '}
      {node.id}
      <hr />
    </div>
  ))

  getContextValue = (result) => {
    const schema = this.context;
    const { type } = this.props;
    const { limit, offset, where, orderBy } = this.state;
    const schemaType = schema.types[type];

    return {
      schema,
      schemaType,
      selections: this.selections,
      topLevelSelections: this.topLevelSelections,
      type,
      result,
      error: result.error,
      nodes: _.get(result, 'data.nodes', []),
      count: _.get(result, 'data.meta.aggregate.count'),
      variables: {
        limit, offset, where, orderBy,
      },
      setVariables: this.setVariables,
    };
  }

  getQueryContent = (contextValue) => {
    const { children } = this.props;

    if (typeof children === 'function') return children(contextValue);
    if (children) return children;
    return this.renderNodes(contextValue.nodes);
  }

  handleError = (error) => {
    const { type } = this.props;
    console.error(`Error in NodeList query for ${type}: `, error);
  }

  handleCompleted = (data) => {
    // console.log('Completed data fetch for NodeList <Query>.');
  }

  render() {
    const { offset, limit, orderBy, where } = this.state;
    const variables = { offset, limit, orderBy, where };

    return (
      <Query
        query={this.query}
        // variables={variables}
        variables={JSON.parse(JSON.stringify(variables))}
        displayName="NodeListQuery"
        onCompleted={this.handleCompleted}
        onError={this.handleError}
        fetchPolicy="cache-and-network"
      >
        {(result) => {
          const contextValue = this.getContextValue(result);
          const content = this.getQueryContent(contextValue);

          return (
            <NodeListContext.Provider value={contextValue}>
              {content}
            </NodeListContext.Provider>
          );
        }}

      </Query>
    );
  }
}

export default NodeList;
export { NodeListContext, NodeListContextConsumer };
