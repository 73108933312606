import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-apollo';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import StartIcon from 'mdi-material-ui/Flash';
import ResumeIcon from 'mdi-material-ui/History';
import Paper from '@material-ui/core/Paper';
import formatDate from 'date-fns/format';
import Grid from '@material-ui/core/Grid';
import _ from 'lodash';

import { withCurrentUser } from '/../../kiska/src/components/contexts/CurrentUserContext'; // eslint-disable-line import/no-extraneous-dependencies
import { Typography } from '@material-ui/core';
import NormalLayout from './layouts/NormalLayout';

const styles = theme => ({
  root: {
    backgroundColor: 'yellow',
  },
  section: {
    padding: theme.spacing.unit * 2,
    marginTop: theme.spacing.unit * 4,
  },
  sectionHeader: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing.unit * 4,
    '& svg': {
      fontSize: 30,
      marginRight: 16,
    },
  },
  sessionButton: {
    textTransform: 'none',
    textAlign: 'center',
    justifyContent: 'center',
    width: '100%',
  },
});

const Section = compose(
  withStyles(styles),
)(({ classes, title, children, icon }) => (
  <Paper className={classes.section}>
    <Typography variant="h5" className={classes.sectionHeader} gutterBottom>
      {icon}{title}
    </Typography>
    {children}
  </Paper>
));

class HomePage extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
  }

  renderAnon = () => {
    const { classes } = this.props;

    return (
      <NormalLayout title="Welcome!">
        <Typography variant="body1" align="center">
          Welcome to the Swansea Face Training program.<br />
          Want to become a participant?<br /><br />
          <Button variant="outlined" component={Link} to="/become-a-participant" className={classes.backButton} color="primary">
            Sign Up!
          </Button>
        </Typography>
      </NormalLayout>
    );
  }

  renderAuth = () => {
    const { classes, currentUser: { user } } = this.props;

    const assignedSets = _.get(user, 'setAssignment.data.sets', []);

    return (
      <NormalLayout title="Swansea Face Training" showTitle={false}>
        <Typography variant="body1" align="center">
          Welcome {user.displayName}!<br />
          To get started, choose an option below:<br />
        </Typography>
        <Grid container spacing={40}>
          <Grid item xs={12} sm={6}>
            <Section title="Resume a training session" icon={<ResumeIcon />}>
              {!user.sessions.length ? (
                <Typography variant="body2">
                  You don't have any incomplete training sessions at this time.<br /><br />
                </Typography>
              ) : null}
              <Grid container spacing={16} justify="center">
                {user.sessions.map((session) => {
                  if (session.status === 'complete') return null;
                  return (
                    <Grid item xs={12} md={12} key={session.id} align="center">
                      <Button className={classes.sessionButton} color="primary" component={Link} to={`/run-session?session=${session.id}`} variant="outlined">
                        {session.staircaseSet.label}<br />
                        From {formatDate(new Date(session.updatedAt), `MMM dd, yyyy 'at' h:mma`)}
                      </Button>
                      <br />
                    </Grid>
                  );
                })}
              </Grid>
            </Section>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Section title="Start a new training session" icon={<StartIcon />}>
              {!assignedSets.length ? (
                <Typography variant="body2">
                  You don't have any training assigned to you at this time.<br /><br />
                </Typography>
              ) : null}
              <Grid container spacing={16} justify="center">
                {assignedSets.map((set, index) => {
                  if (!set) return null;

                  return (
                    <Grid item xs={12} md={12} key={index} align="center">
                      <Button className={classes.sessionButton} disabled={index !== 0} color="primary" component={Link} to={`/run-session?set=${set.id}`} variant="outlined">
                        {set.label}
                      </Button>
                      <br />
                    </Grid>
                  );
                })}
              </Grid>
            </Section>
          </Grid>
        </Grid>

      </NormalLayout>
    );
  }

  render() {
    const { currentUser: { user } } = this.props;

    if (user.isAnon) return this.renderAnon();
    return this.renderAuth();
  }
}

HomePage = compose(
  withStyles(styles),
  withCurrentUser,
)(HomePage);

export default HomePage;
