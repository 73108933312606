import to from 'await-to-js';
import _ from 'lodash';

const buildInvalidPasswordMessage = (fieldLabel, rule) => {
  let msg = `${fieldLabel} must contain at least `;
  if (rule.minChars) msg += `${rule.minChars} characters, `;
  if (rule.digit) msg += `one digit, `;
  if (rule.upperCase) msg += `one uppercase letter, `;
  if (rule.lowerCase) msg += `one lowercase letter, `;
  if (rule.symbol) msg += `one of these symbols: ! @ # $ % ^ &, `;

  msg = _.trim(msg, ', ');
  msg += '.';

  return msg;
};

const mergeValidationErrors = (errors) => {
  const globalErrors = errors.reduce((acc, e) => (e && e.globalErrors ? [...acc, ...e.globalErrors] : acc), []);
  const fieldErrors = errors.reduce((acc, e) => {
    if (!e || !e.fieldErrors) return acc;
    return _.mergeWith(acc, e.fieldErrors, (objValue, srcValue) => {
      if (_.isArray(objValue)) {
        return objValue.concat(srcValue);
      }
      return undefined;
    });
  }, {});
  return { globalErrors, fieldErrors };
};

const handleValidationObj = (rules, { field, fieldName }, contextArgs) => new Promise(async (resolve, reject) => {
  const { fields, node, type, schema } = contextArgs;
  const value = field.value;
  const promises = [];
  const schemaType = schema.types[type];
  const fieldLabel = schemaType.fields[fieldName].label;
  const typeLabel = schema.types[type].label;

  const addFieldError = e => promises.push({ fieldErrors: { [`${fieldName}`]: [e] } });

  _.forEach(rules, (rule, ruleName) => {
    switch (ruleName) {
      case 'required':
        if (value === '' || value === null) {
          addFieldError({
            type: ruleName,
            message: rule.message ? rule.message(value) : `${fieldLabel} is a required field.`,
          });
        }
        break;

      case 'email':
        if (!/[^@]+@[^.]+\..+/g.test(value)) {
          addFieldError({
            type: ruleName,
            message: rule.message ? rule.message(value) : `${fieldLabel} must be a valid email address.`,
          });
        }
        break;

      case 'password':
        rule = {
          minChars: 6,
          digit: true,
          upperCase: true,
          lowerCase: true,
          symbol: true,
          ...rule,
        };
        // eslint-disable-next-line no-case-declarations
        let validPassword = true;
        if (rule.minChars) validPassword = validPassword && value.length >= rule.minChars;
        if (rule.digit) validPassword = validPassword && RegExp(`(?=.*[0-9])`).test(value);
        if (rule.upperCase) validPassword = validPassword && RegExp(`(?=.*[A-Z])`).test(value);
        if (rule.lowerCase) validPassword = validPassword && RegExp(`(?=.*[a-z])`).test(value);
        if (rule.symbol) validPassword = validPassword && RegExp(`(?=.[!@#$%^&])`).test(value);
        if (!validPassword) {
          addFieldError({
            type: ruleName,
            message: rule.message ? rule.message(value) : buildInvalidPasswordMessage(fieldLabel, rule),
          });
        }
        break;

      case 'equalTo':
        if (rule.value && rule.value !== value) {
          addFieldError({
            type: ruleName,
            message: rule.message ? rule.message(value) : `${fieldLabel} must be equal to ${rule.value}.`,
          });
        } else if (rule.field && fields[rule.field].value !== value) {
          addFieldError({
            type: ruleName,
            message: rule.message ? rule.message(value) : `${fieldLabel} must be the same as ${schemaType.fields[rule.field].label}.`,
          });
        }
        break;

      default:
        console.warn(`Unknown validation rule "${ruleName}", skipping...`);
    }
  });

  const [unexpectedError, validationErrors] = await to(Promise.all(promises));
  if (unexpectedError) reject(unexpectedError);

  const mergedErrors = mergeValidationErrors(validationErrors);
  resolve(mergedErrors);
});

const validate = contextArgs => new Promise(async (resolve, reject) => {
  const { fields, node, type, schema } = contextArgs;
  const promises = [];

  _.forEach(fields, (field, fieldName) => {
    const validation = _.get(schema, `types.${type}.fields.${fieldName}.validation`);
    if (!validation) return;

    if (Array.isArray(validation)) {
      // Validation should be a mixed array of rule functions and rules objects
      validation.forEach((objOrFunc) => {
        if (typeof objOrFunc === 'function') {
          promises.push(objOrFunc({ field, fieldName }, contextArgs));
        } else {
          promises.push(handleValidationObj(objOrFunc, { field, fieldName }, contextArgs));
        }
      });
    } else {
      // Validation should be an object of rules
      promises.push(handleValidationObj(validation, { field, fieldName }, contextArgs));
    }
  });

  const [unexpectedError, validationErrors] = await to(Promise.all(promises));
  if (unexpectedError) reject(unexpectedError);

  const mergedErrors = mergeValidationErrors(validationErrors);
  resolve(mergedErrors);
});

const atLeastOneError = (errors) => {
  if (!errors) return false;
  if (errors.globalErrors && errors.globalErrors.length) return true;
  if (!errors.fieldErrors) return false;

  let hasError = false;
  _.forEach(errors.fieldErrors, (fieldErrors) => {
    if (!fieldErrors) return;
    if (fieldErrors.length) hasError = true;
  });
  return hasError;
};

export { validate, mergeValidationErrors, atLeastOneError };
