import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';


import { NodeListContext } from '.';

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  rootBorder: {
    padding: theme.spacing.unit * 1,
    border: 'solid 1px rgba(0,0,0,.25)',
    borderRadius: 5,
  },
  radio: {
    paddingTop: theme.spacing.unit * 0.5,
    paddingBottom: theme.spacing.unit * 0.5,
  },
});

class BooleanFilter extends Component {
  static contextType = NodeListContext

  static propTypes = {
    label: PropTypes.node,
    classes: PropTypes.object.isRequired,
    invert: PropTypes.bool,
    checkboxOnly: PropTypes.bool,
    trueLabel: PropTypes.string,
    falseLabel: PropTypes.string,
    indeterminateLabel: PropTypes.string,
    path: PropTypes.string.isRequired,
  }

  static defaultProps = {
    label: ``,
    invert: false,
    checkboxOnly: false,
    trueLabel: 'Is true/yes',
    falseLabel: 'Is false/no',
    indeterminateLabel: 'Is either',
  }

  handleChange = (event) => {
    const { checkboxOnly, invert } = this.props;

    if (!checkboxOnly) {
      this.change(event.target.value);
    } else if (invert) {
      this.change(event.target.checked ? 'false' : 'indeterminate');
    } else {
      this.change(event.target.checked ? 'true' : 'indeterminate');
    }
  }

  change = (value) => {
    const { setVariables } = this.context;
    const { path } = this.props;

    setVariables(({ where }) => {
      if (value === 'true') {
        _.set(where, `${path}._eq`, true);
      } else if (value === 'false') {
        _.set(where, `${path}._eq`, false);
      } else {
        _.set(where, `${path}._eq`, undefined);
      }
      return { where };
    });
  }

  clear = (event) => {
    this.change(undefined);
  }

  render() {
    const { variables } = this.context;
    const { classes, label, path, checkboxOnly, trueLabel, falseLabel, indeterminateLabel } = this.props;

    let content;
    let value = _.get(variables.where, `${path}._eq`);
    if (value === true) value = 'true';
    else if (value === false) value = 'false';
    else value = 'indeterminate';

    if (checkboxOnly) {
      content = (
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox checked={value === 'true'} onChange={this.handleChange} />
            }
            label={label}
          />
        </FormGroup>
      );
    } else {
      const radio = <Radio className={classes.radio} />;
      content = (
        <>
          <FormLabel className={classes.label}>{label}</FormLabel>
          <RadioGroup className={classes.group} onChange={this.handleChange} value={value}>
            <FormControlLabel value="true" control={radio} label={trueLabel} />
            <FormControlLabel value="false" control={radio} label={falseLabel} />
            <FormControlLabel value="indeterminate" control={radio} label={indeterminateLabel} />
          </RadioGroup>
        </>
      );
    }

    return (
      <div className={classNames(classes.root, { [`${classes.rootBorder}`]: !checkboxOnly })}>
        <FormControl component="fieldset" className={classes.control}>
          {content}
        </FormControl>
      </div>
    );
  }
}

BooleanFilter = withStyles(styles)(BooleanFilter);
export default BooleanFilter;
