import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import AdminList from '/components/AdminList';
import { Helmet } from 'react-helmet';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import formatDate from 'date-fns/format';
import CopyIcon from 'mdi-material-ui/ContentCopy';

import CopyToClipboardButton from '/../../kiska/src/components/CopyToClipboardButton';
import { buildSignUpByInvitationUrl } from '/utils';

const styles = theme => ({
  root: {
  },
});

class UserInvitationListPage extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    onTitleChange: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    props.onTitleChange('Invitations');
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Helmet>
          <title>Staircases</title>
        </Helmet>

        <AdminList
          type="user_invitation"
          likeFilterProps={{ path: 'token', label: 'Search by token' }}
          renderTitle={node => `Role: ${node.role.label}`}
          editUrl={node => `/admin/user-invitations/${node.id}/edit`}
          viewUrl={node => `/admin/user-invitations/${node.id}`}
          insertUrl="/admin/user-invitations/new/insert"
          insertTitle="Create new Invitation"
          showViewButton={false}
          showEditButton={false}
          renderActions={(node) => {
            const link = buildSignUpByInvitationUrl(node.token);
            return (
              <CopyToClipboardButton title="Copy link to clipboard" text={link} color="primary" variant="text">
                {link}
              </CopyToClipboardButton>
            );
          }}
          renderContent={(node) => {
            return (
              <Typography variant="body2">
                Uses remaining: {node.usesRemaining}<br />
                Expires on: {formatDate(new Date(node.expiresAt), 'MMM dd, yyyy')}<br />
              </Typography>
            );
          }}
        />;
      </div>
    );
  }
}

UserInvitationListPage = compose(
  withStyles(styles),
)(UserInvitationListPage);

export default UserInvitationListPage;
