import React, { Component, createContext } from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';

const query = gql`
  query GetAppSettings{
    settings: app_setting{
      id json text int file{id name url mimeType} name label
    }
  }
`;

const AppSettingsContext = createContext({});

class AppSettingsProvider extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  }

  static defaultProps = {
  }

  render() {
    const { children } = this.props;

    return (
      <Query query={query}>
        {({ data, error, loading }) => {
          const value = {};

          if (error || !data.settings) {
            console.error('Error loading app settings: ', error);
          } else {
            data.settings.forEach((setting) => { value[setting.name] = setting; });
          }

          return (
            <AppSettingsContext.Provider value={value}>
              {children}
            </AppSettingsContext.Provider>
          );
        }}
      </Query>
    );
  }
}

const withAppSettings = WrappedComponent => props => (
  <AppSettingsContext.Consumer>
    {value => <WrappedComponent appSettings={value} {...props} />}
  </AppSettingsContext.Consumer>
);

export { withAppSettings, AppSettingsProvider, AppSettingsContext };
