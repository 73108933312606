export default {
  types: {
    staircase: {

      renderers: {
        inline: node => node.label,
      },

      selections: {
        default: 'id label config images{id file{id name url mimeType size}}',
      },

      fields: {
        label: {
          label: 'Title',
          type: 'String',
        },

        images: {
          type: 'file',
          hasMany: true,
        },

        config: {
          type: 'Json',
          label: 'Configuration',
        },

      },
    },
  },
};
