import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

import { LabeledValue } from './helpers';

const StaircaseView = ({ node }) => (
  <Grid container spacing={24}>
    <Grid item xs={12} sm={6}>
      <LabeledValue label="Name" value={node.label} />
    </Grid>
  </Grid>
);

StaircaseView.propTypes = {
  node: PropTypes.object.isRequired,
};

export default StaircaseView;
