/* eslint-disable no-plusplus */
import settingsFunc from '../settings';

const settings = settingsFunc();

const buildSignUpByInvitationUrl = (token) => {
  return `${settings.origin}${settings.signUpByInvitationRootUrl}?op=sign-up&invite=${token}`;
};

const getRandomIntInclusive = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

const getNonRepeatingRandomIntArrayInclusive = (min, max, length) => {
  if (length === undefined) length = max - min + 1;

  const array = [];
  for (let i = 0; i < length; i++) {
    let value;
    do {
      value = getRandomIntInclusive(min, max);
    } while (array.includes(value));
    array[i] = value;
  }
  return array;
};

const getRandomIntArrayInclusive = (min, max, length) => {
  const array = [];
  for (let i = 0; i < length; i++) {
    const value = getRandomIntInclusive(min, max);
    array[i] = value;
  }
  return array;
};

const pickRandomElement = (a) => {
  return a[getRandomIntInclusive(0, a.length - 1)];
};

const getSortedFaces = (staircaseOrFiles) => {
  let files;
  if (!staircaseOrFiles) staircaseOrFiles = [];
  if (Array.isArray(staircaseOrFiles)) files = JSON.parse(JSON.stringify(staircaseOrFiles));
  else files = staircaseOrFiles.images.map(i => i.file);

  files.sort((a, b) => ((a.name > b.name) ? 1 : -1));
  return files;
};

const getMaxLevel = (staircaseOrFiles) => {
  let files;
  if (!staircaseOrFiles) staircaseOrFiles = [];
  if (Array.isArray(staircaseOrFiles)) files = staircaseOrFiles;
  else files = staircaseOrFiles.images;
  const maxLevel = files.length / 2 - 1;
  return maxLevel;
};

const getLevelFaces = (staircaseOrFiles, level, sortedFaces = null) => {
  sortedFaces = sortedFaces || getSortedFaces(staircaseOrFiles);

  const maxLevel = getMaxLevel(sortedFaces);
  if (level > maxLevel) {
    // console.warn(`Tried to get Level ${level} faces but max is ${maxLevel}!`);
    return null;
  }
  const keys = [
    sortedFaces[0],
    sortedFaces[sortedFaces.length - 1],
  ];
  const options = [
    sortedFaces[level],
    sortedFaces[sortedFaces.length - 1 - level],
  ];

  return { keys, options, maxLevel, level };
};

const getAllLevelsFaces = (staircaseOrFiles) => {
  const sortedFaces = getSortedFaces(staircaseOrFiles);
  if (sortedFaces.length < 4) return [];

  const levels = [];
  let level;
  // eslint-disable-next-line no-cond-assign
  while (level = getLevelFaces(null, levels.length, sortedFaces)) {
    levels.push(level);
  }

  return levels;
};

export { pickRandomElement, getMaxLevel, getAllLevelsFaces, getLevelFaces, getSortedFaces, buildSignUpByInvitationUrl, getRandomIntInclusive, getNonRepeatingRandomIntArrayInclusive, getRandomIntArrayInclusive };
