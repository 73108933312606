import '../utils/polyfills';
import React, { Component } from 'react';
import { hydrate } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import createApolloClient from '../createApolloClient';

import normalizeSchema from '../schema/normalizeSchema';
import GoogleAnalytics from '../components/contexts/GoogleAnalytics';
import AppWrappers from '../components/AppWrappers';

let normalizedSchema;

const createClientApp = async (options) => {
  const apolloClient = createApolloClient({
    ...options,
    target: 'client',
    ssrMode: false,
  });

  if (!normalizedSchema) {
    normalizedSchema = normalizeSchema(options.schema);
  } else {
    options.schema = normalizedSchema;
  }

  // Pull data injected by SSR
  const ssrData = window.__KISKA_SSR_DATA__;

  // Print out some stats about the SSR process
  console.log('SSR stats', ssrData.renderStats);

  class ClientApp extends Component {
    static propTypes = {
      // route: PropTypes.object.isRequired,
      // classes: PropTypes.object.isRequired,
    }

    static defaultProps = {
    }

    constructor(props) {
      super(props);
      this.state = {};
    }

    componentDidMount() {
      // Remove JSS styles injected from the SSR
      const jssStyles = document.getElementById('jss-server-side');
      if (jssStyles && jssStyles.parentNode) {
        jssStyles.parentNode.removeChild(jssStyles);
      }
    }

    render() {
      return (
        <BrowserRouter>
          <AppWrappers options={options} apolloClient={apolloClient} ssrData={ssrData} userStub={ssrData.userStub}>
            <GoogleAnalytics options={options} />
            <options.App {...options.appProps} />
          </AppWrappers>
        </BrowserRouter>
      );
    }
  }

  const app = <ClientApp />;

  hydrate(app, document.querySelector('#app'));

  return ClientApp;
};

export { createClientApp };
