import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import NextIcon from 'mdi-material-ui/ChevronRight';
import PreviousIcon from 'mdi-material-ui/ChevronLeft';
import IconButton from '@material-ui/core/IconButton';

import { NodeListContext } from '.';

class PageButton extends Component {
  static contextType = NodeListContext

  static propTypes = {
    type: PropTypes.string.isRequired,
    labeled: PropTypes.bool,
    previousLabel: PropTypes.node,
    nextLabel: PropTypes.node,
  }

  static defaultProps = {
    labeled: true,
    nextLabel: 'Next',
    previousLabel: 'Prev',
  }

  static getPageInfo = (variables, count) => {
    const { offset, limit } = variables;
    const hasPrevious = offset > 0;
    const hasNext = offset + limit < count;
    return { hasPrevious, hasNext };
  }

  handleClick = () => {
    const { setVariables, count } = this.context;
    const { type } = this.props;

    setVariables((variables) => {
      const { hasPrevious, hasNext } = PageButton.getPageInfo(variables, count);
      if (type.includes('prev') && hasPrevious) {
        return { offset: Math.max(variables.offset - variables.limit, 0) };
      }
      if (type.includes('next') && hasNext) {
        return { offset: variables.offset + variables.limit };
      }
      return {};
    });
  }

  render() {
    const { result: { loading }, count, variables } = this.context;
    const { labeled, nextLabel, previousLabel, type, ...rest } = this.props;
    const { hasPrevious, hasNext } = PageButton.getPageInfo(variables, count);
    if (!type) {
      console.error('Must supply type to PageButton');
      return null;
    }
    let label = '';
    if (labeled) {
      if (type.includes('prev')) label = previousLabel;
      if (type.includes('next')) label = nextLabel;
    }
    const OutterElement = labeled ? Button : IconButton;

    const disabled = loading
      || (type.includes('prev') && !hasPrevious)
      || (type.includes('next') && !hasNext);

    return (
      <OutterElement disabled={disabled} onClick={this.handleClick} color="secondary" {...rest}>
        {type.includes('prev') ? <PreviousIcon /> : null}
        {labeled ? <span>{label}</span> : null}
        {type.includes('next') ? <NextIcon /> : null}
      </OutterElement>
    );
  }
}

export default PageButton;
