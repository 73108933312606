import React, { Component, createContext } from 'react';
import PropTypes from 'prop-types';
import MuiTextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import ErrorIcon from 'mdi-material-ui/AlertOctagon';
import red from '@material-ui/core/colors/red';

const styles = theme => ({
  errors: {

  },
  error: {
    padding: `${theme.spacing.unit * 0.5}px ${theme.spacing.unit * 0.5}px`,
    marginTop: theme.spacing.unit * 0.5,
    display: 'flex',
    alignItems: 'center',
    borderRadius: 5,
    backgroundColor: red[700],
    color: '#FFF',
    lineHeight: 1.2,
  },
  errorIcon: {
    color: '#FFF',
    marginRight: theme.spacing.unit * 1,
  },
});

class HelperText extends Component {
  static propTypes = {
    errors: PropTypes.array.isRequired,
    text: PropTypes.node,
    classes: PropTypes.object.isRequired,
  }

  static defaultProps = {
    text: null,
  }

  render = () => {
    const { errors, text, classes } = this.props;

    if (!text && !errors.length) {
      return null;
    }

    const errorSpans = errors.map(error => (
      <Typography variant="body1" component="span" color="inherit" className={classes.error} key={error.type}>
        <ErrorIcon className={classes.errorIcon} />
        {error.message}
      </Typography>
    ));

    return (
      <span>
        <span className={classes.text}>{text}</span>
        <span className={classes.errors}>{errorSpans}</span>
      </span>
    );
  }
}

HelperText = withStyles(styles)(HelperText);
HelperText.displayName = 'HelperText';

export default HelperText;
