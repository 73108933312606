import React from 'react';
import { Route } from 'react-router-dom';
import { AnimatedSwitch, spring } from 'react-router-transition';
import { compose } from 'react-apollo';
import { withStyles } from '@material-ui/core';

import HomePage from '/pages/HomePage';
import SignInPage from '/pages/SignInPage';
import AdminLayout from '/pages/layouts/AdminLayout';
import RunSessionPage from './pages/RunSessionPage';
import NotFoundPage from './pages/errors/NotFoundPage';
import settingsFunc from '../settings';

const settings = settingsFunc();

const jssStyles = theme => ({
  '@global': {
    '.top-level-route-wrapper': {
      position: 'relative',
      '& > div': {
        position: 'absolute',
        width: '100%',
      },
    },
  },
});

function mapStyles(styles) {
  return {
    opacity: styles.opacity,
    // transform: `translateX(${styles.translateX}vw)`,
    transform: `scale(${styles.scale})`,
  };
}

function bounce(val) {
  return spring(val, {
    stiffness: 200,
    damping: 25,
  });
}

const bounceTransition = {
  atEnter: {
    opacity: 0,
    scale: 0,
    translateX: 50,
    // translateX: 100,
  },
  atLeave: {
    opacity: bounce(0),
    scale: bounce(0.2),
    translateX: bounce(50),
    // translateX: bounce(-100),
  },
  atActive: {
    opacity: bounce(1),
    scale: bounce(1),
    translateX: bounce(0),
  },
};

const Routes = () => (
  <AnimatedSwitch
    atEnter={bounceTransition.atEnter}
    atLeave={bounceTransition.atLeave}
    atActive={bounceTransition.atActive}
    mapStyles={mapStyles}
    className="top-level-route-wrapper"
  >
    <Route exact path="/" component={HomePage} />
    <Route path={`${settings.signUpByInvitationRootUrl}`} component={SignInPage} />
    <Route path="/sign-in" component={SignInPage} />

    <Route path="/run-session" component={RunSessionPage} />

    <Route path="/admin" component={AdminLayout} />

    <Route component={NotFoundPage} />
  </AnimatedSwitch>
);

const ERoutes = compose(
  withStyles(jssStyles),
)(Routes);

export default ERoutes;
