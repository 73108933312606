import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-apollo';
import qs from 'query-string';
import { NavLink, Link as RouterLink, Redirect } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { withRouter } from 'react-router';
import CircularProgress from '@material-ui/core/CircularProgress';
import Link from '@material-ui/core/Link';
import BackIcon from 'mdi-material-ui/ChevronLeft';

import { Typography } from '@material-ui/core';
import SignInWidget from '/../../kiska/src/components/SignInWidget';
import { withCurrentUser } from '/../../kiska/src/components/contexts/CurrentUserContext';
import NormalLayout from './layouts/NormalLayout';

import logo from '/images/logo.png';
import SiteFooter from './layouts/components/SiteFooter';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '100vh',
    justifyContent: 'center',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
  },
  body: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: theme.spacing.unit * 2,
  },
  logo: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    justifyContent: 'center',
    marginTop: 8,
    marginBottom: 48,
    '&:link, &:visited, &:hover': {
      textDecoration: 'none',
    },
    '& img': {
      height: 150,
      // padding: '16px 8px 16px 16px',
    },
    '& p': {
      fontWeight: 300,
      fontSize: 36,
      lineHeight: 1.2,
    },
  },
});

class SignInPage extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
  }

  render() {
    const { classes, currentUser, history, location, match } = this.props;

    const query = qs.parse(location.search);
    const { op, url, invite } = query;

    if (currentUser.user.isAuthenticated) {
      return <Redirect to={url || '/'} />;
    }

    if ((op === 'sign-up') && !invite) {
      return (
        <NormalLayout title="No Invitation Found">
          <div className={classes.noInviteRoot}>
            <Typography align="center">
              It looks like that invitation link is not valid.
              Please try again or <Link component={RouterLink} to="/contact-us">contact us</Link> for help.
            </Typography>
          </div>
        </NormalLayout>
      );
    }

    return (
      <div className={classes.root}>
        <div className={classes.header}>
          <IconButton onClick={() => history.goBack()}><BackIcon /></IconButton>
        </div>
        <div className={classes.body}>
          <div className={classes.logo}>
            <img src={logo} alt="Swansea Face Training logo" />
            <Typography component="p">
              Face<br />Research<br />Swansea
            </Typography>
          </div>
          {op === 'sign-up' ? (
            <>
              <Typography variant="h6" align="center">You're Invited!</Typography>
              <Typography variant="body2" align="center" color="textSecondary">
                Use any of the providers below to setup your new account.
              </Typography>
              <br /><br />
            </>
          ) : null}
          {op === 'sign-in' ? (
            <>
              <Typography variant="h6" align="center">Sign In</Typography>
              <Typography variant="body2" align="center" color="textSecondary">
                You must already have an account to sign in.
                Don't have one?
                <Button variant="text" component={RouterLink} to="/become-a-participant" size="small" color="primary">
                  Sign up
                </Button>
              </Typography>
              <br /><br />
            </>
          ) : null}
          <SignInWidget
            privacyPolicyUrl="https://www.swansea.ac.uk/privacyandcookies/"
            tosUrl="https://www.swansea.ac.uk/privacyandcookies/"
            signInOptions={['Google', 'Facebook', 'Twitter', 'Github', 'Email']}
          />
        </div>
        <SiteFooter />
      </div>
    );
  }
}

SignInPage = compose(
  withStyles(styles),
  withCurrentUser,
  withRouter,
)(SignInPage);
SignInPage.displayName = 'SignInPage';
export default SignInPage;
