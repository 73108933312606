import { hot } from 'react-hot-loader';
import '/../../kiska/src/client/entryPointBoilerplate';
import { createClientApp } from '/../../kiska/src/client/createClientApp';

import App from './App';
import commonOptions from './options';

const options = {
  ...commonOptions,
  App,
};
const ClientApp = createClientApp(options);

export default hot(module)(ClientApp);
