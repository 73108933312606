
const buildAnonUser = () => ({
  id: '',
  displayName: 'Anonymous',
  email: '',
  isAnon: true,
  isAuthenticated: false,
  role: { name: 'anon', label: 'Anonymous' },
});

const normalizeUser = (user) => {
  if (!user || !user.id) {
    user = buildAnonUser();
  } else {
    user.isAnon = false;
    user.isAuthenticated = true;
  }

  user.isAdmin = ['site-owner', 'admin'].includes(user.role.name) || ['root'].includes(user.id);

  Object.freeze(user);
  return user;
};

module.exports = {
  normalizeUser,
  buildAnonUser,
};
