import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-apollo';
import _ from 'lodash';
import AscIcon from 'mdi-material-ui/ArrowUp';
import DescIcon from 'mdi-material-ui/ArrowDown';
import { withStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import ReactDOM from 'react-dom';

import { withThemeManager, MuiThemeProvider } from '../contexts/ThemeManagerContext'; // eslint-disable-line import/no-extraneous-dependencies
import { NodeListContext } from '.';
import PageControls from './PageControls';
import FilterDialog from './FilterDialog';

const possibleOrderByTypes = ['String', 'Int', 'Float', 'Timestamp'];

const styles = theme => ({
  formControl: {
    width: '100%',
  },
  select: {
    display: 'flex',
    alignItems: 'center',
    height: 16,
  },
});

class OrderBySelect extends Component {
  static contextType = NodeListContext

  static propTypes = {
    firstOrderByDirection: PropTypes.string,
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    label: PropTypes.string,
    fields: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  }

  static defaultProps = {
    firstOrderByDirection: 'asc_nulls_last',
    className: '',
    label: 'Sort By',
    fields: undefined,
  }

  static fieldOrderByDirection = (field, orderBy) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const criteria of orderBy) {
      if (criteria[field]) return criteria[field];
    }
    return null;
  }

  state = {
    labelWidth: 0,
  }

  constructor(props, context) {
    super(props);

    let fields = props.fields;
    if (fields && typeof fields === 'string') fields = fields.split(/[,\s]/).filter(f => !!f);

    const options = [];
    _.forEach(context.schemaType.fields, (field) => {
      if (!possibleOrderByTypes.includes(field.type)) return;
      if (fields && !fields.includes(field.name)) return;

      options.push({ value: `${field.name}:asc_nulls_last`, label: <><AscIcon />&nbsp;&nbsp;{field.label} - ascending</> });
      options.push({ value: `${field.name}:desc_nulls_last`, label: <><DescIcon />&nbsp;&nbsp;{field.label} - descending</> });
    });

    this.options = options.map((option, index) => <MenuItem value={option.value} key={index}>{option.label}</MenuItem>);
  }

  componentDidMount() {
    this.setState({
      // eslint-disable-next-line react/no-find-dom-node
      labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth,
    });
  }

  toggleOrderByDirection = (direction) => {
    const { firstOrderByDirection } = this.props;

    switch (direction) {
      case 'asc': return 'desc';
      case 'desc': return 'asc';
      case 'asc_nulls_first': return 'desc_nulls_first';
      case 'desc_nulls_first': return 'asc_nulls_first';
      case 'asc_nulls_last': return 'desc_nulls_last';
      case 'desc_nulls_last': return 'asc_nulls_last';
      case null:
      default:
        return firstOrderByDirection;
    }
  }

  orderByField = (field, direction) => {
    const { setVariables } = this.context;

    setVariables(({ orderBy }) => {
      const currentDirection = OrderBySelect.fieldOrderByDirection(field, orderBy);
      const newDirection = direction || this.toggleOrderByDirection(currentDirection);

      // Remove all orderBy elements that have this field name
      _.remove(orderBy, v => !!v[field]);
      // Add the new orderBy element at the beginning of array
      orderBy.unshift({ [`${field}`]: newDirection });

      return { orderBy: JSON.parse(JSON.stringify(orderBy)) };
    });
  }

  handleChange = (event) => {
    const value = event.target.value;
    const splitValue = value.split(':');
    const field = splitValue[0];
    const direction = splitValue[1];

    this.orderByField(field, direction);
  }

  render() {
    const { variables: { orderBy } } = this.context;
    const { label, classes } = this.props;
    let value = '';
    if (orderBy.length) {
      const first = orderBy[0];
      const field = Object.getOwnPropertyNames(first)[0];
      value = `${field}:${first[field]}`;
    }

    return (
      <FormControl className={classes.formControl} variant="outlined">
        <InputLabel
          ref={(ref) => {
            this.InputLabelRef = ref;
          }}
        >
          {label}
        </InputLabel>
        <Select
          value={value}
          name="myName"
          classes={{ selectMenu: classes.select }}
          onChange={this.handleChange}
          autoWidth
          input={<OutlinedInput labelWidth={this.state.labelWidth} name="myName" />}
        >
          {this.options}
        </Select>
      </FormControl>
    );
  }
}

OrderBySelect = compose(
  withStyles(styles),
  withThemeManager,
)(OrderBySelect);
export default OrderBySelect;
