import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'react-apollo';
import CustomScrollbar from 'react-scrollbars-custom';

const styles = theme => ({
  scrollbar: {
    // backgroundColor: '#5D5D5D',
  },
});

class Scrollbar extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    children: PropTypes.node,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }

  static defaultProps = {
    width: '100%',
    height: undefined,
    children: null,
  }

  trackYRenderer = (props) => {
    const { elementRef, style, ...rendererRest } = props;
    return (
      <span
        {...rendererRest}
        ref={elementRef}
        style={{
          ...style,
          backgroundColor: 'rgba(0,0,0,.2)',
        }}
      />
    );
  }

  render() {
    const { classes, children, height, width, ...rest } = this.props;

    return (
      <CustomScrollbar
        style={{ width, height }}
        className={classes.scrollbar}
        trackYProps={{ renderer: this.trackYRenderer }}
        {...rest}
      >
        {children}
      </CustomScrollbar>
    );
  }
}

Scrollbar = compose(
  withStyles(styles),
)(Scrollbar);


export default Scrollbar;
