import React, { Component, createContext } from 'react';
import PropTypes from 'prop-types';

const SchemaContext = createContext({});

class SchemaProvider extends Component {
  static propTypes = {
    schema: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired,
  }

  render() {
    const { schema, children } = this.props;

    return (
      <SchemaContext.Provider value={schema}>
        {children}
      </SchemaContext.Provider>
    );
  }
}

const withSchema = WrappedComponent => props => (
  <SchemaContext.Consumer>
    {schema => <WrappedComponent schema={schema} {...props} />}
  </SchemaContext.Consumer>
);

export { withSchema, SchemaProvider, SchemaContext };
