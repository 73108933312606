import PropTypes from 'prop-types';
import { MuiThemeProvider } from '@material-ui/core/styles';
import React, { Component, createContext } from 'react';

const ThemeManagerContext = createContext({});

const styles = theme => ({
  root: {
    color: 'magenta',
  },
});

class ThemeManagerProvider extends Component {
  static propTypes = {
    defaultTheme: PropTypes.string.isRequired,
    themes: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      activeTheme: props.defaultTheme || 'light',
    };
  }

  setActiveTheme = (theme) => {
    this.setState({ activeTheme: theme });
  }

  toggleActiveTheme = (theme) => {
    this.setState(state => ({ activeTheme: state.activeTheme === 'light' ? 'dark' : 'light' }));
  }

  render() {
    const { children, themes } = this.props;
    const { activeTheme } = this.state;
    const contextValue = {
      activeTheme: themes[activeTheme],
      inverseTheme: activeTheme === 'light' ? themes.dark : themes.light,
      themes,
      setActiveTheme: this.setActiveTheme,
      toggleActiveTheme: this.toggleActiveTheme,
    };

    return (
      <ThemeManagerContext.Provider value={contextValue}>
        {children}
      </ThemeManagerContext.Provider>
    );
  }
}

const withThemeManager = WrappedComponent => props => (
  <ThemeManagerContext.Consumer>
    {value => <WrappedComponent themeManager={value} {...props} />}
  </ThemeManagerContext.Consumer>
);

// ThemeManagerProvider = withApollo(ThemeManagerProvider);
export { ThemeManagerProvider, ThemeManagerContext, withThemeManager, MuiThemeProvider };
