import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { compose } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';

import favicon from './images/logo.png';
import { withThemeManager } from '/../../kiska/src/components/contexts/ThemeManagerContext'; // eslint-disable-line import/no-extraneous-dependencies
import { withCurrentUser } from '/../../kiska/src/components/contexts/CurrentUserContext'; // eslint-disable-line import/no-extraneous-dependencies
import Routes from './Routes';

const styles = theme => ({
  '@global': {
    body: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
  },
});

class App extends Component {
  static propTypes = {
  }

  static propTypes = {
  }


  render() {
    return (
      <>
        <Helmet>
          <meta name="description" content="site desc here" />
          <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />
          <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=2, user-scalable=yes" />
          <meta name="apple-mobile-web-app-capable" content="yes" />
          <meta name="mobile-web-app-capable" content="yes" />
          <link rel="icon" type="image/png" href={favicon} />
          <link href="https://fonts.googleapis.com/css?family=Roboto:300,400,700&display=swap" rel="stylesheet" />
        </Helmet>
        <Routes />
      </>
    );
  }
}

App = compose(
  withStyles(styles),
  withThemeManager,
  withCurrentUser,
)(App);

export default App;
