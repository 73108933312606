import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'react-apollo';
import { NavLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from 'mdi-material-ui/Menu';
import classNames from 'classnames';

import { withCurrentUser } from '/../../kiska/src/components/contexts/CurrentUserContext'; // eslint-disable-line import/no-extraneous-dependencies
import logo from '/images/logo.png';
import UserMenu from './UserMenu';

const menu = [
  { primary: 'Sign Up!', to: '/become-a-participant' },
  { primary: 'About Us', to: '/about-us' },
  { primary: 'Contact Us', to: '/contact-us' },
];

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing.unit * 2,
    boxShadow: '0 1px 4px rgba(0,0,0,.2)',
    zIndex: 10,
    // marginBottom: theme.spacing.unit * 4,
  },
  logo: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    '&:link, &:visited, &:hover': {
      textDecoration: 'none',
    },
    '& img': {
      height: 100,
      // padding: '16px 8px 16px 16px',
    },
    '& p': {
      fontWeight: 300,
      fontSize: 24,
      lineHeight: 1.2,
    },
  },
  right: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    alignSelf: 'stretch',
    flex: 1,
  },
  userMenu: {
    alignSelf: 'flex-end',
  },
  largeMenu: {
    justifyContent: 'flex-end',
    width: '100%',
    display: 'none',
    marginBottom: -theme.spacing.unit * 2,
    '@media (min-width: 740px)': {
      display: 'flex',
    },
  },
  smallMenuTrigger: {
    '@media (min-width: 740px)': {
      display: 'none',
    },
  },
  largeMenuItem: {
    textTransform: 'none',
    fontWeight: 400,
    fontSize: 20,
    padding: `${theme.spacing.unit * 0}px ${theme.spacing.unit * 4}px`,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
    '&.active': {
      // backgroundColor: theme.palette.action.hover,
      backgroundColor: theme.palette.primary.light,
      // color: theme.palette.text.primary,
      color: '#FFF',
      // color: theme.palette.primary.dark,
      fontWeight: 400,
    },
  },
  smallMenuItem: {

  },
});

const LargeMenuItem = compose(
  withStyles(styles),
)(({ classes, primary, to }) => (
  <Button component={NavLink} className={classes.largeMenuItem} to={to} color="primary">
    {primary}
  </Button>
));

const SmallMenuItem = compose(
  withStyles(styles),
)(({ classes, primary, to, secondary, func }) => (
  <MenuItem
    className={classes.smallMenuItem}
    component={to ? NavLink : undefined}
    to={to}
    onClick={func}
  >
    <ListItemText
      classes={{ primary: classes.primary }}
      primary={primary}
      secondary={secondary}
    />
  </MenuItem>
));

class SiteHeader extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
  }

  static defaultProps = {
  }

  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
    };
  }

  handleMenuOpen = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { anchorEl } = this.state;
    const { classes, currentUser: { user } } = this.props;

    return (
      <header className={classes.root}>
        <Link className={classes.logo} to="/" component={NavLink}>
          <img src={logo} alt="Swansea Face Training logo" />
          <Typography component="p">
            Face<br />Research<br />Swansea
          </Typography>
        </Link>
        <div className={classes.right}>
          <UserMenu className={classes.userMenu} showSignIn />
          <nav className={classes.largeMenu}>
            {menu.map(({ to, func, primary, secondary }, index) => (
              <LargeMenuItem key={index} to={to} primary={primary} />
            ))}
            {user.isAdmin ? <LargeMenuItem primary="Admin" to="/admin" /> : null}
          </nav>
          <IconButton className={classNames(classes.smallMenuTrigger)} onClick={this.handleMenuOpen}>
            <MenuIcon />
          </IconButton>
          <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={this.handleMenuClose} className={classes.smallMenu}>
            {menu.map(({ to, func, primary, secondary }, index) => (
              <SmallMenuItem key={index} to={to} primary={primary} secondary={secondary} />
            ))}
            {user.isAdmin ? <SmallMenuItem key="adimin" primary="Admin" to="/admin" /> : null}
          </Menu>
        </div>
      </header>
    );
  }
}

SiteHeader = compose(
  withStyles(styles),
  withCurrentUser,
)(SiteHeader);
SiteHeader.displayName = 'SiteHeader';
export default SiteHeader;
