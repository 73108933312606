import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import { compose } from 'react-apollo';
import barColor from '@material-ui/core/colors/orange';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';
import AppBar from './components/AppBar';

import logo from '/images/logo.png';

import SiteFooter from './components/SiteFooter';
import AdminRouter from './components/AdminRouter';
import { withThemeManager, MuiThemeProvider } from '/../../kiska/src/components/contexts/ThemeManagerContext'; // eslint-disable-line import/no-extraneous-dependencies
import Scrollbar from '/../../kiska/src/components/Scrollbar'; // eslint-disable-line import/no-extraneous-dependencies
import AdminMenu from '/pages/layouts/components/AdminMenu';

const drawerWidth = 280;
const appBarHeight = 64;

const styles = theme => ({
  root: {
    display: 'flex',
    width: '100vw',
    height: '100vh',

    '& .admin-route-wrapper': {
      position: 'relative',
      '& > div': {
        position: 'absolute',
        width: '100%',
        paddingBottom: theme.spacing.unit * 4,
      },
    },
  },

  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    backgroundColor: barColor[400],
  },

  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    height: '100vh',
  },
  drawerPaper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: drawerWidth,
    border: 'none',
  },

  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    paddingTop: appBarHeight,
  },
  innerScroll: {
    padding: theme.spacing.unit * 4,
  },

  logo: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    '&:link, &:visited, &:hover': {
      textDecoration: 'none',
    },
    '& img': {
      width: 90,
      padding: '16px 8px 16px 16px',
    },
    '& p': {
      fontWeight: 300,
      fontSize: 22,
      lineHeight: 1.2,
    },
  },
});

class AdminLayout extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    themeManager: PropTypes.object.isRequired,
  }

  static defaultProps = {
  }

  state = {
    title: '',
  }

  handleTitleChange = (title) => {
    if (title !== this.state.title) {
      setTimeout(() => this.setState({ title }), 1);
    }
  };

  render() {
    const { title } = this.state;
    const { classes, themeManager: { inverseTheme } } = this.props;
    const contentTheme = inverseTheme;
    const sidebarTheme = inverseTheme;

    const routeProps = {
      onTitleChange: this.handleTitleChange,
    };

    return (
      <div className={classes.root}>
        <MuiThemeProvider theme={sidebarTheme}>
          <AppBar className={classes.appBar} title={title} />

          <Drawer className={classes.drawer} variant="permanent" classes={{ paper: classes.drawerPaper }} anchor="left">
            <Link className={classes.logo} to="/admin" component={RouterLink}>
              <img src={logo} alt="Swansea Face Training logo" />
              <Typography component="p">
                Face<br />Research<br />Swansea
              </Typography>
            </Link>

            <Scrollbar height="100vh" width={drawerWidth}>
              <AdminMenu />

            </Scrollbar>
            <SiteFooter className={classes.footer} />
          </Drawer>
        </MuiThemeProvider>

        <MuiThemeProvider theme={contentTheme}>
          <main className={classes.content} style={{ backgroundColor: contentTheme.palette.background.default }}>
            <Scrollbar height={`calc(100vh - ${appBarHeight + 0}px)`}>
              <div className={classes.innerScroll}>
                <AdminRouter routeProps={routeProps} />
              </div>
            </Scrollbar>
          </main>
        </MuiThemeProvider>
      </div>
    );
  }
}

AdminLayout = compose(
  withStyles(styles),
  withThemeManager,
)(AdminLayout);

export default AdminLayout;
