import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

import AdminRouterSwitch from './AdminRouterSwitch';

import AdminPage from '/pages/admin/AdminPage';
import StaircaseListPage from '/pages/admin/StaircaseListPage';
// import StaircaseViewPage from '/pages/admin/StaircaseViewPage';
import StaircaseEditPage from '/pages/admin/StaircaseEditPage';
import StaircaseSetListPage from '/pages/admin/StaircaseSetListPage';
import StaircaseSetEditPage from '/pages/admin/StaircaseSetEditPage';
import AppSettingEditPage from '/pages/admin/AppSettingEditPage';
import StaircaseSetAssignmentPage from '../../../admin/StaircaseSetAssignmentPage';

import UsersListPage from '/pages/admin/UsersListPage';
import UsersEditPage from '/pages/admin/UserEditPage';
import UsersViewPage from '/pages/admin/UserViewPage';
import UserInvitationEditPage from '/pages/admin/UserInvitationEditPage';
import UserInvitationListPage from '/pages/admin/UserInvitationListPage';
import SessionTablePage from '/pages/admin/SessionTablePage';
import SessionViewPage from '/pages/admin/SessionViewPage';

import NotFoundPage from '/pages/admin/NotFoundPage';

const AdminRouter = ({ routeProps }) => (
  <AdminRouterSwitch>
    <Route path="/admin" render={props => <AdminPage {...props} {...routeProps} />} exact />

    {/* Build Stuff */}
    <Route path="/admin/staircases" render={props => <StaircaseListPage {...props} {...routeProps} />} exact />
    <Route path="/admin/staircase-sets" render={props => <StaircaseSetListPage {...props} {...routeProps} />} exact />
    <Route path="/admin/staircase-set-assignment" render={props => <StaircaseSetAssignmentPage {...props} {...routeProps} />} exact />

    <Route path="/admin/staircases/new/insert" render={props => <StaircaseEditPage {...props} {...routeProps} />} exact />
    <Route path="/admin/staircases/:id/edit" render={props => <StaircaseEditPage {...props} {...routeProps} />} exact />
    {/* <Route path="/admin/staircases/:id" render={props => <StaircaseViewPage {...props} {...routeProps} />} exact /> */}

    <Route path="/admin/staircase-sets/new/insert" render={props => <StaircaseSetEditPage {...props} {...routeProps} />} exact />
    <Route path="/admin/staircase-sets/:id/edit" render={props => <StaircaseSetEditPage {...props} {...routeProps} />} exact />
    {/* <Route path="/admin/staircase-sets/:id" render={props => <StaircaseSetViewPage {...props} {...routeProps} />} exact /> */}

    {/* Results and Data */}
    <Route path="/admin/sessions" render={props => <SessionTablePage {...props} {...routeProps} />} exact />
    <Route path="/admin/sessions/:id" render={props => <SessionViewPage {...props} {...routeProps} />} exact />

    {/* Users */}
    <Route path="/admin/users-by-role/participant" render={props => <UsersListPage {...props} {...routeProps} />} exact />
    <Route path="/admin/users-by-role/administrator" render={props => <UsersListPage {...props} {...routeProps} />} exact />
    <Route path="/admin/users-by-role/user" render={props => <UsersListPage {...props} {...routeProps} />} exact />
    <Route path="/admin/users-by-role/:role" render={props => <UsersListPage {...props} {...routeProps} />} exact />

    <Route path="/admin/users/new/insert" render={props => <UsersEditPage {...props} {...routeProps} />} exact />
    <Route path="/admin/users/:id/edit" render={props => <UsersEditPage {...props} {...routeProps} />} exact />
    <Route path="/admin/users/:id" render={props => <UsersViewPage {...props} {...routeProps} />} exact />
    <Route path="/admin/user-invitations/new/insert" render={props => <UserInvitationEditPage {...props} {...routeProps} />} exact />
    <Route path="/admin/user-invitations/new/insert/:role" render={props => <UserInvitationEditPage {...props} {...routeProps} />} exact />
    <Route path="/admin/user-invitations/:id/edit" render={props => <UserInvitationEditPage {...props} {...routeProps} />} exact />
    <Route path="/admin/user-invitations/:id" render={props => <UserInvitationEditPage {...props} {...routeProps} />} exact />
    <Route path="/admin/user-invitations" render={props => <UserInvitationListPage {...props} {...routeProps} />} exact />

    {/* Global Settings */}
    <Route path="/admin/settings/:id" render={props => <AppSettingEditPage {...props} {...routeProps} />} exact />

    {/* Errors */}
    <Route render={props => <NotFoundPage {...props} {...routeProps} />} />
  </AdminRouterSwitch>
);

AdminRouter.propTypes = {
  routeProps: PropTypes.object,
};
AdminRouter.defaultProps = {
  routeProps: null,
};

export default AdminRouter;
