import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import AdminList from '/components/AdminList';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import UsersIcon from 'mdi-material-ui/AccountGroup';
import AdminUsersIcon from 'mdi-material-ui/ShieldAccount';
import StairsIcon from 'mdi-material-ui/Stairs';
import BuildIcon from 'mdi-material-ui/Hammer';
import ResultsIcon from 'mdi-material-ui/ChartBar';
import TableIcon from 'mdi-material-ui/TableLarge';
import DatabaseIcon from 'mdi-material-ui/Database';
import UnknownUserIcon from 'mdi-material-ui/AccountQuestion';
import SetIcon from 'mdi-material-ui/CodeBraces';
import ParticipantIcon from 'mdi-material-ui/TestTube';
import AssignIcon from 'mdi-material-ui/ClipboardAccountOutline';
import SettingsIcon from 'mdi-material-ui/Settings';
import SiteIcon from 'mdi-material-ui/Earth';
import SessionIcon from 'mdi-material-ui/Train';
import InvitationsIcon from 'mdi-material-ui/AccountPlus';
import BeachIcon from 'mdi-material-ui/Beach';
import AirplaneIcon from 'mdi-material-ui/AirplaneTakeoff';
import ArrowIcon from 'mdi-material-ui/ArrowRightBold';
import HappyIcon from 'mdi-material-ui/EmoticonHappyOutline';

const styles = theme => ({
  root: {
    width: '100%',
  },
  items: {
    display: 'flex',
    alignItems: 'stretch',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  item: {
    width: 220,
    marginBottom: theme.spacing.unit * 2,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    justifyContent: 'center',
  },
  actionArea: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    justifyContent: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& svg': {
      fontSize: 75,
      color: theme.palette.primary.main,
    },
  },
  itemArrow: {
    fontSize: 50,
    color: theme.palette.text.hint,
    alignSelf: 'center',
  },
});

const ItemArrow = compose(
  withStyles(styles),
)(({ classes }) => (
  <ArrowIcon className={classes.itemArrow} />
));

const Item = compose(
  withStyles(styles),
  withRouter,
)(({ classes, text, icon, history, to, href }) => (
  <Card className={classes.item}>
    <CardActionArea onClick={to ? () => history.push(to) : undefined} className={classes.actionArea} component={to ? undefined : 'a'} href={to ? undefined : href}>
      <CardContent className={classes.content}>
        <div style={{ display: 'flex', alignItems: 'center' }}>{icon}</div>
        <Typography variant="body1" align="center">{text}</Typography>
      </CardContent>
    </CardActionArea>
  </Card>
));

class AdminPage extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    onTitleChange: PropTypes.func.isRequired,
  }

  static defaultProps = {
  }

  constructor(props) {
    super(props);

    const title = 'Admin Dashboard';
    props.onTitleChange(title);
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Helmet>
          <title>Admin Dashboard</title>
        </Helmet>

        <Typography variant="body2" align="center" gutterBottom>A typical workflow would got as follows:</Typography>
        <br />
        <div className={classes.items}>
          <Item text="Adjust global training session settings" icon={<SessionIcon />} to="/admin/settings/session" />
          <ItemArrow />
          <Item text="Make a few staircases" icon={<StairsIcon />} to="/admin/staircases" />
          <ItemArrow />
          <Item text="Group staircases into a staircase set" icon={<SetIcon />} to="/admin/staircase-sets" />
          <ItemArrow />
          <Item text="Invite some participants" icon={<ParticipantIcon />} to="/admin/user-invitations/new/insert?role=participant" />
          <ItemArrow />
          <Item text="Assign staircase sets to participants" icon={<AssignIcon />} to="/admin/staircase-set-assignment" />
          <ItemArrow />
          <Item text="Checkout the results" icon={<TableIcon />} to="/admin/results/table" />
          <ItemArrow />
          <Item text="Invite more admins to help you expand your empire" icon={<AdminUsersIcon />} to="/admin/user-invitations/new/insert?role=administrator" />
          <ItemArrow />
          <Item text={<>You're Done :-)</>} icon={<><AirplaneIcon /><ArrowIcon style={{ fontSize: 20 }} /><BeachIcon /></>} href="https://www.google.com/search?q=tropical+beaches&source=lnms&tbm=isch&sa=X&ved=0ahUKEwiz-NTo7IfjAhXilVQKHQP8BMoQ_AUIECgB&biw=1600&bih=769" />

        </div>
      </div>
    );
  }
}

AdminPage = compose(
  withStyles(styles),
  withRouter,
)(AdminPage);
AdminPage.displayName = 'AdminPage';
export default AdminPage;
