import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { compose } from 'react-apollo';
import _ from 'lodash';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import PlusIcon from 'mdi-material-ui/Plus';
import RemoveIcon from 'mdi-material-ui/Close';


import { withCurrentUser } from '/../../kiska/src/components/contexts/CurrentUserContext'; // eslint-disable-line import/no-extraneous-dependencies
import { MutationContext } from '/../../kiska/src/components/MutationContext/MutationContext'; // eslint-disable-line import/no-extraneous-dependencies
import Fixture from '/../../kiska/src/components/MutationContext/FormFields/Fixture'; // eslint-disable-line import/no-extraneous-dependencies
import NodeField from '/../../kiska/src/components/MutationContext/FormFields/NodeField'; // eslint-disable-line import/no-extraneous-dependencies
import { Typography } from '@material-ui/core';

const styles = theme => ({
  root: {

  },
  set: {
    display: 'flex',
    alignItems: 'center',
    '& > div:first-child': {
      flex: 1,
    },
  },
});

class SetAssignmentEditor extends React.Component {
  static contextType = MutationContext;

  static propTypes = {
    where: PropTypes.object,
    classes: PropTypes.object.isRequired,
  }

  static defaultProps = {
    where: null,
  };

  renderSet = (set) => {
    const { classes } = this.props;
    return (
      <Paper className={classes.set}>
        {set}
      </Paper>
    );
  }

  handleInsertSet = () => {
    const { onChange, fields, runDefaultMutation } = this.context;
    const sets = _.get(fields, 'data.value.sets', []);

    onChange(`data.sets[${sets.length}]`, null);
    runDefaultMutation();
  }

  handleRemoveSet = i => () => {
    const { onChange, fields } = this.context;
    const sets = _.get(fields, 'data.value.sets', []);

    sets.splice(i, 1);

    onChange(`data.sets`, sets);
  }

  render() {
    const { node } = this.context;
    const { where, classes } = this.props;
    const userId = _.get(where, 'userId._eq');

    const sets = _.get(node, 'data.sets', [null]);

    if (!userId || userId === 'null') {
      return <Typography>Select a user to create or edit an assignment.</Typography>;
    }

    return (
      <Grid container spacing={0}>
        <Fixture name="userId" value={userId} />

        {sets.map((set, i) => (
          <Grid item xs={12} key={i} className={classes.set}>
            <NodeField name={`data.sets[${i}]`} relatedType="staircase_set" label={`Set #${i + 1}`} />
            <IconButton onClick={this.handleRemoveSet(i)}><RemoveIcon /></IconButton>
          </Grid>
        ))}

        <Grid item xs={12}>
          <Button variant="contained" onClick={this.handleInsertSet}><PlusIcon /><span>Add Set</span></Button>
        </Grid>

        {/*
        <Grid item xs={12}>
          <NodeField name="data.sets[2]" relatedType="staircase_set" label="Fixed Set #2" />
        </Grid> */}

      </Grid>
    );
  }
}

SetAssignmentEditor = compose(
  withStyles(styles),
)(SetAssignmentEditor);

SetAssignmentEditor.displayName = 'SetAssignmentEditor';

export default SetAssignmentEditor;
