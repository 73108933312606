import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import MuiAppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import BackIcon from 'mdi-material-ui/ChevronLeft';
import IconButton from '@material-ui/core/IconButton';
import classNames from 'classnames';
import Tooltip from '@material-ui/core/Tooltip';
import { withRouter } from 'react-router';

import UserMenu from './UserMenu';
import { withCurrentUser } from '/../../kiska/src/components/contexts/CurrentUserContext';

const styles = theme => ({
  root: {
    // backgroundColor: barColor[900],
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: 0,
    // paddingRight: 0,
  },
  left: {
    display: 'flex',
    alignItems: 'center',
  },
});

class AppBar extends Component {
  static propTypes = {
    // currentUser: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    history: PropTypes.object.isRequired,
    title: PropTypes.node,
  }

  static defaultProps = {
    className: undefined,
    title: '',
  }

  render() {
    const { classes, className, history, title } = this.props;

    return (
      <MuiAppBar position="fixed" className={classNames(className, classes.root)}>
        <Toolbar className={classes.toolbar}>

          <Tooltip title="Back">
            <IconButton onClick={history.goBack} className={classes.menuButton} color="inherit">
              <BackIcon />
            </IconButton>
          </Tooltip>

          <Typography className={classes.title} align="center" variant="h6" color="inherit" noWrap>
            {title}
          </Typography>

          <UserMenu showSignIn />
        </Toolbar>
      </MuiAppBar>
    );
  }
}

AppBar = compose(
  withStyles(styles),
  withCurrentUser,
  withRouter,
)(AppBar);
export default AppBar;
