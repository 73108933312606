import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { NavLink } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';

import NormalLayout from '../layouts/NormalLayout';
import { NotFound } from '/../../kiska/src/components/statusedRoutes'; // eslint-disable-line import/no-extraneous-dependencies

export default class NotFoundPage extends Component {
  render() {
    return (
      <NotFound>
        <Helmet>
          <title>Not Found</title>
        </Helmet>
        <NormalLayout title="Page Not Found">
          <Typography variant="body1" align="center">Sorry, that page doesn't seem to exist :-(</Typography>
        </NormalLayout>
      </NotFound>
    );
  }
}
