import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-apollo';
import _ from 'lodash';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from 'mdi-material-ui/Pencil';
import ViewIcon from 'mdi-material-ui/Eye';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Paper from '@material-ui/core/Paper';
import classNames from 'classnames';
import CircularProgress from '@material-ui/core/CircularProgress';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { Link } from 'react-router-dom';

import withRouterWorkaround from '../withRouterWorkaround';
import { withThemeManager, MuiThemeProvider } from '../contexts/ThemeManagerContext'; // eslint-disable-line import/no-extraneous-dependencies
import { NodeListContext } from '.';
import PageControls from './PageControls';


const styles = theme => ({
  root: {
    position: 'relative',
  },
  card: {
    margin: `${theme.spacing.unit * 1}px 0 ${theme.spacing.unit * 1}px 0`,
  },
  content: {
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: 0,
  },
  action: {
    padding: '8px 8px',
    '& svg': {
      fontSize: 20,
    },
  },
  empty: {
    textAlign: 'center',
    padding: theme.spacing.unit * 4,
    margin: `${theme.spacing.unit * 2}px 0`,
  },
  loadingScreen: {
    position: 'absolute',
    width: '100%',
    backgroundColor: 'rgba(0,0,0,.6)',
    zIndex: 100,
    bottom: 0,
    top: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    borderRadius: 5,
    paddingTop: 50,
  },
});

class ListView extends Component {
  static propTypes = {
    firstOrderByDirection: PropTypes.string,
    invertTheme: PropTypes.bool,
    containerComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    editDialog: PropTypes.node,
    classes: PropTypes.object.isRequired,
    viewDialog: PropTypes.any,
    themeManager: PropTypes.object.isRequired,
    className: PropTypes.string,
    viewUrl: PropTypes.func,
    editUrl: PropTypes.func,
    renderTitle: PropTypes.func,
    renderContent: PropTypes.func,
    renderActions: PropTypes.func,
    history: PropTypes.object.isRequired,
    showEditButton: PropTypes.bool,
    showViewButton: PropTypes.bool,
  }

  static defaultProps = {
    firstOrderByDirection: 'asc_nulls_last',
    invertTheme: false,
    containerComponent: 'div',
    className: '',
    viewDialog: null,
    editDialog: null,
    viewUrl: null,
    editUrl: null,
    renderTitle: () => null,
    renderContent: () => null,
    renderActions: () => null,
    showViewButton: true,
    showEditButton: true,
  }

  constructor(props, context) {
    super(props, context);

    if (!context || !_.size(context)) throw new Error('Cannot find enclosing NodeList context for ListView.');

    this.hasRowActions = !!props.editDialog;

    this.state = {
      viewNodeId: null,
      editNode: null,
    };
  }

  renderRow = (node) => {
    const { classes, editDialog, renderTitle, renderContent, renderActions, editUrl, viewDialog, viewUrl, history, showViewButton, showEditButton } = this.props;

    const editProps = editDialog
      ? { onClick: () => this.openEditor(node) }
      : { component: Link, to: editUrl(node) };

    const viewProps = viewDialog
      ? { onClick: () => this.openView(node) }
      : { component: Link, to: viewUrl(node) };

    const renderedActions = renderActions(node, classes.action);
    const hasActions = showViewButton || showEditButton || renderedActions;

    return (
      <Card key={node.id} className={classes.card}>
        <CardActionArea onClick={() => history.push(viewUrl(node))}>
          <CardContent className={classes.content}>
            <Typography variant="h5">{renderTitle(node)}</Typography>
            {renderContent(node)}
          </CardContent>
        </CardActionArea>
        {hasActions ? (
          <CardActions className={classes.actions}>
            {showViewButton ? (
              <Tooltip title="View" className={classes.action}>
                <IconButton {...viewProps}><ViewIcon /></IconButton>
              </Tooltip>
            ) : null}
            {showEditButton ? (
              <Tooltip title="Edit" className={classes.action}>
                <IconButton {...editProps}><EditIcon /></IconButton>
              </Tooltip>
            ) : null}
            {renderedActions}
          </CardActions>
        ) : null}
      </Card>
    );
  }

  renderLoader = (loading) => {
    if (!loading) return null;
    const { classes } = this.props;

    return (
      <div className={classes.loadingScreen}>
        <CircularProgress size={100} thickness={2} color="primary" />
      </div>
    );
  }

  closeView = () => {
    this.setState({ viewNodeId: null });
  }

  openView = (nodeId) => {
    this.setState({ viewNodeId: nodeId });
  }

  closeEditor = () => {
    this.setState({ editNode: null });
  }

  openEditor = (node) => {
    this.setState({ editNode: node });
  }

  renderEmpty = () => {
    const { schemaType } = this.context;
    const { classes } = this.props;

    return (
      <Paper className={classes.empty}>
        <Typography variant="h4" align="center" gutterBottom>
          :-(
        </Typography>
        <Typography variant="body1" align="center">
          No {schemaType.pluralLabel} found.<br />
          Try adjusting your filters or search criteria.
        </Typography>
      </Paper>
    );
  }

  render() {
    const { nodes, result: { loading } } = this.context;
    const { viewNodeId, editNode } = this.state;
    const { containerComponent, className, viewDialog, invertTheme, classes, editDialog, themeManager: { activeTheme, inverseTheme } } = this.props;
    const theme = invertTheme ? inverseTheme : activeTheme;

    const viewDialogWithProps = viewDialog ? React.cloneElement(viewDialog, {
      nodeId: viewNodeId,
      open: !!viewNodeId,
      onClose: this.closeView,
    }) : null;

    const editDialogWithProps = editDialog ? React.cloneElement(editDialog, {
      node: editNode,
      open: !!editNode,
      onClose: this.closeEditor,
      onNodeChange: this.openEditor,
    }) : null;

    const ContainerComponent = containerComponent;

    return (
      <>
        {/* <MuiThemeProvider theme={theme}> */}
        {viewDialogWithProps}
        {editDialogWithProps}
        <ContainerComponent className={classNames(classes.root, className)}>
          {this.renderLoader(loading)}
          {(nodes && nodes.length) ? nodes.map(this.renderRow) : this.renderEmpty()}
          <PageControls />
        </ContainerComponent>
        {/* </MuiThemeProvider> */}
      </>
    );
  }
}

ListView.contextType = NodeListContext;

ListView = compose(
  withStyles(styles),
  withThemeManager,
  withRouterWorkaround,
)(ListView);

export default ListView;
