import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from 'mdi-material-ui/Magnify';

import { NodeListContext } from '.';

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  textField: {
    // maxWidth: '100%',
    // width: '20em',
    width: '100%',
  },
  searchInput: {
    // padding: '16px 0 16px 0',
  },
});

class LikeFilter extends Component {
  static contextType = NodeListContext

  static propTypes = {
    label: PropTypes.node,
    classes: PropTypes.object.isRequired,
    not: PropTypes.bool,
    path: PropTypes.string.isRequired,
    caseSensitive: PropTypes.bool,
    autoWildcards: PropTypes.bool,
    textFieldProps: PropTypes.object,
    search: PropTypes.bool,
  }

  static defaultProps = {
    label: `Contains the text:`,
    not: false,
    caseSensitive: false,
    autoWildcards: true,
    textFieldProps: {},
    search: false,
  }

  handleChange = (event) => {
    const { autoWildcards } = this.props;
    let value = event.target.value;
    if (value && autoWildcards) value = `%${value}%`;
    this.change(value);
  }

  getCondition = () => {
    const { not, caseSensitive } = this.props;
    // Returns _like, _ilike, _nlike, or _nilike
    const condition = `_${not ? 'n' : ''}${caseSensitive ? '' : 'i'}like`;
    return condition;
  }

  change = (value) => {
    const { setVariables } = this.context;
    const { path } = this.props;
    const condition = this.getCondition();

    setVariables(({ where }) => {
      if (value) {
        _.set(where, `${path}.${condition}`, value);
      } else {
        _.set(where, `${path}.${condition}`, undefined);
      }
      return { where };
    });
  }

  clear = (event) => {
    this.change(undefined);
  }

  render() {
    const { variables } = this.context;
    const { classes, label: propsLabel, path, not, autoWildcards, textFieldProps: propsTextFieldProps, search } = this.props;
    const condition = this.getCondition();
    const label = not ? 'Does not contain the text:' : propsLabel;

    let value = _.get(variables.where, `${path}.${condition}`);
    if (!value) value = '';
    if (autoWildcards) {
      value = value.slice(1, -1);
    }

    let textFieldProps = propsTextFieldProps;
    if (search) {
      textFieldProps = {
        variant: 'outlined',
        InputProps: {
          startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
          inputProps: {
            className: classes.searchInput,
          },
        },
        ...textFieldProps,
      };
    }

    return (
      <div className={classNames(classes.root)}>
        <TextField className={classes.textField} label={label} value={value} onChange={this.handleChange} {...textFieldProps} />
      </div>
    );
  }
}

LikeFilter = withStyles(styles)(LikeFilter);
export default LikeFilter;
