import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import AdminList from '/components/AdminList';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';

const styles = theme => ({
  root: {
    width: '100%',
  },
});

class UserListPage extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    onTitleChange: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
  }

  static defaultProps = {
  }

  static getRoleAndTitle = (props) => {
    const { match: { params, path } } = props;

    let role = params.role || path.split('/').pop();
    const urlRole = role;
    if (role === 'administrator') role = 'site-owner';

    let title;
    switch (role) {
      case 'participant': title = 'Participants'; break;
      case 'site-owner': title = 'Administrators'; break;
      default: title = 'Users';
    }

    return { role, title, urlRole };
  }

  constructor(props) {
    super(props);

    const { title } = UserListPage.getRoleAndTitle(props);
    props.onTitleChange(title);
  }

  componentDidUpdate = (prevProps) => {
    const { title } = UserListPage.getRoleAndTitle(this.props);
    const { title: prevTitle } = UserListPage.getRoleAndTitle(prevProps);

    if (title !== prevTitle) {
      this.props.onTitleChange(title);
    }
  }

  render() {
    const { classes } = this.props;
    const { role, title, urlRole } = UserListPage.getRoleAndTitle(this.props);

    let where;
    if (role) {
      where = { role: { name: { _ilike: role } } };
    }

    return (
      <div className={classes.root}>
        <Helmet>
          <title>{title}</title>
        </Helmet>

        <AdminList
          key={role}
          type="user"
          where={where}
          likeFilterProps={{ path: 'displayName', label: 'Search by name' }}
          renderTitle={node => `${node.displayName}`}
          editUrl={node => `/admin/users/${node.id}/edit`}
          viewUrl={node => `/admin/users/${node.id}`}
          insertUrl={`/admin/user-invitations/new/insert?role=${urlRole}`}
          insertTitle="Invite new user"
          orderByFields="id, displayName, email, lastSignInAt, updatedAt, createdAt"
        />;
      </div>
    );
  }
}

UserListPage = compose(
  withStyles(styles),
  withRouter,
)(UserListPage);

export default UserListPage;
