import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import { NodeListContext } from '.';

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    marginRight: theme.spacing.unit * 1,
  },
  select: {
    color: theme.palette.secondary.main,
  },
});

class LimitSelector extends Component {
  static contextType = NodeListContext

  static propTypes = {
    classes: PropTypes.object.isRequired,
    options: PropTypes.array,
    className: PropTypes.string,
  }

  static defaultProps = {
    options: [25, 50, 100, 500],
    className: '',
  }

  handleChange = (event) => {
    const { setVariables } = this.context;
    setVariables(variables => ({ limit: event.target.value }));
  }

  render() {
    const { variables: { limit } } = this.context;
    const { classes, className, options, ...rest } = this.props;

    return (
      <div className={classNames(classes.root, className)} {...rest}>
        <Typography variant="body2" className={classes.label}>Results per page:</Typography>
        <Select value={limit} onChange={this.handleChange} className={classes.select} disableUnderline>
          {options.map(option => <MenuItem value={option} key={option}>{option}</MenuItem>)}
        </Select>
      </div>
    );
  }
}

LimitSelector = withStyles(styles)(LimitSelector);
export default LimitSelector;
