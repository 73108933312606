import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';

import AdminViewPage from '/components/AdminViewPage';

const styles = theme => ({
  root: {
  },
});

class UserEditPage extends Component {
  static propTypes = {
    onTitleChange: PropTypes.func.isRequired,
  }

  static defaultProps = {
  }

  render() {
    const { onTitleChange } = this.props;

    return (
      <AdminViewPage
        type="user"
        onTitleChange={onTitleChange}
        renderTitle={node => `User: ${node.displayName}`}
        editUrl={node => `/admin/users/${node.id}/edit`}
      />
    );
  }
}

UserEditPage = compose(
  withStyles(styles),
  withRouter,
)(UserEditPage);

export default UserEditPage;
