export default {
  types: {
    post: {

      selections: {
        default: 'id body owner{id name email}',
      },

      fields: {
        body: {
          type: 'String',
        },

      },
    },
  },
};
