import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Helmet } from 'react-helmet';

import { NotFound } from '/../../kiska/src/components/statusedRoutes';

const styles = theme => ({
  root: {
    // width: '100%',
    margin: '148px auto',
  },
  message: {
  },
});

class NotFoundPage extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    onTitleChange: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    props.onTitleChange('Not Found');
  }

  render() {
    const { classes } = this.props;

    return (
      <NotFound>
        <div className={classes.root}>
          <Typography variant="h1" className={classes.message} align="center">
            :-(<br /><br />
          </Typography>
          <Typography variant="body1" align="center">
            Sorry, couldn't find that page.<br />
            In computer talk, that's a 404.
          </Typography>
        </div>
      </NotFound>
    );
  }
}

NotFoundPage = compose(
  withStyles(styles),
)(NotFoundPage);

export default NotFoundPage;
