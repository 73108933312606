export default {
  types: {
    app_setting: {

      selections: {
        default: 'id name label json text int file{id name url} files{id file{id name url}}',
      },

      fields: {
        name: {
          type: 'String',
        },
        label: {
          type: 'String',
        },
        json: {
          type: 'Json',
        },
        text: {
          type: 'String',
        },
        int: {
          type: 'Int',
        },
        file: {
          type: 'file',
        },
        files: {
          type: 'file',
          hasMany: true,
        },

      },
    },
  },
};
