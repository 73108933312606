import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';

import CrudPageForm from '/components/CrudPageForm';

const styles = theme => ({
  root: {
  },
});

class AppSettingEditPage extends Component {
  static propTypes = {
    onTitleChange: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
  }

  static defaultProps = {
  }

  render() {
    const { onTitleChange, match: { params } } = this.props;

    return (
      <CrudPageForm
        type="app_setting"
        onTitleChange={onTitleChange}
        renderTitle={node => node.label}
        editorProps={{ id: params.id }}
      />
    );
  }
}

AppSettingEditPage = compose(
  withStyles(styles),
  withRouter,
)(AppSettingEditPage);

AppSettingEditPage.displayName = 'AppSettingEditPage';

export default AppSettingEditPage;
