import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import SaveIcon from 'mdi-material-ui/ContentSave';
import green from '@material-ui/core/colors/green';
import PlusIcon from 'mdi-material-ui/Plus';
import CopyIcon from 'mdi-material-ui/ContentCopy';

import { MutationContext } from './MutationContext';
import MutateButton from './MutateButton';

const styles = theme => ({
  root: {
    backgroundColor: green[700],
    color: '#FFF',

    '&:hover': {
      backgroundColor: green[900],
    },
  },
});

class SaveButton extends Component {
  static contextType = MutationContext;

  static propTypes = {
    text: PropTypes.node,
    children: PropTypes.node,
    icon: PropTypes.node,
    saveAndClone: PropTypes.bool,
    classes: PropTypes.object.isRequired,
  }


  static defaultProps = {
    saveAndClone: false,
    text: null,
    icon: null,
    children: null,
  }

  render() {
    const { node } = this.context;
    const { text, children, icon, classes, saveAndClone, ...rest } = this.props;

    const isNew = !(node && node.id);
    const mutationName = isNew ? 'insert' : 'update';
    const iconContent = icon || (saveAndClone ? (
      <>
        <SaveIcon style={{ marginRight: 0 }} />
        <PlusIcon />
        <CopyIcon style={{ marginRight: 8 }} />
      </>
    ) : <SaveIcon />);
    const textContent = text || (saveAndClone ? 'Save & Clone' : 'Save');
    let content = children;
    if (!content) {
      content = (
        <>
          {iconContent}
          <span>{textContent}</span>
        </>
      );
    }

    return (
      <MutateButton className={classes.root} variant="contained" color="inherit" mutationName={mutationName} mutationMeta={{ saveAndClone }} {...rest}>
        {content}
      </MutateButton>
    );
  }
}

SaveButton = withStyles(styles)(SaveButton);
export default SaveButton;
