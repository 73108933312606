import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { compose } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from 'mdi-material-ui/Plus';

import NodeList from '/../../kiska/src/components/NodeList'; // eslint-disable-line import/no-extraneous-dependencies
import OrderBySelect from '/../../kiska/src/components/NodeList/OrderBySelect'; // eslint-disable-line import/no-extraneous-dependencies
import ListView from '/../../kiska/src/components/NodeList/ListView';
import LikeFilter from '../../../kiska/src/components/NodeList/LikeFilter';

const styles = theme => ({
  root: {
    width: '100%',
  },
  controls: {
    marginBottom: theme.spacing.unit * 2,
  },
  fab: {
    bottom: 0,
    position: 'sticky',
    left: 'calc(100% - 50px)',
  },
});

class AdminList extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
    likeFilterProps: PropTypes.object.isRequired,
    renderTitle: PropTypes.func.isRequired,
    editUrl: PropTypes.func.isRequired,
    viewUrl: PropTypes.func.isRequired,
    insertUrl: PropTypes.string.isRequired,
    insertTitle: PropTypes.string.isRequired,
    where: PropTypes.object,
    orderByFields: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    renderContent: PropTypes.func,
    showEditButton: PropTypes.bool,
    showViewButton: PropTypes.bool,
    renderActions: PropTypes.func,
  }

  static defaultProps = {
    where: undefined,
    orderByFields: undefined,
    renderContent: undefined,
    showEditButton: undefined,
    showViewButton: undefined,
    renderActions: undefined,
  }

  render() {
    const { classes, type, likeFilterProps, editUrl, viewUrl, insertUrl, renderTitle, renderActions, insertTitle, where, orderByFields, renderContent, showEditButton, showViewButton } = this.props;

    return (
      <div className={classes.root}>
        <NodeList type={type} initialWhere={where}>
          <Grid container spacing={16} className={classes.controls}>
            <Grid item xs={12} sm={6}>
              <LikeFilter search {...likeFilterProps} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <OrderBySelect fields={orderByFields} />
            </Grid>
          </Grid>
          <ListView
            renderTitle={renderTitle}
            editUrl={editUrl}
            insertUrl={insertUrl}
            viewUrl={viewUrl}
            renderContent={renderContent}
            showViewButton={showViewButton}
            showEditButton={showEditButton}
            renderActions={renderActions}
          />
        </NodeList>
        <Tooltip title={insertTitle}>
          <Fab className={classes.fab} color="primary" component={Link} to={insertUrl}>
            <AddIcon />
          </Fab>
        </Tooltip>
      </div>
    );
  }
}

AdminList = compose(
  withStyles(styles),
)(AdminList);

export default AdminList;
