import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

const StatusedRoute = ({ code, children }) => (
  <Route
    render={({ staticContext }) => {
      if (staticContext) staticContext.status = code;
      return children;
    }}
  />
);
StatusedRoute.propTypes = {
  code: PropTypes.number.isRequired,
  children: PropTypes.node,
};
StatusedRoute.defaultProps = {
  children: null,
};

const NotFound = ({ children }) => (
  <StatusedRoute code={404}>
    {children || 'Not Found :-('}
  </StatusedRoute>
);
NotFound.propTypes = {
  children: PropTypes.node,
};
NotFound.defaultProps = {
  children: null,
};

export { StatusedRoute, NotFound };
