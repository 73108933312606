import gql from 'graphql-tag';
import { addIdsToSelections } from '../../utils';


const buildQuery = ({ type, schema, selections }) => {
  selections = addIdsToSelections(selections);

  const query = gql`
    query NodeList(
      $where: ${type}_bool_exp, 
      $orderBy: [${type}_order_by!], 
      $offset: Int, 
      $limit: Int
      ){

      nodes: ${type}(where: $where, order_by: $orderBy, offset: $offset, limit: $limit){
        ${selections}
      }

      meta: ${type}_aggregate(where: $where){
        aggregate {
          count
        }
      }
    }
  `;

  return query;
};

export default buildQuery;
