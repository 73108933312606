import schema from './schema';
import settings from '../settings';
import { themes } from './theme';

const options = {
  ...settings(),
  theme: {
    default: 'light',
    themes,
  },
  schema,
};

export default options;
