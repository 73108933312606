import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import NodeSelector from '/../../kiska/src/components/NodeSelector';
import CrudPageForm from '/components/CrudPageForm';

const styles = theme => ({
  root: {
  },
});

class StaircaseSetAssignmentPage extends Component {
  static propTypes = {
    onTitleChange: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
  }

  static defaultProps = {
  }

  constructor(props) {
    super(props);

    this.state = {
      userId: 'null',
    };
  }

  handleUserChange = (user) => {
    if (!user) return;

    this.setState({ userId: user.id });
  }

  render() {
    const { userId } = this.state;
    const { onTitleChange, classes } = this.props;

    return (
      <Grid container spacing={24} className={classes.root}>
        <Typography variant="body1" gutterBottom>
          Here you can select a user and then assign to that user which Staircase Sets they can train on.
          You can search for users by their name, email address, or ID. You can search for Staircase Sets
          by their title.
          <br /><br />
          Users must complete the sets in the order given here.
          You can leave blank slots in the middle of the sequence (ie: empty, no set lines).
          This will stop the user from progressing past this point until you remove the empty slot.
        </Typography>

        <Grid item xs={12}>
          <NodeSelector type="user" label="User" onChange={this.handleUserChange} />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5">Assignment:</Typography>
          <CrudPageForm
            key={userId}
            type="set_assignment"
            where={{ userId: { _eq: userId } }}
            onTitleChange={onTitleChange}
            renderTitle={node => (node ? `Assign Staircase Sets to ${node.user.displayName}` : 'Assign Staircase Sets')}
            editorProps={{ variant: 'staircase-set-assignment' }}
            redirectOnInsert={false}
          />
        </Grid>
      </Grid>
    );
  }
}

StaircaseSetAssignmentPage = compose(
  withStyles(styles),
  withRouter,
)(StaircaseSetAssignmentPage);

export default StaircaseSetAssignmentPage;
