export default {
  types: {
    tag: {

      renderers: {
        inline: node => node.label,
      },

      selections: {
        default: 'id name label',
      },

      fields: {
        name: {
          type: 'String',
          validation: {
            required: true,
          },
        },

        label: {
          type: 'String',
          validation: {
            required: true,
          },
        },

        users: {
          type: 'user',
          hasMany: true,
        },


      },

    },
  },
};
