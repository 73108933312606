import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-apollo';
import FormControl from '@material-ui/core/FormControl';
import { withStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import _ from 'lodash';
import FormHelperText from '@material-ui/core/FormHelperText';

import splitFieldName from '../splitFieldName';
import HelperText from './HelperText';
import { MutationContext } from '../MutationContext';

const testOptions = [
  { value: 10, primary: 'Ten' },
  { value: 20, primary: 'Twenty', secondary: 'A bigger number' },
  { value: 30, primary: 'Thirty', secondary: 'Yet a bigger number' },
  { value: 40, primary: 'Forty' },
  { value: 50, primary: 'Fifty' },
  { value: 60, primary: 'Sixy' },
];

const styles = theme => ({
  root: {
    display: 'flex',
    flexBasis: 'column',
    justifyContent: 'center',
  },
});

class SelectField extends Component {
  static contextType = MutationContext;

  static propTypes = {
    name: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
    helperText: PropTypes.node,
    label: PropTypes.string,
    multiple: PropTypes.bool,
    options: PropTypes.array,
    initialValue: PropTypes.string,
  }

  static defaultProps = {
    helperText: undefined,
    label: '',
    multiple: false,
    options: testOptions,
    initialValue: undefined,
  }

  constructor(props, context) {
    super(props, context);
    const { registerField } = context;
    const { rootFieldName, extFieldName, valueFieldName } = splitFieldName(props.name);
    this.rootName = rootFieldName; this.extName = extFieldName; this.valueName = valueFieldName;

    registerField(props.name, { initialValue: props.initialValue });
  }

  handleChange = (event) => {
    const { onChange } = this.context;
    const { name } = this.props;
    const value = event.target.value;

    onChange(name, value);
  }

  valueToPrimary = (value) => {
    const { options } = this.props;
    const option = options.find(o => o.value === value);
    return option ? option.primary : '';
  }

  renderValue = (value) => {
    const { multiple } = this.props;

    if (multiple) return value.map(this.valueToPrimary).join(', ');

    return this.valueToPrimary(value);
  }

  render() {
    const { classes, helperText, label: propsLabel, multiple, options } = this.props;
    const { fields, pendingMutation, schema, type } = this.context;
    const schemaField = schema.types[type].fields[this.rootName];
    const label = propsLabel || schemaField.label;
    const errors = fields[this.rootName].errors;

    let value = _.get(fields, this.valueName);
    if (value === undefined || value === null) value = '';
    if (multiple && !Array.isArray(value)) value = [];

    return (
      <FormControl error={!!errors.length} disabled={pendingMutation} className={classes.root}>
        <InputLabel>{label}</InputLabel>
        <Select value={value} onChange={this.handleChange} multiple={multiple} renderValue={this.renderValue}>
          {options.map(o => (
            <MenuItem key={o.value} value={o.value}>
              {multiple ? <Checkbox checked={value.includes(o.value)} /> : null}
              <ListItemText primary={o.primary} secondary={o.secondary} />
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>
          <HelperText text={helperText} errors={errors} />
        </FormHelperText>
      </FormControl>
    );
  }
}

SelectField = compose(
  withStyles(styles),
)(SelectField);

export default SelectField;
