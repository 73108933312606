import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import _ from 'lodash';
import { compose } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';

import SaveButton from '/../../kiska/src/components/MutationContext/SaveButton';
import { MutationContext } from '/../../kiska/src/components/MutationContext/MutationContext'; // eslint-disable-line import/no-extraneous-dependencies
import TextField from '/../../kiska/src/components/MutationContext/FormFields/TextField'; // eslint-disable-line import/no-extraneous-dependencies
import FileUplaoder from '/../../kiska/src/components/MutationContext/FormFields/FileField'; // eslint-disable-line import/no-extraneous-dependencies
import BooleanField from '/../../kiska/src/components/MutationContext/FormFields/BooleanField';
import SelectField from '../../../../kiska/src/components/MutationContext/FormFields/SelectField';
import StaircaseInheritedConfigEditor from '../StaircaseInheritedConfigEditor';
import EditorSection from '../EditorSection';
import { getAllLevelsFaces } from '/utils';

const styles = theme => ({
  level: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    backgroundColor: 'rgba(255,255,255,.15)',
    borderRadius: 5,
    padding: theme.spacing.unit * 1,
    margin: '8px 0',
  },
  levelFace: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0 16px',
    '& img': {
      height: 50,
      borderRadius: 5,
    },
  },
});

const Level = compose(
  withStyles(styles),
)(({ classes, level }) => (
  <div className={classes.level}>
    <Typography variant="h6">Level {level.level + 1}:</Typography>
    <div className={classes.levelFace}>
      <Typography variant="body1" color="textPrimary">Key A</Typography>
      <img src={level.keys[0].url} alt="Key A" />
      <Typography variant="body2" color="textSecondary">{level.keys[0].name}</Typography>
    </div>
    <div className={classes.levelFace}>
      <Typography variant="body1" color="textPrimary">Option A</Typography>
      <img src={level.options[0].url} alt="Option A" />
      <Typography variant="body2" color="textSecondary">{level.options[0].name}</Typography>
    </div>
    <div className={classes.levelFace}>
      <Typography variant="body1" color="textPrimary">Option B</Typography>
      <img src={level.options[1].url} alt="Option B" />
      <Typography variant="body2" color="textSecondary">{level.options[1].name}</Typography>
    </div>
    <div className={classes.levelFace}>
      <Typography variant="body1" color="textPrimary">Key B</Typography>
      <img src={level.keys[1].url} alt="Key B" />
      <Typography variant="body2" color="textSecondary">{level.keys[1].name}</Typography>
    </div>
  </div>
));

class StaircaseEditor extends React.Component {
  static contextType = MutationContext;

  render() {
    const images = _.get(this.context, 'fields.images.value');
    const levelsFaces = getAllLevelsFaces(images);

    return (
      <Grid container spacing={24}>
        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <SaveButton />
        </Grid>

        <Grid item xs={12}>
          <TextField name="label" />
        </Grid>

        <Grid item xs={12} md={12}>
          <StaircaseInheritedConfigEditor />
        </Grid>

        <Grid item xs={12} md={12}>
          <EditorSection title="Review Levels">
            <div style={{ flexDirection: 'row' }}>
              <Typography variant="body2" component="div">
                You do not directly set/create levels here, this is only for review purposes to ensure your file names are following
                the acceptable format and you have uploaded a complete set. The file naming format is quite flexible&emdash;the
                images you upload are simply sorted by filename according to the following rules:
                <ul>
                  <li>English/Latin alaphabet order (other characters may produce weird but consistent results)</li>
                  <li>Digits before letters, eg: 001-ZZZ.jpg preceeds AAA-001.jpg</li>
                  <li>Uppercase before lower case, A.jpg preceeds a.jpg</li>
                  <li>Punctuation before alphanumeric, eg: !Z.jpg preceeds A.jpg</li>
                  <li>
                    Blank positions before filled positions, eg: 1.jpg preceeds 002.jpg<br />
                    This rule makes it important to keep filenames the same number of characters long and use leading zeros, eg: 001.jpg instead of 1.jpg.
                  </li>
                </ul>
                Once sorted, the first and last images are the "key" faces, 2nd and 2nd last images are Level 1 options,
                  3rd and 3rd last images are Level 2 options, etc. There is no fixed limit for number of levels.
                <br /><br />
                If you see a problem with the levels below, check that all the images have been uploaded. You can remove individual images
                and add new ones.
                <br /><br />
              </Typography>
              {levelsFaces.map(level => <Level level={level} key={level.options[0].id} />)}
            </div>
          </EditorSection>
        </Grid>

        <Grid item xs={12} md={12}>
          {/* <Typography variant="h6">Images</Typography> */}
          <FileUplaoder name="images" />
        </Grid>


      </Grid>
    );
  }
}

StaircaseEditor = compose(
  withStyles(styles),
)(StaircaseEditor);
StaircaseEditor.displayName = 'StaircaseEditor';
export default StaircaseEditor;
