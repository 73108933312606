/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import _ from 'lodash';

import { LabeledValue } from './helpers';

const UserView = ({ node }) => {
  // console.log('user: ', node);
  const sets = _.get(node, 'setAssignment.data.sets', []);

  return (
    <Grid container spacing={24}>
      <Grid item xs={12} sm={6}>
        <LabeledValue label="Name" value={node.displayName} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <LabeledValue label="Email" value={node.email} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <LabeledValue label="System ID" value={node.id} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <LabeledValue label="Participant ID" value={node.participantId} />
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography variant="h6" color="textSecondary" gutterBottom>Assigned Training</Typography>
        {sets.map((set) => {
          if (!set) return null;
          return (
            <Typography key={set.id} variant="body2">{set.label}</Typography>
          );
        })}
      </Grid>
    </Grid>
  );
};

UserView.propTypes = {
  node: PropTypes.object.isRequired,
};

export default UserView;
