import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'react-apollo';
import Grid from '@material-ui/core/Grid';

import Scrollbar from '../../../Scrollbar';
import FilePreview from './FilePreview';

const styles = theme => ({
  root: {
    // overflowY: 'auto',
    // overflowX: 'hidden',
    maxHeight: 300,
  },
  scrollbar: {
    backgroundColor: '#5D5D5D',
    borderRadius: 5,
  },
  grid: {
    borderRadius: 5,
    backgroundColor: '#5D5D5D',
    width: '100%',
    padding: theme.spacing.unit * 1,
    paddingBottom: 75,
    margin: 0,
  },
});

class FilePreviewList extends React.Component {
  static propTypes = {
    files: PropTypes.array,
    classes: PropTypes.object.isRequired,
    onRemove: PropTypes.func,
    children: PropTypes.node,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }

  static defaultProps = {
    files: [],
    onRemove: null,
    children: null,
    height: undefined,
  }

  render() {
    const { classes, files: propFiles, onRemove, children, height } = this.props;

    let files = propFiles;
    if (!files) files = [];
    else if (!Array.isArray(propFiles)) files = [files];

    const content = (
      <>
        <Grid container spacing={16} className={classes.grid}>
          {files.map(file => (
            <Grid item key={file.id}>
              <FilePreview file={file} onRemove={onRemove} />
            </Grid>
          ))}
        </Grid>
        {children}
      </>
    );

    if (!height) {
      return content;
    }

    return <Scrollbar height={height} className={classes.scrollbar}>{content}</Scrollbar>;
  }
}

FilePreviewList = compose(
  withStyles(styles),
)(FilePreviewList);


export default FilePreviewList;
