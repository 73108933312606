import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DeleteIcon from 'mdi-material-ui/Delete';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { compose } from 'react-apollo';
import red from '@material-ui/core/colors/red';

import { MutationContext } from './MutationContext';
import MutateButton from './MutateButton';
import ConfirmationDialog from '../ConfirmationDialog';

const styles = theme => ({
  root: {
    backgroundColor: red[500],
    color: '#FFF',

    '&:hover': {
      backgroundColor: red[800],
    },
  },
});

class DeleteButton extends Component {
  static contextType = MutationContext;

  static propTypes = {
    text: PropTypes.node,
    children: PropTypes.node,
    icon: PropTypes.node,
    classes: PropTypes.object.isRequired,
  }

  static defaultProps = {
    text: null,
    children: null,
    icon: null,
  }

  render() {
    const { node, schema, type } = this.context;
    const { text, children, icon, classes } = this.props;
    const schemaType = schema.types[type];

    const isNew = !(node && node.id);
    if (isNew) return null;

    const mutationName = 'delete';
    const iconContent = icon || <DeleteIcon />;
    const textConent = text || 'Delete';
    let content = children;
    if (!content) {
      content = (
        <>
          {iconContent}
          <span>{textConent}</span>
        </>
      );
    }

    const commonButtonProps = {
      variant: 'contained',
      className: classes.root,
      children: content,
    };

    const requestDeleteButton = <Button {...commonButtonProps} />;
    const confirmDeleteButton = <MutateButton {...commonButtonProps} mutationName={mutationName} />;

    const message = (
      <>
        Are you sure you want to delete this
        {' '}
        {schemaType.lowerCaseLabel}
        ?
        <br />
        <br />
        This action
        {' '}
        <b>cannot be undone</b>
        {' '}
        without restoring a complete database backup.
      </>
    );

    return (
      <ConfirmationDialog
        trigger={requestDeleteButton}
        confirmButton={confirmDeleteButton}
        title="Confirm Delete"
        content={message}
      />
    );
  }
}

DeleteButton = compose(
  withStyles(styles),
)(DeleteButton);

export default DeleteButton;
