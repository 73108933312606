const getFiltersForField = (field, schemaType) => {
  const path = field.name;

  switch (field.type) {
    case 'String':
      return [
        { type: 'like', path },
        { type: 'null', path },
      ];

    case 'Int':
    case 'Float':
      return [
        { type: 'number-between', path },
        { type: 'null', path },
      ];

    case 'Timestamp':
      return [
        { type: 'date-between', path },
        { type: 'null', path },
      ];

    case 'Boolean':
      return [{ type: 'boolean', path }];

    case 'Json':
      return [];
    default:
  }

  if (!field.isScaler) {
    return [
      { type: 'node', path },
      { type: 'null', path },
    ];
  }

  return null;
};

export default getFiltersForField;
