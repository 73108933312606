import React, { Component, createContext } from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import _ from 'lodash';

import { SchemaContext } from '../contexts/SchemaContext'; // eslint-disable-line import/no-extraneous-dependencies
import { addIdsToSelections } from '../../utils';

class OneNode extends Component {
  static contextType = SchemaContext;

  static propTypes = {
    type: PropTypes.string.isRequired,
    selections: PropTypes.string,
    selectionSet: PropTypes.string,
    renderNode: PropTypes.func.isRequired,
    renderEmpty: PropTypes.func,
    renderLoading: PropTypes.func,
    renderError: PropTypes.func,
    id: PropTypes.string,
    where: PropTypes.object,
  }

  static defaultProps = {
    selections: '',
    selectionSet: 'default',
    renderEmpty: () => null,
    renderLoading: () => null,
    renderError: () => null,
    id: '',
    where: null,
  }

  constructor(props, context) {
    super(props, context);

    const { type, where } = props;
    const schema = context;
    const schemaType = _.get(schema, `types[${type}]`);
    if (!schemaType) throw new Error(`Could not find schema for type "${type}".`);
    let selections = props.selections || _.get(schemaType, `selections.${props.selectionSet}`);
    selections = addIdsToSelections(selections);

    if (where) {
      this.query = gql`
        query OneNode($where: set_assignment_bool_exp){
          nodes: ${type}(where: $where){
            ${selections}
          }
        }
      `;
    } else {
      this.query = gql`
        query OneNode($id: String!){
          node: ${type}_by_pk(id: $id){
            ${selections}
          }
        }
      `;
    }

    this.state = {
    };
  }

  handleError = (error) => {
    const { type } = this.props;
    console.error(`Error in OneNode query for ${type}: `, error);
  }

  handleCompleted = (data) => {
    // console.log('Completed data fetch for OneNode <Query>.');
  }

  render() {
    const { id, where, renderNode, renderError, renderEmpty, renderLoading } = this.props;
    let variables;

    if (where) variables = { where };
    else variables = { id };

    if (!where && !id) return renderEmpty();

    return (
      <Query
        query={this.query}
        variables={variables}
        displayName="OneNodeQuery"
        onCompleted={this.handleCompleted}
        onError={this.handleError}
        fetchPolicy="cache-and-network"
      >
        {({ data, error, loading }) => {
          if (error) return renderError(error);
          if (loading && !data.node) return renderLoading();

          if (where) {
            if (!data.nodes && !data.nodes.length) return renderEmpty();
            return renderNode(data.nodes[0]);
          }

          if (!data.node) return renderEmpty();
          return renderNode(data.node);
        }}

      </Query>
    );
  }
}

export default OneNode;
