import React from 'react';
import PropTypes from 'prop-types';
import formatDate from 'date-fns/format';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'react-apollo';

const styles = theme => ({
  label: {
    color: '#666',
  },
  value: {

  },
});

const LabeledValue = compose(
  withStyles(styles),
)(({ label, value, classes }) => {
  if (value instanceof Date) {
    value = (
      <>
        {formatDate(value, 'dd-MMM-yyyy')}<br />
        at {formatDate(value, 'h:mma').toLowerCase()}
      </>
    );
  }

  return (
    <>
      <Typography variant="subtitle1" className={classes.label}>{label}:</Typography>
      <Typography variant="body2" className={classes.value}>{value}</Typography>
    </>
  );
});

LabeledValue.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.any,
};
LabeledValue.defaultProps = {
  value: 'no value',
};

export { LabeledValue };
