import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet';
import { withRouter, Link } from 'react-router-dom';
import _ from 'lodash';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from 'mdi-material-ui/Pencil';


import views from '/components/views';
import CrudForm from '/../../kiska/src/components/MutationContext/CrudForm';
import SaveButton from '/../../kiska/src/components/MutationContext/SaveButton';
import DeleteButton from '/../../kiska/src/components/MutationContext/DeleteButton';
import OneNode from '/../../kiska/src/components/OneNode';

const styles = theme => ({
  root: {
    width: '100%',
  },
  fab: {
    bottom: 0,
    position: 'sticky',
    left: 'calc(100% - 50px)',
  },
});

class AdminViewPage extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    onTitleChange: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    renderTitle: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    viewProps: PropTypes.object,
    editUrl: PropTypes.func.isRequired,
  }

  static defaultProps = {
    viewProps: null,
  }

  renderNode = (node) => {
    const { classes, onTitleChange, type, viewProps, renderTitle, editUrl } = this.props;

    const title = renderTitle(node);
    if (title !== this.title) {
      setTimeout(() => onTitleChange(title), 1);
    }
    this.title = title;

    const View = views[`${_.upperFirst(type)}View`];

    return (
      <div className={classes.root}>
        <Helmet>
          <title>{title}</title>
        </Helmet>

        <View node={node} {...viewProps} />
        <Tooltip title="Edit">
          <Fab className={classes.fab} color="primary" component={Link} to={editUrl(node)}>
            <EditIcon />
          </Fab>
        </Tooltip>
      </div>
    );
  }

  render() {
    const { type, match: { params } } = this.props;

    return (
      <OneNode type={type} id={params.id} renderNode={this.renderNode} />
    );
  }
}

AdminViewPage = compose(
  withStyles(styles),
  withRouter,
)(AdminViewPage);

export default AdminViewPage;
