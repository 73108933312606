import React, { Component, createContext } from 'react';
import { compose, withApollo, Query } from 'react-apollo';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import gql from 'graphql-tag';
import to from 'await-to-js';
import _ from 'lodash';
import qs from 'query-string';

import withRouterWorkaround from '../withRouterWorkaround';
import { withSettings } from './SettingsContext';
import { SchemaContext } from './SchemaContext'; // eslint-disable-line import/no-extraneous-dependencies
import { addIdsToSelections, fetchJson } from '../../utils'; // eslint-disable-line import/no-extraneous-dependencies
import { normalizeUser } from '../../normalizeUser'; // eslint-disable-line import/no-extraneous-dependencies

const CurrentUserContext = createContext({});

const isSsr = typeof window === 'undefined';

class CurrentUserProvider extends Component {
  static contextType = SchemaContext;

  static propTypes = {
    children: PropTypes.node.isRequired,
    client: PropTypes.object.isRequired,
    settings: PropTypes.object.isRequired,
    userStub: PropTypes.object,
    location: PropTypes.object.isRequired,
  }

  static defaultProps = {
    userStub: null,
  }

  static buildQuery = (props, context) => {
    const schema = context;
    const selections = addIdsToSelections(schema.types.user.selections.currentUser);

    return gql`
      query GetCurrentUser($id: String!){
        user: user(where: {id: {_eq: $id}}){
          ${selections}
        }
      }
    `;
  }

  static haveAuthCookie = ({ authCookieName }) => {
    const cookie = Cookies.get(authCookieName);
    if (!cookie) return false;
    return cookie.length > 10;
  }

  constructor(props, context) {
    super(props, context);
    const { settings, userStub } = props;
    const firebaseConfig = settings.firebaseConfig;

    if (!isSsr) {
      if (!window.firebase.apps.length) {
        window.firebase.initializeApp(firebaseConfig);
        window.firebaseAuth = window.firebase.auth();
      }
    }

    this.query = CurrentUserProvider.buildQuery(props, context);

    this.state = {
      userStub,
      haveAuthCookie: CurrentUserProvider.haveAuthCookie(settings),
    };
  }

  componentDidMount = () => {
    window.firebaseAuth.onAuthStateChanged(this.handleAuthStateChanged);
  }

  authenticate = async (firebaseUserToken) => {
    const { userStub: prevUserStub, haveAuthCookie: prevHaveAuthCookie } = this.state;
    const { settings, client, location } = this.props;
    const url = `${settings.kiskaApiEndpoint}/auth/authenticate`;
    const query = qs.parse(location.search);

    const [authError, result] = await to(fetchJson({
      url,
      body: {
        firebaseUserToken,
        invitation: query.invite,
      },
    }));
    if (authError) {
      this.signOut();
      return;
    }

    const haveAuthCookie = CurrentUserProvider.haveAuthCookie(settings);

    if (!haveAuthCookie) {
      console.error('No auth cookie received from authenticate endpoint.');
      return;
    }

    if ((prevUserStub.id !== result.userStub.id) || !prevHaveAuthCookie) {
      this.setState({ userStub: result.userStub, haveAuthCookie: true });
      client.reFetchObservableQueries();
    }
  }

  handleSignedIn = (user) => {
    user.getIdToken(true)
      .then((token) => {
        this.authenticate(token);
      })
      .catch((error) => {
        console.error('Error getting user token:', error);
      });
  }

  handleAuthStateChanged = (user) => {
    const { userStub } = this.state;

    if (user) {
      this.handleSignedIn(user);
    } else if (userStub && userStub.id) {
      this.signOut();
    }
  }

  getContextValue = (data) => {
    let user = _.get(data, 'user[0]', null);
    user = normalizeUser(user);

    return {
      user,
      signedIn: !user.isAnon,
      signOut: this.signOut,
      registerOpenSignInDialog: this.registerOpenSignInDialog,
      openSignInDialog: this.openSignInDialog,
    };
  }

  signOut = () => {
    const { settings, client } = this.props;

    window.firebaseAuth.signOut();
    Cookies.remove(settings.authCookieName);
    this.setState({ userStub: null });
    setTimeout(() => {
      client.cache.reset();
      client.resetStore();
    }, 300);
    console.log('User has been logged out and cache has been cleared.');
  }

  render() {
    const { userStub, haveAuthCookie } = this.state;
    const id = userStub ? userStub.id : '';
    const { children } = this.props;

    return (
      <Query query={this.query} variables={{ id }} skip={!id || (!isSsr && !haveAuthCookie)}>
        {({ data, error }) => {
          const contextValue = this.getContextValue(data);
          if (error) {
            console.error(`Error querying current user with id "${id}": `, error);
          }

          return (
            <CurrentUserContext.Provider value={contextValue}>
              {children}
            </CurrentUserContext.Provider>
          );
        }}
      </Query>
    );
  }
}

const withCurrentUser = WrappedComponent => props => (
  <CurrentUserContext.Consumer>
    {value => <WrappedComponent currentUser={value} {...props} />}
  </CurrentUserContext.Consumer>
);

CurrentUserProvider = compose(
  withApollo,
  withSettings,
  withRouterWorkaround,
)(CurrentUserProvider);
CurrentUserProvider.displayName = 'CurrentUserProvider';
export { CurrentUserProvider, CurrentUserContext, withCurrentUser };
