import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import IconButton from '@material-ui/core/IconButton';
import ExpandIcon from 'mdi-material-ui/ChevronDown';
import ChartIcon from 'mdi-material-ui/ChartLine';
import CollapseIcon from 'mdi-material-ui/ChevronUp';
import Tooltip from '@material-ui/core/Tooltip';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Fade from '@material-ui/core/Fade';
import Collapse from '@material-ui/core/Collapse';

import { AppSettingsContext } from '/../../kiska/src/components/contexts/AppSettingsContext';
import StaircaseChart from './StaircaseChart';

const styles = theme => ({
  root: {
    backgroundColor: 'rgba(255,255,255,1)',
    borderBottomLeftRadius: 5,
    position: 'fixed',
    top: 0,
    right: 0,
    width: 920,
    zIndex: 100,
    opacity: 0.8,
    boxShadow: '-1px 1px 8px 2px rgba(0,0,0,.8)',
    overflow: 'hidden',
  },
  tabsWrapper: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,.8)',
  },
  tabs: {
    backgroundColor: 'rgba(0,0,0,.8)',
    flex: 1,
  },
  tabsContainer: {
    height: 520,
  },
  tabContainer: {
    position: 'absolute',
    top: 50,
    bottom: 0,
    width: '100%',
    padding: theme.spacing.unit * 0,
  },
});

const TabContainer = compose(
  withStyles(styles),
)(({ children, classes, active }) => {
  return (
    <Fade in={active} unmountOnExit>
      <div className={classes.tabContainer}>
        {children}
      </div>
    </Fade>
  );
});

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

class SessionCharts extends Component {
  static contextType = AppSettingsContext;

  static propTypes = {
    classes: PropTypes.object.isRequired,
    session: PropTypes.object.isRequired,
    className: PropTypes.string,
    theme: PropTypes.object.isRequired,
    tab: PropTypes.number.isRequired,
    onTabChange: PropTypes.func.isRequired,
  }

  static defaultProps = {
    className: '',
  }

  state = {
    open: true,
  };

  handleChange = (event, value) => {
    this.props.onTabChange(value);
  };

  render() {
    const { open } = this.state;
    const { classes, session, tab } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.tabsWrapper}>
          <Tabs
            value={tab >= 0 ? tab : 0}
            onChange={this.handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            className={classes.tabs}
          >
            {session.staircaseSet.staircases.map(({ staircase }, index) => (
              <Tab key={staircase.id} label={staircase.label} />
            ))}
          </Tabs>
          <Tooltip title={open ? 'Hide charts' : 'Show charts'}>
            <IconButton onClick={() => this.setState(state => ({ open: !state.open }))}>{open ? <CollapseIcon /> : <ExpandIcon />}</IconButton>
          </Tooltip>
        </div>
        <Collapse in={open && tab >= 0} timeout={500}>
          <div className={classes.tabsContainer}>
            {session.staircaseSet.staircases.map(({ staircase }, index) => (
              <TabContainer key={staircase.id} active={tab === index}>
                <StaircaseChart
                  height={520}
                  width="100%"
                  responses={session.responses.filter(r => r.staircaseId === staircase.id)}
                  state={session.state.staircases[staircase.id]}
                />
              </TabContainer>
            ))}
          </div>
        </Collapse>
      </div>
    );
  }
}

SessionCharts = compose(
  withStyles(styles, { withTheme: true }),
)(SessionCharts);
SessionCharts.displayName = 'SessionCharts';
export default SessionCharts;
