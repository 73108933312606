/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import { compose } from 'react-apollo';
import PropTypes from 'prop-types';
import { withSnackbar } from 'notistack';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import CopyIcon from 'mdi-material-ui/ContentCopy';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  content: {
    textTransform: 'none',
    marginRight: theme.spacing.unit * 2,
    userSelect: 'all',
  },
});

class CopyToClipboardButton extends React.Component {
  static propTypes = {
    enqueueSnackbar: PropTypes.func.isRequired,
    message: PropTypes.string,
    text: PropTypes.string,
    title: PropTypes.string,
    errorPosition: PropTypes.object,
    successPosition: PropTypes.object,
    children: PropTypes.node,
    onPresentSnackbar: PropTypes.func.isRequired,
    closeSnackbar: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
  }

  static defaultProps = {
    message: 'Copied to clipboard!',
    title: 'Copy to clipboard',
    text: '',
    errorPosition: { horizontal: 'right', vertical: 'bottom' },
    successPosition: { horizontal: 'right', vertical: 'bottom' },
    children: null,
  }

  showError = () => {
    const { errorPosition, enqueueSnackbar, children } = this.props;
    const options = { variant: 'error' };
    options.anchorOrigin = errorPosition;
    enqueueSnackbar((
      <>
        Your browser doesn't support automatic copying :-(
        {children ? <><br />The text has been hilighted for you to copy manually</> : null}.
      </>
    ), options);
  }

  handleClick = () => {
    const { message, text, successPosition, enqueueSnackbar } = this.props;

    if (!text) return;

    if (!window.navigator.clipboard) {
      this.showError();
      return;
    }

    window.navigator.clipboard.writeText(text)
      .then(() => {
        const options = { variant: 'success' };
        options.anchorOrigin = successPosition;
        enqueueSnackbar(message, options);
      })
      .catch(() => {
        this.showError();
      });
  }

  render() {
    const { title, classes, children, onPresentSnackbar, closeSnackbar, enqueueSnackbar, errorPosition, successPosition, message, ...rest } = this.props;
    const content = children ? <span className={classes.content}>{children}</span> : null;
    const Component = children ? Button : IconButton;

    return (
      <Tooltip title={title}>
        <Component onClick={this.handleClick} {...rest}>{content}<CopyIcon /></Component>
      </Tooltip>
    );
  }
}

CopyToClipboardButton = compose(
  withStyles(styles),
  withSnackbar,
)(CopyToClipboardButton);
CopyToClipboardButton.displayName = 'CopyToClipboardButton';
export default CopyToClipboardButton;
