export default {
  types: {
    role: {
      renderers: {
        inline: node => node.label,
      },

      selections: {
        default: 'name label',
      },

      fields: {
        name: {
          type: 'String',
        },
        label: {
          type: 'String',
        },
      },
    },

    user: {

      renderers: {
        inline: node => node.displayName,
      },

      selections: {
        default: 'id displayName email photoUrl participantId firebaseUid lastSignInAt updatedAt createdAt role{id name label}  setAssignment{id data userId} sessions(order_by: {updatedAt: desc}){id status staircaseSet{id label} updatedAt createdAt state}',
        currentUser: 'id displayName email photoUrl participantId firebaseUid lastSignInAt updatedAt createdAt role{id name label} setAssignment{id data userId} sessions(order_by: {updatedAt: desc}){id status staircaseSet{id label} updatedAt createdAt state}',
      },

      fields: {
        displayName: {
          type: 'String',
          label: 'Name',
          validation: {
            required: true,
          },
        },

        email: {
          type: 'String',
          validation: {
            required: true,
            email: true,
          },
        },

        staircaseSets: {
          type: 'staircase_set',
          hasMany: true,
        },

        photoUrl: {
          type: 'String',
        },

        participantId: {
          type: 'String',
        },

        firebaseUid: {
          type: 'String',
        },

        lastSignInAt: {
          type: 'Timestamp',
        },

        role: {
          type: 'role',
        },

      },
    },
  },
};
