import React from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'react-apollo';
import CloseIcon from 'mdi-material-ui/Close';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import { CircularProgress, Typography } from '@material-ui/core';
import Cookies from 'js-cookie';
import Fade from '@material-ui/core/Collapse';
import { Link } from 'react-router-dom';

import { withSettings } from '../contexts/SettingsContext';
import { withReqRes } from '../contexts/ReqResContext';
import { CurrentUserContext } from '../contexts/CurrentUserContext';
// import './firebaseui-4_0_0.css';

const isSsr = typeof window === 'undefined';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F8F8F8',
    overflow: 'hidden',
  },
  progress: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: -180,
  },
  uiWidget: {
    backgroundColor: '#F8F8F8',
  },
});

// Global ID for the element.
const ELEMENT_ID = 'firebaseui_container';

// Promise that resolves unless the FirebaseUI instance is currently being deleted.
let firebaseUiDeletion = Promise.resolve();

// Just before redirecting to Acount Chooser or some such we want
// to save the value of pending redirect into a cookie
// so SSR will see it
const isPendingRedirect = () => window.sessionStorage.getItem('firebaseui::pendingRedirect') === '"pending"';
if (!isSsr) {
  window.addEventListener('beforeunload', () => {
    Cookies.set('firebaseuiPendingRedirect', isPendingRedirect());
  });
}

class SignInWidget extends React.Component {
  static contextType = CurrentUserContext;

  static propTypes = {
    classes: PropTypes.object.isRequired,
    signInFlow: PropTypes.string,
    signInSuccessUrl: PropTypes.string,
    signInOptions: PropTypes.array,
    tosUrl: PropTypes.string,
    privacyPolicyUrl: PropTypes.string,
    req: PropTypes.object,
    settings: PropTypes.object,
  }

  static defaultProps = {
    req: null,
    settings: null,
    signInFlow: 'redirect',
    signInSuccessUrl: undefined,
    signInOptions: [],
    tosUrl: '/',
    privacyPolicyUrl: '/',
  }

  constructor(props, context) {
    super(props, context);

    let redirectPending = false;
    if (isSsr) {
      const { req: { headers }, settings: { ssrOnlyCode } } = props;
      const receivedCookies = ssrOnlyCode.cookie.parse(headers.cookie || headers.Cookie || '');
      if (receivedCookies.firebaseuiPendingRedirect === 'true') {
        redirectPending = true;
      }
    } else {
      redirectPending = isPendingRedirect();
    }

    this.state = {
      redirectPending,
    };

    this.unregisterAuthObserver = () => { };
  }

  componentDidMount = () => {
    const { signInFlow } = this.props;
    const firebaseui = window.firebaseui;
    const firebaseAuth = window.firebaseAuth;

    // Wait in case the firebase UI instance is being deleted.
    // This can happen if you unmount/remount the element quickly.
    return firebaseUiDeletion.then(() => {
      // Get or Create a firebaseUI instance.
      this.firebaseUiWidget = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(firebaseAuth);
      // this.firebaseUiWidget = new firebaseui.auth.AuthUI(firebaseAuth);
      if (signInFlow === 'popup') {
        this.firebaseUiWidget.reset();
      }

      // We track the auth state to reset firebaseUi if the user signs out.
      this.userSignedIn = false;
      this.unregisterAuthObserver = firebaseAuth.onAuthStateChanged((user) => {
        if (!user && this.userSignedIn) {
          this.firebaseUiWidget.reset();
        }
        this.userSignedIn = !!user;
      });

      this.startUiWidget();
    });
  }

  componentWillUnmount() {
    firebaseUiDeletion = firebaseUiDeletion.then(() => {
      this.unregisterAuthObserver();
      return this.firebaseUiWidget.delete();
    });
    return firebaseUiDeletion;
  }

  startUiWidget = () => {
    const { signInFlow, signInSuccessUrl: propsSignInSuccessUrl, signInOptions, tosUrl, privacyPolicyUrl } = this.props;
    const firebase = window.firebase;

    const signInSuccessUrl = propsSignInSuccessUrl || window.location.href;

    const firebaseUiConfig = {
      signInFlow,
      signInSuccessUrl,
      signInOptions: signInOptions.map(option => firebase.auth[`${option}AuthProvider`].PROVIDER_ID),
      tosUrl,
      privacyPolicyUrl,
    };

    this.firebaseUiWidget.start(`#${ELEMENT_ID}`, firebaseUiConfig);
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.progress}>
          <CircularProgress size={100} thickness={2} />
          <br /><br />
          <Typography variant="body1">Securely signing you in...</Typography>
        </div>
        <div id={ELEMENT_ID} className={classes.uiWidget} />
      </div>
    );
  }
}

SignInWidget = compose(
  withStyles(styles),
  withReqRes,
  withSettings,
)(SignInWidget);
SignInWidget.displayName = 'SignInWidget';
export default SignInWidget;
