import React from 'react';
import PropTypes from 'prop-types';
import { ApolloProvider } from 'react-apollo';
import { SnackbarProvider } from 'notistack';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles';

import { ReqResProvider } from './contexts/ReqResContext';
import { AppSettingsProvider } from './contexts/AppSettingsContext';
import { SchemaProvider } from './contexts/SchemaContext';
import { SettingsProvider } from './contexts/SettingsContext';
import { ThemeManagerProvider } from './contexts/ThemeManagerContext';
import { CurrentUserProvider } from './contexts/CurrentUserContext';
// import { PageMetaProvider } from './contexts/PageMetaContext';


const AppWrappers = ({ options, req, res, apolloClient, children, secretSettings, userStub }) => {
  const sheetsManager = new Map();

  return (
    <SettingsProvider settings={options} secretSettings={secretSettings}>
      <ReqResProvider req={req} res={res}>
        <ApolloProvider client={apolloClient}>
          <AppSettingsProvider>
            <SchemaProvider schema={options.schema}>
              <SnackbarProvider maxSnack={5}>
                <ThemeManagerProvider themes={options.theme.themes} defaultTheme={options.theme.default}>
                  <MuiThemeProvider theme={options.theme.themes[options.theme.default]} sheetsManager={sheetsManager}>
                    <CurrentUserProvider userStub={userStub}>
                      {/* <PageMetaProvider> */}
                      {children}
                      <CssBaseline />
                      {/* </PageMetaProvider> */}
                    </CurrentUserProvider>
                  </MuiThemeProvider>
                </ThemeManagerProvider>
              </SnackbarProvider>
            </SchemaProvider>
          </AppSettingsProvider>
        </ApolloProvider>
      </ReqResProvider>
    </SettingsProvider>
  );
};

AppWrappers.propTypes = {
  options: PropTypes.object.isRequired,
  req: PropTypes.object,
  apolloClient: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  secretSettings: PropTypes.object,
  res: PropTypes.object,
  ssrData: PropTypes.object,
  userStub: PropTypes.object,
};
AppWrappers.defaultProps = {
  req: null,
  res: null,
  secretSettings: null,
  ssrData: null,
  userStub: null,
};

export default AppWrappers;
