import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { compose } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import ReloadIcon from 'mdi-material-ui/Reload';
import classNames from 'classnames';
import _ from 'lodash';
import NoSsr from '@material-ui/core/NoSsr';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import Paper from '@material-ui/core/Paper';
import { AppSettingsContext } from '/../../kiska/src/components/contexts/AppSettingsContext';
import Scrollbar from '/../../kiska/src/components/Scrollbar';

const styles = theme => ({
  root: {
    paddingTop: 0,
  },
  tooltip: {
    padding: 16,
    backgroundColor: 'rgba(255,255,255,.7)',
    lineHeight: 1.5,
    zIndex: 100000,
    color: '#444',
  },
});

class StaircaseChart extends Component {
  static contextType = AppSettingsContext;

  static propTypes = {
    classes: PropTypes.object.isRequired,
    state: PropTypes.object.isRequired,
    className: PropTypes.string,
    responses: PropTypes.array.isRequired,
    width: PropTypes.any,
    height: PropTypes.any,
    liveMode: PropTypes.bool,
  }

  static defaultProps = {
    className: '',
    width: 600,
    height: 300,
    liveMode: true,
  }

  constructor(props) {
    super(props);

    this.state = {
    };
  }

  renderDot = ({ cx, cy, stroke, payload, value, dataKey, key, ...rest }) => {
    let dia = 8;
    let fill;
    stroke = '#000';
    const strokeWidth = 2;

    if (payload.result === 'correct') fill = '#0C0';
    if (payload.result === 'incorrect') fill = '#C00';
    if (payload.result === 'timeout') fill = '#00C';
    if (payload.result === 'pending') {
      fill = '#999';
      dia = 16;
    }
    if (payload.countdownThresholdReached) {
      stroke = '#000';
      dia = 16;
    }

    return (
      <svg key={key} x={cx - dia / 2} y={cy - dia / 2} width={dia} height={dia} fill={fill} viewBox="0 0 100 100">
        <circle cx="50" cy="50" r="50" strokeWidth={strokeWidth} stroke={stroke} />
      </svg>
    );
  }

  renderTooltip = ({ payload }) => {
    const { classes } = this.props;

    if (!payload || !payload.length) {
      return null;
    }

    const point = payload.find(p => p.dataKey === 'responses');
    if (!point) {
      return null;
    }

    return (
      <Paper className={classes.tooltip}>
        <b>Trial Number:</b> {point.payload.responseNumber}<br />
        <b>Level:</b> {point.value} <br />
        <b>Response:</b> {point.payload.result} <br />
        <b>Response Time:</b> {point.payload.responseTime}ms <br />
        <b>Reversals:</b> {point.payload.reversals} <br />
        <b>Avg. Inflection:</b> {point.payload.inflectionAvg.toFixed(1)}<br />
        <b>Avg. + Inflection:</b> {point.payload.positiveInflectionAvg.toFixed(1)} <br />
        <b>Avg. - Inflection:</b> {point.payload.negativeInflectionAvg.toFixed(1)} <br />
      </Paper>
    );
  }

  render() {
    const { classes, responses, className, state, height, width, liveMode } = this.props;

    const data = responses.map((r, index) => {
      return {
        responseNumber: index,
        responses: r.data.level + 1,
        result: r.data.result,
        responseTime: r.data.responseTime,
        countdownThresholdReached: r.data.countdownThresholdReached,
        positiveInflectionAvg: state.positiveInflectionAvg + 1,
        negativeInflectionAvg: state.negativeInflectionAvg + 1,
        inflectionAvg: state.inflectionAvg + 1,
        reversals: r.data.reversals,
      };
    });

    if (liveMode) {
      data.push({
        responseNumber: responses.length,
        responses: state.level + 1,
        result: 'pending',
        positiveInflectionAvg: state.positiveInflectionAvg + 1,
        negativeInflectionAvg: state.negativeInflectionAvg + 1,
        inflectionAvg: state.inflectionAvg + 1,
      });
    }

    const stroke = '#CCC';

    return (
      <div className={classNames(classes.root, className)}>
        <NoSsr>
          <ResponsiveContainer width={width} height={height}>
            <LineChart
              data={data}
              margin={{ top: 8, right: 0, left: -20, bottom: 0 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="responseNumber" />
              <YAxis domain={[0, 19]} ticks={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19]} />
              <Tooltip content={this.renderTooltip} />
              <Line isAnimationActive={false} type="monotone" dataKey="responses" stroke={stroke} strokeWidth={2} dot={this.renderDot} />
              <Line type="monotone" dataKey="positiveInflectionAvg" stroke="rgba(0,200,0,.7)" strokeWidth={2} dot={false} />
              <Line type="monotone" dataKey="negativeInflectionAvg" stroke="rgba(200,0,0,.7)" strokeWidth={2} dot={false} />
              <Line type="monotone" dataKey="inflectionAvg" stroke="rgba(0,0,200,.7)" strokeDashArray={[5, 5]} strokeWidth={2} dot={false} />
            </LineChart>
          </ResponsiveContainer>
        </NoSsr>
      </div>
    );
  }
}

StaircaseChart = compose(
  withStyles(styles),
)(StaircaseChart);
StaircaseChart.displayName = 'StaircaseChart';
export default StaircaseChart;
