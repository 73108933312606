import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet';

import Table from '/../../kiska/src/components/NodeList/Table'; // eslint-disable-line import/no-extraneous-dependencies
import NodeList from '/../../kiska/src/components/NodeList'; // eslint-disable-line import/no-extraneous-dependencies

const styles = theme => ({
  root: {
  },
});

class SessionTablePage extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    onTitleChange: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    props.onTitleChange('Sessions');
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Helmet>
          <title>Sessions</title>
        </Helmet>

        <NodeList type="training_session" selectionSet="table" initialOrderBy={[{updatedAt: 'desc'}]}>
          <Table
            columns={[
              'id',
              'status',
              'user',
              'staircaseSet',
              'updatedAt',
              'createdAt',
              // {
              //   fieldName: 'avg-inflection',
              //   label: 'Avg. Inflection',
              //   render: node => 'Test here',
              // },
            ]}
            getRowClickUrl={node => `/admin/sessions/${node.id}`}
          />
        </NodeList>
      </div>
    );
  }
}

SessionTablePage = compose(
  withStyles(styles),
)(SessionTablePage);
SessionTablePage.displayName = 'SessionTablePage';
export default SessionTablePage;
