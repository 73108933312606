import React from 'react';
import PropTypes from 'prop-types';
import { AnimatedSwitch, spring } from 'react-router-transition';

function mapStyles(s) {
  return {
    opacity: s.opacity,
    // transform: `translateX(${styles.translateX}vw)`,
    transform: `scale(${s.scale})`,
  };
}

function bounce(val) {
  return spring(val, {
    stiffness: 200,
    damping: 25,
  });
}

const bounceTransition = {
  atEnter: {
    opacity: 0,
    scale: 0,
    translateX: 50,
    // translateX: 100,
  },
  atLeave: {
    opacity: bounce(0),
    scale: bounce(0.2),
    translateX: bounce(50),
    // translateX: bounce(-100),
  },
  atActive: {
    opacity: bounce(1),
    scale: bounce(1),
    translateX: bounce(0),
  },
};

const AdminRouterSwitch = ({ children }) => (
  <AnimatedSwitch
    atEnter={bounceTransition.atEnter}
    atLeave={bounceTransition.atLeave}
    atActive={bounceTransition.atActive}
    mapStyles={mapStyles}
    className="admin-route-wrapper"
  >
    {children}
  </AnimatedSwitch>
);

AdminRouterSwitch.propTypes = {
  children: PropTypes.node.isRequired,
};
AdminRouterSwitch.defaultProps = {
};

export default AdminRouterSwitch;
