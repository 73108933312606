import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import formatDate from 'date-fns/format';
import addMilliseconds from 'date-fns/addMilliseconds';
import Divider from '@material-ui/core/Divider';
import DownloadIcon from 'mdi-material-ui/Download';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import downloadSessionResults from '../../components/downloadSessionResults';
import CrudForm from '/../../kiska/src/components/MutationContext/CrudForm';
import DeleteButton from '/../../kiska/src/components/MutationContext/DeleteButton';
import OneNode from '/../../kiska/src/components/OneNode'; // eslint-disable-line import/no-extraneous-dependencies
import StaircaseChart from '../../components/StaircaseChart';

const styles = theme => ({
  root: {
  },
  staircase: {
    backgroundColor: 'rgba(255,255,255,.0)',
    borderRadius: 5,
    padding: theme.spacing.unit * 0,
    marginTop: 8,
    marginBottom: 8,
  },
  stats: {
    '& > div': {
    },
  },
  stat: {
    display: 'flex',
    alignItems: 'center',
  },
  statLabel: {
    marginRight: theme.spacing.unit * 1,
  },
  sectionHeading: {
    '& > div': {
      margin: 0,
    },
  },
  actions: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    '& > *': {
      width: 180,
      margin: '8px 0',
      '& > span': {
        display: 'flex',
        justifyContent: 'space-between',
        '& > span': {
          flex: 1,
        },
      },
    },
  },
  tableRow: {
    height: 24,
    '& > td, & > th': {
      padding: `0 8px`,
    },
  },
});

const Section = compose(
  withStyles(styles),
)(({ classes, title, children }) => {
  return (
    <ExpansionPanel>
      <ExpansionPanelSummary className={classes.sectionHeading} expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6" color="textSecondary">{title}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        {children}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
});

const Stat = compose(
  withStyles(styles),
)(({ classes, label, value }) => {
  return (
    <Grid item xs={12} sm={12} md={12} lg={12} className={classes.stat}>
      <Typography variant="body2" color="textPrimary" className={classes.statLabel}>{label}:</Typography>
      <Typography variant="body2" color="primary" className={classes.statValue}>{value}</Typography>
    </Grid>
  );
});

const Stats = compose(
  withStyles(styles),
)(({ classes, children, title }) => {
  return (
    <div className={classes.stats}>
      {title ? <Typography variant="h6" color="textSecondary">{title}</Typography> : null}
      <Grid container spacing={0}>
        {children}
      </Grid>
    </div>
  );
});

const getResponsesAggregates = (session, staircaseId) => {
  let avgResponseTime = 0;
  let responseCount = 0;

  let correctCount = 0;
  let incorrectCount = 0;
  let timeoutCount = 0;

  let avgCorrectResponseTime = 0;
  let avgIncorrectResponseTime = 0;
  let avgTimeoutResponseTime = 0;

  session.responses.forEach((r) => {
    if (staircaseId && r.staircaseId !== staircaseId) return;
    const { data } = r;
    responseCount += 1;
    avgResponseTime += data.responseTime;
    if (data.result === 'correct') {
      correctCount += 1;
      avgCorrectResponseTime += data.responseTime;
    } else if (data.result === 'incorrect') {
      incorrectCount += 1;
      avgIncorrectResponseTime += data.responseTime;
    } else if (data.result === 'timeout') {
      timeoutCount += 1;
      avgTimeoutResponseTime += data.responseTime;
    }
  });

  if (responseCount) avgResponseTime /= responseCount;
  if (correctCount) avgCorrectResponseTime /= correctCount;
  if (incorrectCount) avgIncorrectResponseTime /= incorrectCount;
  if (timeoutCount) avgTimeoutResponseTime /= timeoutCount;

  return { avgResponseTime, responseCount, correctCount, incorrectCount, timeoutCount, avgCorrectResponseTime, avgIncorrectResponseTime, avgTimeoutResponseTime };
};

const Staircase = compose(
  withStyles(styles),
)(({ classes, staircase, state, session }) => {
  const ags = getResponsesAggregates(session, staircase.id);

  let content;
  if (!state) {
    content = (
      <Typography>No result for this staircase. Did you change the settings after participants started training?</Typography>
    );
  } else {
    content = (
      <>
        <Section title="Stats">
          <Stats>
            <Stat label="Average Reversal Level" value={(state.avgReversalLevel + 1).toFixed(2)} />
            <Stat label="Average Reversal Level (unweighted)" value={(state.unweightedAvgReversalLevel + 1).toFixed(2)} />
            <Stat label="Max Reversals" value={`${state.maxReversals} at Level ${state.maxReversalLevel + 1}`} />
            <Stat label="Total Reversals" value={state.totalReversals} />
            <Stat label="Number of Level with Reversals" value={state.totalReversalLevels} />
            <Stat label="Average Inflection Level" value={(state.inflectionAvg + 1).toFixed(2)} />
            <Stat label="Average Positive Inflection Level" value={(state.positiveInflectionAvg + 1).toFixed(2)} />
            <Stat label="Average Negative Inflection Level" value={(state.negativeInflectionAvg + 1).toFixed(2)} />
            <Stat label="Inflections Count" value={state.inflections} />
            <Stat label="Positive Inflection Count" value={state.positiveInflections} />
            <Stat label="Negative Inflection Count" value={state.negativeInflections} />
            <Stat label="Response Count" value={ags.responsesCount} />
            <Stat label="Correct Response Count" value={ags.correctCount} />
            <Stat label="Incorrect Response Count" value={ags.incorrectCount} />
            <Stat label="Timeout Count" value={ags.timeoutCount} />
            <Stat label="Avgerage Response Time" value={`${ags.avgResponseTime.toFixed(1)}ms`} />
            <Stat label="Average Correct Response Time" value={`${ags.avgCorrectResponseTime.toFixed(1)}ms`} />
            <Stat label="Average Incorrect Response Time" value={`${ags.avgIncorrectResponseTime.toFixed(1)}ms`} />
            <Stat label="Average Timeout Response Time" value={`${ags.avgTimeoutResponseTime.toFixed(1)}ms`} />
          </Stats>
        </Section>
        <Section title="Responses">
          <Table className={classes.table}>
            <TableHead>
              <TableRow className={classes.tableRow}>
                <TableCell align="left">Timestamp</TableCell>
                <TableCell align="right">Level</TableCell>
                <TableCell align="right">Response Time (ms)</TableCell>
                <TableCell align="right">Result</TableCell>
                <TableCell align="right">Option Size</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {session.responses.map(({ updatedAt, staircaseId, data, id }) => {
                if (staircaseId !== staircase.id) return null;
                return (
                  <TableRow key={id} className={classes.tableRow} hover>
                    <TableCell align="left">{formatDate(new Date(updatedAt), `MMM dd, yyyy h:mm:ss.SS aaaaa'm'`)}</TableCell>
                    <TableCell align="right">{data.level + 1}</TableCell>
                    <TableCell align="right">{data.responseTime}</TableCell>
                    <TableCell align="right">{data.result}</TableCell>
                    <TableCell align="right">{data.faceSet ? `${data.faceSet.optionSize.toFixed(1)}%` : 'none'}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Section>
        <br />
        <StaircaseChart
          state={state}
          responses={session.responses.filter(r => r.staircaseId === staircase.id)}
          liveMode={false}
          height={400}
          width="100%"
        />
      </>
    );
  }

  return (
    <div className={classes.staircase}>
      <Typography variant="h6" gutterBottom color="textSecondary">Staircase Results: {staircase.label}</Typography>
      {content}
    </div>
  );
});

const getSessionDuration = session => (((new Date(session.updatedAt)).getTime() - (new Date(session.createdAt)).getTime()));

class SessionViewPage extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    onTitleChange: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props);

    props.onTitleChange('Session Results');
  }

  downloadCsv = (session) => {
    downloadSessionResults(session);
  }

  renderSession = (session) => {
    const { onTitleChange, classes } = this.props;

    onTitleChange(`Session Results - ${session.staircaseSet.label} - ${session.user.displayName}`);
    const duration = getSessionDuration(session);
    const niceDuration = (duration / 59.961).toFixed(1);

    return (
      <>
        <Grid container className={classes.top}>
          <Grid item xs={12} sm={12} md={6}>
            <Stats title="Session Stats">
              <Stat label="Staircase Set" value={session.staircaseSet.label} />
              <Stat label="User" value={session.user.displayName} />
              <Stat label="System ID" value={session.user.id} />
              <Stat label="Face Training Participant ID" value={session.user.participantId || '<none>'} />
              <Stat label="Started" value={formatDate(new Date(session.createdAt), `MMM dd, yyyy 'at' h:mmaaaaa'm'`)} />
              <Stat label="Last Response" value={formatDate(new Date(session.updatedAt), `MMM dd, yyyy 'at' h:mmaaaaa'm'`)} />
              <Stat label="Total Duration" value={`${duration.toLocaleString()}ms (${niceDuration} minutes)`} />
            </Stats>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div className={classes.actions}>
              <Button color="secondary" onClick={() => this.downloadCsv(session)} variant="contained">
                <DownloadIcon /><span>Download CSV</span>
              </Button>
              <CrudForm type="training_session" node={session}>
                <DeleteButton />
              </CrudForm>
            </div>
          </Grid>
        </Grid>
        <br />
        {session.staircaseSet.staircases.map(({ staircase }) => {
          const state = session.state.staircases[staircase.id];
          return (
            <React.Fragment key={staircase.id}>
              <Divider />
              <Staircase session={session} staircase={staircase} state={state} />
            </React.Fragment>
          );
        })}
      </>
    );
  }

  render() {
    const { classes, match: { params } } = this.props;

    return (
      <div className={classes.root}>
        <Helmet>
          <title>Session</title>
        </Helmet>

        <OneNode type="training_session" id={params.id} selectionSet="default" renderNode={this.renderSession} />
      </div>
    );
  }
}

SessionViewPage = compose(
  withStyles(styles),
  withRouter,
)(SessionViewPage);
SessionViewPage.displayName = 'SessionViewPage';
export default SessionViewPage;
