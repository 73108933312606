import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { compose } from 'react-apollo';

import { withCurrentUser } from '/../../kiska/src/components/contexts/CurrentUserContext'; // eslint-disable-line import/no-extraneous-dependencies
import { MutationContext } from '/../../kiska/src/components/MutationContext/MutationContext'; // eslint-disable-line import/no-extraneous-dependencies
import TextField from '/../../kiska/src/components/MutationContext/FormFields/TextField'; // eslint-disable-line import/no-extraneous-dependencies
import NodeField from '/../../kiska/src/components/MutationContext/FormFields/NodeField'; // eslint-disable-line import/no-extraneous-dependencies
import { Typography } from '@material-ui/core';

class UserEditor extends React.Component {
  static contextType = MutationContext;

  static propTypes = {
    variant: PropTypes.string,
    currentUser: PropTypes.object.isRequired,
  }

  static defaultProps = {
    variant: '',
  };

  render() {
    const { node } = this.context;
    const { variant, currentUser: { user } } = this.props;

    if (variant === 'staircase-set-assignment') {
      return (
        <Grid container spacing={24}>

          <Grid item xs={12} sm={12}>
            <NodeField name="staircaseSets" />
          </Grid>

        </Grid>
      );
    }

    return (
      <Grid container spacing={24}>

        <Grid item xs={12} sm={6}>
          <TextField name="displayName" />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField name="email" />
        </Grid>

        {user.isAdmin ? (
          <>
            <Grid item xs={12} sm={12}>
              <NodeField name="staircaseSets" />
            </Grid>

            <Grid item xs={12} sm={6}>
              <NodeField name="role" />
            </Grid>
          </>
        ) : null}

        {user.isAdmin ? (
          <>
            <Grid item xs={12} sm={12}>
              <TextField name="participantId" helperText="This is for your own bookkeepping, whatever internal ID you would like to assign to participants to help organize them. The system will yell at you if you try to add a duplicate ID." />
            </Grid>

            <Grid item xs={12} sm={12}>
              {node && node.id ? <Typography variant="body2">Internal System ID: <b>{node.id}</b></Typography> : null}
            </Grid>
          </>
        ) : null}

      </Grid>
    );
  }
}

UserEditor = compose(
  withCurrentUser,
)(UserEditor);

UserEditor.displayName = 'UserEditor';

export default UserEditor;
