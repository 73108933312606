import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-apollo';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import _ from 'lodash';
import FormHelperText from '@material-ui/core/FormHelperText';
import Switch from '@material-ui/core/Switch';

import splitFieldName from '../splitFieldName';
import HelperText from './HelperText';
import { MutationContext } from '../MutationContext';

const styles = theme => ({
  root: {
    display: 'flex',
    flexBasis: 'column',
    justifyContent: 'center',
  },
});

class BooleanField extends React.Component {
  static contextType = MutationContext;

  static propTypes = {
    name: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
    helperText: PropTypes.node,
    label: PropTypes.string,
    initialValue: PropTypes.bool,
    switchy: PropTypes.bool,
  }

  static defaultProps = {
    helperText: undefined,
    label: '',
    initialValue: false,
    switchy: true,
  }

  constructor(props, context) {
    super(props, context);
    const { registerField } = context;
    const { rootFieldName, extFieldName, valueFieldName } = splitFieldName(props.name);
    this.rootName = rootFieldName; this.extName = extFieldName; this.valueName = valueFieldName;

    registerField(props.name, { initialValue: props.initialValue });
  }

  handleChange = (event) => {
    const { onChange } = this.context;
    const { name } = this.props;
    const value = event.target.checked;

    onChange(name, value);
  }

  render() {
    const { name, classes, helperText, label: propsLabel, switchy } = this.props;
    const { fields, pendingMutation, schema, type } = this.context;
    const schemaField = schema.types[type].fields[this.rootName];
    const label = propsLabel || schemaField.label;
    const value = !!_.get(fields, this.valueName);
    const errors = fields[this.rootName].errors;

    const Component = switchy ? Switch : Checkbox;

    return (
      <FormControl error={!!errors.length} disabled={pendingMutation} className={classes.root}>
        <FormGroup>
          <FormControlLabel
            control={
              <Component checked={value} onChange={this.handleChange} value={name} />
            }
            label={label}
          />
        </FormGroup>
        <FormHelperText>
          <HelperText text={helperText} errors={errors} />
        </FormHelperText>
      </FormControl>
    );
  }
}

BooleanField = compose(
  withStyles(styles),
)(BooleanField);

BooleanField.displayName = 'BooleanField';

export default BooleanField;
