import React from 'react';
import PropTypes from 'prop-types';
import { MutationContext } from '../MutationContext';

class Fixture extends React.Component {
  static contextType = MutationContext;

  static propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
  }

  static defaultProps = {
    value: undefined,
  }

  constructor(props, context) {
    super(props, context);
    const { registerField, onChange } = context;
    registerField(props.name, { initialValue: props.value });
    onChange(props.name, props.value);
  }

  render() {
    return null;
  }
}

Fixture.displayName = 'Fixture';

export default Fixture;
