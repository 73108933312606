import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import UserIcon from 'mdi-material-ui/Account';
import UserEditIcon from 'mdi-material-ui/AccountEdit';
import SignOutIcon from 'mdi-material-ui/LogoutVariant';
import SignInIcon from 'mdi-material-ui/LoginVariant';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import Tooltip from '@material-ui/core/Tooltip';
import ListSubheader from '@material-ui/core/ListSubheader';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';

import { withCurrentUser } from '/../../kiska/src/components/contexts/CurrentUserContext';

const styles = theme => ({
  root: {
  },
  header: {
    '&:focus': {
      outline: 'none',
    },
  },
  signInButton: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 12,
    '& svg': {
      fontSize: 18,
    },
  },
});

class UserMenu extends Component {
  static propTypes = {
    currentUser: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    showSignIn: PropTypes.bool,
    location: PropTypes.object.isRequired,
  }

  static defaultProps = {
    className: undefined,
    showSignIn: false,
  }

  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
    };
  }

  handleMenuOpen = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { anchorEl } = this.state;
    const { classes, className, showSignIn, currentUser: { user, signOut }, location } = this.props;

    const userMenuItems = [];

    if (user.isAdmin) {
      userMenuItems.push({ icon: <UserIcon />, primary: 'View Profile', to: `/admin/users/${user.id}` });
      userMenuItems.push({ icon: <UserEditIcon />, primary: 'Edit Profile', to: `/admin/users/${user.id}/edit` });
    }
    userMenuItems.push({ icon: <SignOutIcon />, primary: 'Sign Out', func: () => { this.handleMenuClose(); signOut(); } });

    const userMenu = userMenuItems.map(({ icon, primary, secondary, to, func }, index) => (
      <MenuItem
        className={classes.menuItem}
        key={index}
        component={to ? Link : undefined}
        to={to}
        onClick={func}
      >
        <ListItemIcon className={classes.icon}>
          {icon}
        </ListItemIcon>
        <ListItemText
          classes={{ primary: classes.primary }}
          inset
          primary={primary}
          secondary={secondary}
        />
      </MenuItem>
    ));

    return (
      <>
        {user.isAuthenticated ? (
          <div className={classNames(className)}>
            <IconButton className={classNames(classes.menuButton)} color="inherit" onClick={this.handleMenuOpen}>
              <UserIcon />
            </IconButton>
          </div>
        ) : null}

        <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={this.handleMenuClose}>
          {user.isAuthenticated ? <ListSubheader className={classes.header}>Hi {user.displayName}!</ListSubheader> : null}
          {userMenu}
        </Menu>

        {user.isAnon && showSignIn ? (
          <Tooltip title="Sign In">
            <Button
              className={classNames(classes.menuButton, className, classes.signInButton)}
              color="inherit"
              size="small"
              component={Link}
              to={`/sign-in?op=sign-in&url=${location.pathname}`}
            >
              <UserIcon /><span>Sign In</span>
            </Button>
          </Tooltip>
        ) : null}
      </>
    );
  }
}

UserMenu = compose(
  withStyles(styles),
  withCurrentUser,
  withRouter,
)(UserMenu);
UserMenu.displayName = 'UserMenu';
export default UserMenu;
