import formatDate from 'date-fns/format';

const FileSaver = require('file-saver');
const { Parser } = require('json2csv');

const downloadSessionResults = (session, options) => {
  const { responses, staircaseSet } = session;
  console.log(`Preparing download for session results...`);

  const filename = `Session-Results_user-${session.user.id}_staircase-set-${staircaseSet.label}.csv`;

  // Massage data
  const fields = [
    {
      label: 'Timestamp',
      value: row => formatDate(new Date(row.updatedAt), `MMM dd, yyyy h:mm:ss.SS aaaaa'm'`),
    },
    {
      label: 'Staircase',
      value: row => session.staircaseSet.staircases.find(s => s.staircase.id === row.staircaseId).staircase.label,
    },
    {
      label: 'Level',
      value: 'data.level',
    },
    {
      label: 'Response Time',
      value: 'data.responseTime',
    },
    {
      label: 'Result',
      value: 'data.result',
    },
  ];

  const json2csvParser = new Parser({ fields });
  const csv = json2csvParser.parse(responses);

  const blob = new window.Blob([csv], { type: 'text/csv;charset=utf-8' });
  FileSaver.saveAs(blob, filename);
};

export default downloadSessionResults;
