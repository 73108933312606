export default {
  types: {
    response: {

      renderers: {
        inline: node => node.result,
      },

      selections: {
        default: `id data user{id displayName} staircase{id label}`,
      },

      fields: {
        session: {
          type: 'training_session',
        },

        data: {
          type: 'Json',
        },

        user: {
          type: 'user',
        },

        staircase: {
          type: 'staircase',
        },

      },
    },
  },
};
