import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { MutationContext } from '/../../kiska/src/components/MutationContext/MutationContext'; // eslint-disable-line import/no-extraneous-dependencies
import TextField from '/../../kiska/src/components/MutationContext/FormFields/TextField'; // eslint-disable-line import/no-extraneous-dependencies
import FileUplaoder from '/../../kiska/src/components/MutationContext/FormFields/FileField'; // eslint-disable-line import/no-extraneous-dependencies
import BooleanField from '/../../kiska/src/components/MutationContext/FormFields/BooleanField';
import SelectField from '../../../../kiska/src/components/MutationContext/FormFields/SelectField';
import StaircaseInheritedConfigEditor from '../StaircaseInheritedConfigEditor';
import EditorSection from '../EditorSection';

class AppSettingEditor extends React.Component {
  static contextType = MutationContext;

  static propTypes = {
    id: PropTypes.string.isRequired,
  }

  renderSessionSettings = () => {
    return (
      <Grid container spacing={24}>
        <Grid item xs={12}>
          <BooleanField
            name="json.clickToChoose"
            label="Click to choose"
            helperText="Allow users to tap or click the face to choose it. This is the only way users can choose a face on phones or tablets."
            initialValue
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectField
            name="json.chooseLeftKey"
            label="Choose Left Key"
            helperText="The key users press to choose the left face"
            initialValue="KeyF"
            options={[
              { value: 'KeyF', primary: 'F key' },
              { value: 'ControlLeft', primary: 'Left control key' },
              { value: 'ShiftLeft', primary: 'Left shift key' },
              { value: 'KeyA', primary: 'A key' },
              { value: 'KeyQ', primary: 'Q key' },
              { value: 'ArrowLeft', primary: 'Left arrow key' },
            ]}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectField
            name="json.chooseRightKey"
            label="Choose Right Key"
            helperText="The key users press to choose the right face"
            initialValue="KeyJ"
            options={[
              { value: 'KeyJ', primary: 'J key' },
              { value: 'ControlRight', primary: 'Right control key' },
              { value: 'ShiftRight', primary: 'Right shift key' },
              { value: 'KeyL', primary: 'L key' },
              { value: 'KeyP', primary: 'P key' },
              { value: 'ArrowRight', primary: 'Right arrow key' },
            ]}
          />
        </Grid>

      </Grid>
    );
  }

  render() {
    const { id } = this.props;

    if (id === 'session') return this.renderSessionSettings();

    return <Typography>Not much here :-(</Typography>;
  }
}

export default AppSettingEditor;
