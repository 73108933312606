
module.exports = (env) => {
  env = env || process.env.NODE_ENV || 'development';

  const serviceName = 'facetraining';
  const apiPort = 8080;
  const kiskaApiPort = 3001;
  const webServerPort = 3000;
  const distPath = 'dist';
  const devOrigin = `http://localhost.com:${webServerPort}`;

  const baseSettings = {
    distPath,
    appRootPath: '/home/noland/projects/facetraining2/facetraining2',
    serviceName,
    origin: devOrigin,
    kiskaApiEndpoint: `http://localhost.com:${kiskaApiPort}/kiska-api`,
    ssrKiskaApiEndpoint: `http://localhost.com:${kiskaApiPort}/kiska-api`,
    apiEndpoint: `http://localhost.com:${apiPort}/v1/graphql`,
    ssrApiEndpoint: `http://localhost.com:${apiPort}/v1/graphql`,
    webServerPort,
    staticAssetsSource: 'static',
    staticAssetsPath: `${distPath}/static`,
    staticAssetsRootUrl: '/static',
    bundlesPath: `${distPath}/bundles`,
    bundlesRootUrl: '/bundles',
    authCookieName: 'kiska_auth_token',
    authHttpOnlyCookieName: 'kiska_httpOnly_auth_token',
    requireInviteToSignUp: true,
    signUpByInvitationRootUrl: '/sign-up',
    encryptDbBackups: false,
    firebaseConfig: {
      apiKey: 'AIzaSyAvJgW3YCL7APVaghnIwIfYi1zAhwibIJs',
      authDomain: 'face-training.firebaseapp.com',
      databaseURL: 'https://face-training.firebaseio.com',
      projectId: 'face-training',
      storageBucket: 'face-training.appspot.com',
      messagingSenderId: '362809791868',
      appId: '1:362809791868:web:2389270dbb26d7ce',
    },
    authorizedFileUploadRoles: ['admin', 'site-owner'],
  };

  const developmentSettings = baseSettings;

  const productionOrigin = 'https://face.training:443';
  const productionSettings = {
    ...baseSettings,
    appRootPath: '/srv/facetraining/facetraining',
    origin: productionOrigin,
    kiskaApiEndpoint: `${productionOrigin}/kiska-api`,
    ssrKiskaApiEndpoint: `http://localhost:${kiskaApiPort}/kiska-api`,
    apiEndpoint: `${productionOrigin}/api`,
    ssrApiEndpoint: `http://localhost:${apiPort}/v1/graphql`,
  };

  const localProductionSettings = {
    ...baseSettings,
  };

  const remoteDevelopmentSettings = {
    ...baseSettings,
  };

  switch (env) {
    case 'development': return developmentSettings;
    case 'production': return productionSettings;
    case 'local-production': return localProductionSettings;
    case 'remove-development': return remoteDevelopmentSettings;
    default:
      throw new Error(`Cannot return settings for unknown environment "${env}".`);
  }
};
