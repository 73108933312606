import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import Link from '@material-ui/core/Link';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '8px 4px 4px 4px',
    opacity: 0.8,
    borderTop: 'solid 1px rgba(255,255,255,.05)',
  },
});

class SiteFooter extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    style: PropTypes.object,
  }

  static defaultProps = {
    className: undefined,
    style: undefined,
  }

  render() {
    const { classes, className, style } = this.props;

    return (
      <div className={classnames(classes.root, className)} style={style}>
        <Typography variant="caption">
          &copy; Copyright 2019 - Swansea Face Research
        </Typography>
        <Typography variant="caption">
          Powered by <Link href="https://advancedwebapps.ca"><em>Advanced Web Apps</em></Link>
        </Typography>
      </div>
    );
  }
}

SiteFooter = withStyles(styles)(SiteFooter);

export default SiteFooter;
