import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';
import CloseIcon from 'mdi-material-ui/Close';

import { NodeListContext } from '.';

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  rootBorder: {
    padding: theme.spacing.unit * 1,
    border: 'solid 1px rgba(0,0,0,.25)',
    borderRadius: 5,
  },
  radio: {
    paddingTop: theme.spacing.unit * 0.5,
    paddingBottom: theme.spacing.unit * 0.5,
  },
});

class NullFilter extends Component {
  static contextType = NodeListContext

  static propTypes = {
    label: PropTypes.node,
    classes: PropTypes.object.isRequired,
    invert: PropTypes.bool,
    checkboxOnly: PropTypes.bool,
    nullLabel: PropTypes.string,
    notNullLabel: PropTypes.string,
    indeterminateLabel: PropTypes.string,
    path: PropTypes.string.isRequired,
  }

  static defaultProps = {
    label: ``,
    invert: false,
    checkboxOnly: false,
    nullLabel: 'Has no value',
    notNullLabel: 'Has a value',
    indeterminateLabel: 'Is either',
  }

  constructor(props, context) {
    super(props, context);

    const { type, schema: { getSchemaAtPath } } = context;
    const { path } = this.props;
    this.relatedSchema = getSchemaAtPath(`${type}.${path}`);
    this.relationIdMode = !this.relatedSchema.isScaler;
    this.path = this.relationIdMode ? `${path}.id` : path;
  }


  handleChange = (event) => {
    const { checkboxOnly, invert } = this.props;

    if (!checkboxOnly) {
      this.change(event.target.value);
    } else if (invert) {
      this.change(event.target.checked ? 'false' : 'indeterminate');
    } else {
      this.change(event.target.checked ? 'true' : 'indeterminate');
    }
  }

  change = (value) => {
    const { setVariables } = this.context;
    const relationIdMode = this.relationIdMode;
    const path = this.path;

    setVariables(({ where }) => {
      if (value === 'null') {
        _.set(where, `${path}._is_null`, true);
      } else if (value === 'not-null') {
        _.set(where, `${path}._is_null`, false);
      } else if (relationIdMode) {
        _.set(where, `${path.slice(0, -3)}`, undefined);
      } else {
        _.set(where, `${path}`, undefined);
      }
      console.log(where);
      return { where };
    });
  }

  clear = (event) => {
    this.change(undefined);
  }

  render() {
    const { variables } = this.context;
    const { classes, label, checkboxOnly, nullLabel, notNullLabel, indeterminateLabel } = this.props;
    const path = this.path;

    let content;
    let value = _.get(variables.where, `${path}._is_null`);
    if (value === true) value = 'null';
    else if (value === false) value = 'not-null';
    else value = 'indeterminate';

    if (checkboxOnly) {
      content = (
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox checked={value === 'null'} indeterminate={value === 'indeterminate'} onChange={this.handleChange} />
            }
            label={label}
          />
        </FormGroup>
      );
    } else {
      const radio = <Radio className={classes.radio} />;
      content = (
        <>
          <FormLabel className={classes.label}>{label}</FormLabel>
          <RadioGroup className={classes.group} onChange={this.handleChange} value={value}>
            <FormControlLabel value="null" control={radio} label={nullLabel} />
            <FormControlLabel value="not-null" control={radio} label={notNullLabel} />
            <FormControlLabel value="indeterminate" control={radio} label={indeterminateLabel} />
          </RadioGroup>
        </>
      );
    }

    return (
      <div className={classNames(classes.root, { [`${classes.rootBorder}`]: !checkboxOnly })}>
        <FormControl component="fieldset" className={classes.control}>
          {content}
        </FormControl>
      </div>
    );
  }
}

NullFilter = withStyles(styles)(NullFilter);
export default NullFilter;
