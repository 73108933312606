import _ from 'lodash';

import user from './user';
import post from './post';

import schemas from './*.js'; // eslint-disable-line

const schema = {};

_.forEach(schemas, ({ default: newSchema }, type) => {
  _.merge(schema, newSchema);
});

export default schema;
