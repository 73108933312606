/* eslint-disable no-plusplus */
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { compose, withApollo } from 'react-apollo';
import qs from 'query-string';
import { Link as RouterLink } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { withRouter } from 'react-router';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fade from '@material-ui/core/Fade';
import Link from '@material-ui/core/Link';
import gql from 'graphql-tag';
import _ from 'lodash';
import shortid from 'shortid';

import { getRandomIntInclusive, getNonRepeatingRandomIntArrayInclusive, getRandomIntArrayInclusive } from '/utils';
import { Typography } from '@material-ui/core';
import { withCurrentUser } from '/../../kiska/src/components/contexts/CurrentUserContext';

import logo from '/images/logo.png';
import SessionRunner from '../components/SessionRunner';

// const randomDistributionTest = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
// for (let i = 0; i < 30000; i++) {
//   const value = getRandomIntInclusive(1, 3);
//   randomDistributionTest[value]++;
// }
// console.log('#############################################################');
// console.log('##### Test of your computer\'s random number generator: #####');
// console.log('##### All values should be drawn roughly equally        #####');
// console.log(`Number of 1's drawn: ${randomDistributionTest[1]}`);
// console.log(`Number of 2's drawn: ${randomDistributionTest[2]}`);
// console.log(`Number of 3's drawn: ${randomDistributionTest[3]}`);
// console.log('#############################################################');

const staircaseSetSelections = `
  id label config
  staircases { staircase{
    id label config
    images { file{
      id name url
    }}
  }}
`;

const sessionSelections = `
  id createdAt updatedAt state results status
  responses(order_by: [{createdAt: asc}]){
    id updatedAt data staircaseId
  }
  staircaseSet{
    ${staircaseSetSelections}
  }
`;

const getSessionQuery = gql`
  query GetSession($id: String!){
    session: training_session_by_pk(id: $id){
      ${sessionSelections}
    }
  }
`;

const getSetQuery = gql`
  query GetStaircaseSet($id: String!){
    set: staircase_set_by_pk(id: $id){
      ${staircaseSetSelections}
    }
  }
`;

const insertSessionMutation = gql`
  mutation InsertSession($objects: [training_session_insert_input!]!){
    session: insert_training_session(objects: $objects){
      returning{
       ${sessionSelections}
      }
    }
  }
`;

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '100vh',
    justifyContent: 'center',
    backgroundColor: theme.palette.secondary.main,
    width: '100vw',
    position: 'absolute',
    top: 0,
    left: 0,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
  },
  body: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing.unit * 2,
    width: '100%',
  },
  bodyInner: {
    padding: theme.spacing.unit * 3,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 400,
    maxWidth: '100%',
  },
  error: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  progress: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    justifyContent: 'center',
    marginBottom: 32,
    '&:link, &:visited, &:hover': {
      textDecoration: 'none',
    },
    '& img': {
      height: 120,
      // padding: '16px 8px 16px 16px',
    },
    '& p': {
      fontWeight: 300,
      fontSize: 28,
      lineHeight: 1.2,
    },
  },
});

class RunSessionPage extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    client: PropTypes.object.isRequired,
  }

  static defaultProps = {}

  constructor(props) {
    super(props);


    this.state = {
      set: null,
      session: null,
      pageError: null,
      imagesLoaded: false,
      ready: false,
    };
  }

  componentDidMount = () => {
    const query = qs.parse(this.props.location.search);
    const { set, session } = query;

    if (session) this.getSession(session);
    else if (set) this.getSet(set);
    else this.handlePageError('Something is wrong with the page address. Go back and try again.');
  }

  handlePageError = (error) => {
    this.setState({ pageError: error });
    console.error(error);
  }

  buildSequence = (set) => {
    const setCount = set.staircases.length;
    const mode = set.config.sequenceMode;
    const length = 100;
    let sequence = [];

    if (mode === 'round-robin') {
      const baseSeq = getNonRepeatingRandomIntArrayInclusive(0, setCount - 1);
      for (let i = 0; i < length; i += setCount) sequence = [...sequence, ...baseSeq];
    } else if (mode === 'random-robin') {
      for (let i = 0; i < length; i += setCount) {
        const seq = getNonRepeatingRandomIntArrayInclusive(0, setCount - 1);
        sequence = [...sequence, ...seq];
      }
    } else if (mode === 'random') {
      sequence = getRandomIntArrayInclusive(0, setCount - 1, length);
    } else {
      throw new Error(`Unknown sequence mode "${mode}"`);
    }

    const sequenceKeys = _.map(set.staircases, ({ staircase }) => staircase.id);


    return { sequence, sequenceKeys };
  }

  buildInitialState = (set) => {
    const { sequence, sequenceKeys } = this.buildSequence(set);
    const state = {
      sequence,
      sequenceKeys,
      sequenceIndex: 0,
      staircases: {},
    };

    set.staircases.forEach(({ staircase }) => {
      state.staircases[staircase.id] = {
        status: 'in-progress',
        level: 0,
        previousMotion: 'none',
        countdownThresholdReached: false,
        postCountdownTrials: 0,

        positiveInflections: 0,
        negativeInflections: 0,
        inflections: 0,
        positiveInflectionAvg: 0,
        negativeInflectionAvg: 0,
        inflectionAvg: 0,

        avgReversalLevel: 0,
        unweightedAvgReversalLevel: 0,
        maxReversals: 0,
        maxReversalsLevel: 0,
        totalReversals: 0,
        totalReversalLevels: 0,

        attempt: {
          previousResult: 'none',
          totalCorrect: 0,
          consecutiveCorrect: 0,
          totalIncorrect: 0,
          consecutiveIncorrect: 0,
          totalTimeouts: 0,
          consecutiveTimeouts: 0,
        },
        levels: [
          {
            level: 0,
            successfullAttempts: 0,
            failedAttempts: 0,
            positiveInflections: 0,
            negativeInflections: 0,
            reversals: 0,
          },
        ],
      };
    });

    const stateString = JSON.stringify(state);
    console.log('Initial state size: ', stateString.length);

    return state;
  }

  insertSession = async (set) => {
    const { currentUser: { user }, client } = this.props;

    const now = new Date();
    const session = {
      id: shortid.generate(),
      createdAt: now,
      updatedAt: now,
      staircaseSetId: set.id,
      userId: user.id,
      state: this.buildInitialState(set),
      status: 'in-progress',
      results: null,
    };
    client.mutate({ mutation: insertSessionMutation, variables: { objects: [session] } })
      .then(({ data, error }) => {
        if (error) { this.handlePageError(error); return; }
        this.handleSessionLoaded(data.session.returning[0]);
      }).catch(error => this.handlePageError(error));
  }

  loadImages = (set) => {
    this.loadingImages = {};
    set.staircases.forEach(({ staircase }) => {
      staircase.images.forEach(({ file }) => {
        const img = new window.Image();
        img.src = file.url;
        img.onload = () => {
          this.loadingImages[file.id].loaded = true;
          const unloadedImages = _.filter(this.loadingImages, i => !i.loaded);
          if (!unloadedImages.length) this.handleImagesLoaded();
        };

        this.loadingImages[file.id] = {
          loaded: false,
          img,
        };
      });
    });
  }

  handleSetLoaded = (set) => {
    const { currentUser: { user } } = this.props;

    console.log('User:', user);
    console.log('Set: ', set);

    const sets = _.get(user, 'setAssignment.data.sets', []);

    const assigned = sets.find(s => (s ? s.id === set.id : false));
    if (!assigned) {
      this.handlePageError('This type of training has not been assigned to you. Please contact us for help.');
      return;
    }

    if (!sets[0] || sets[0].id !== set.id) {
      this.handlePageError('Please go back and start with the first training session available.');
      return;
    }

    const inProgressSessions = user.sessions.filter((s) => {
      if (s.staircaseSetId !== set.id) return false;
      if (s.status !== 'complete') return true;
      return false;
    });
    if (inProgressSessions.length > 10000) {
      this.handlePageError(`You already have ${inProgressSessions.length} unfinished session${inProgressSessions.length ? 's' : ''} for this type of training. Finish that first please.`);
      return;
    }

    this.insertSession(set);

    this.setState({ set });
  }

  handleSessionLoaded = (session) => {
    this.setState({ session });
    console.log('Session: ', session);
    this.loadImages(session.staircaseSet);
  }

  handleImagesLoaded = () => {
    this.setState({ imagesLoaded: true });
    setTimeout(() => this.setState({ ready: true }), 2000);
  };

  getSet = (id) => {
    const { client } = this.props;
    client.query({ query: getSetQuery, variables: { id } })
      .then(({ error, data }) => {
        if (error) { this.handlePageError(error); return; }
        this.handleSetLoaded(data.set);
      }).catch(error => this.handlePageError(error));
  }

  getSession = (id) => {
    const { client } = this.props;
    client.query({ query: getSessionQuery, variables: { id } })
      .then(({ error, data }) => {
        if (error) { this.handlePageError(error); return; }
        this.handleSessionLoaded(data.session);
      }).catch(error => this.handlePageError(error));
  }

  renderError = (error) => {
    const { classes } = this.props;
    return (
      <div className={classes.error}>
        <Typography variant="h6">Error</Typography>
        <Typography variant="body1">
          Sorry, there was an error loading your training session.
          <br /><br />
        </Typography>
        <Typography variant="body1" color="textSecondary" component="div">
          Details:<br />
          {error.message || error}
        </Typography>
      </div>
    );
  }

  render() {
    const { pageError, set, session, imagesLoaded, ready } = this.state;
    const { classes, currentUser: user, history, location, match } = this.props;
    let body;
    let message;

    if (user.isAnon) {
      body = this.renderError({
        message: (
          <Typography variant="body2">
            You must have an account and be signed in to run a training session.
            <Link component={RouterLink} to="/sign-in">Sign In</Link> or <Link component={RouterLink} to="/become-a-participant">Sign Up Now</Link>
          </Typography>
        ),
      });
    } else if (pageError) {
      body = this.renderError(pageError);
    } else {
      if (!session) {
        message = 'Loading session data...';
      } else if (!imagesLoaded) {
        message = 'Loading images...';
      } else {
        message = 'Starting session...';
      }
      body = (
        <div className={classes.progress}>
          <CircularProgress size={100} thickness={1} color="secondary" variant={ready ? 'static' : undefined} value={ready ? 100 : undefined} />
          <br /><br />
          <Typography variant="body1" align="center">{message}</Typography>
        </div>
      );
    }

    return (
      <>
        <Helmet>
          <title>Face Training Session</title>
        </Helmet>
        {ready ? (
          <Fade timeout={{ enter: 3, exit: 0 }} in={ready}>
            <SessionRunner session={session} />
          </Fade>
        ) : null}
        <Fade timeout={{ enter: 300, exit: 3 }} in={!ready}>
          <div className={classes.root}>
            <div className={classes.header} />
            <div className={classes.body}>
              <Paper className={classes.bodyInner}>
                <div className={classes.logo}>
                  <img src={logo} alt="Swansea Face Training logo" />
                  <Typography component="p">
                    Face<br />Research<br />Swansea
                  </Typography>
                </div>
                {body}
              </Paper>
            </div>
          </div>
        </Fade>
      </>
    );
  }
}

RunSessionPage = compose(
  withStyles(styles),
  withCurrentUser,
  withRouter,
  withApollo,
)(RunSessionPage);
RunSessionPage.displayName = 'RunSessionPage';
export default RunSessionPage;
