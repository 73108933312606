import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import ReactGA from 'react-ga';
import { compose } from 'react-apollo';


class GoogleAnalytics extends Component {
  static propTypes = {
    options: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  }

  componentDidMount() {
    const { options, history } = this.props;

    // Fire up Google Analytics
    if (options.googleAnalytics) {
      ReactGA.initialize(options.googleAnalytics.trackingId);
    }
    let listeningToHistory = false;

    const logPageView = (location) => {
      if (options.googleAnalytics) {
        ReactGA.pageview(location.pathname);
      }
    };

    logPageView(window.location);

    // Setup React Router to log page view in Google Analytics
    if (!listeningToHistory) {
      history.listen(logPageView);
      listeningToHistory = true;
    }
  }

  render() {
    return null;
  }
}

GoogleAnalytics = compose(
  withRouter,
)(GoogleAnalytics);

export default GoogleAnalytics;
