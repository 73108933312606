const scalerTypes = [
  'ID',
  'String',
  'Int',
  'Float',
  'Timestamp',
  'Boolean',
  'Json',
  'Upload',
];

const typeIsScaler = typeName => scalerTypes.includes(typeName);

export { scalerTypes, typeIsScaler };
