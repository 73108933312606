import React from 'react';
import _ from 'lodash';
import pluralize from 'pluralize';

import buildGetSchemaAtPath from './buildGetSchemaAtPath';
import { typeIsScaler } from './scalerTypes';
import getFiltersForField from './getFiltersForField';
import inlineScalerRenderers from './inlineScalerRenderers';
import { getRelationSchemaType } from '../utils';

const typeLabel = (type) => {
  // Try to use user-supplied label first
  if (type.label) return type.label;

  // Otherwise just split the type name with spaces and capitilize
  // eg: UploadedFile --> Uploaded File
  return _.upperFirst(_.camelCase(type.name).replace(/([a-z])([A-Z])/g, '$1 $2'));
};

const fieldLabel = (field) => {
  // Try to use user-supplied label first
  if (field.label) return field.label;

  // If this is id, then capitilize the whole thing
  if (field.name === 'id') return 'ID';

  // Otherwise just split the field name with spaces and capitilize
  // eg: firstName --> First Name
  return _.upperFirst(field.name.replace(/([a-z])([A-Z])/g, '$1 $2'));
};

const buildNonScalerRenderer = (field, type, relationType) => (nodes) => {
  const render = relationType.renderers.inline;

  if (!nodes) {
    return 'none';
  }

  if (!Array.isArray(nodes)) {
    return render(nodes);
  }

  return (
    <ul style={{ margin: 0 }}>
      {nodes.map((node) => {
        const isManyToMany = node.__typename.includes('__x__');
        if (isManyToMany) node = node[relationType.name];

        return <li key={node.id}>{render(node)}</li>;
      })}
    </ul>
  );
};

const normalizeField = (field, type, schema) => {
  field.label = fieldLabel(field);
  field.isScaler = typeIsScaler(field.type);
  field.filters = field.filters || getFiltersForField(field);

  let renderers = {};
  if (field.isScaler) {
    renderers = {
      inline: inlineScalerRenderers[field.type],
    };
  } else {
    const relationType = getRelationSchemaType(field, type, schema);
    renderers = {
      inline: buildNonScalerRenderer(field, type, relationType),
    };
  }
  field.renderers = _.merge(renderers, field.renderers);
};

const normalizeType1stPass = (type, schema) => {
  // Add label
  type.label = typeLabel(type);
  type.pluralLabel = type.pluralLabel || pluralize(type.label);
  type.lowerCaseLabel = type.label.toLowerCase();
  type.lowerCasePluralLabel = type.pluralLabel.toLowerCase();

  // Optional built-in fields
  if (type.fields.createdAt !== false) {
    type.fields.createdAt = {
      type: 'Timestamp',
      label: 'Date Created',
      required: true,
    };
  }
  if (type.fields.updatedAt !== false) {
    type.fields.updatedAt = {
      type: 'Timestamp',
      label: 'Last Updated',
      required: true,
    };
  }

  // Add id field
  type.fields.id = {
    type: 'String',
    required: true,
    unique: true,
  };

  // Add owner field
  if (type.name !== 'user') {
    type.fields.owner = {
      type: 'user',
      required: false,
    };
  }

  // Add lists of fields
  type.allFields = _.map(type.fields, (field, fieldName) => fieldName);
  type.scalerFields = type.allFields.filter(fieldName => type.fields[fieldName].isScaler);
  type.nonScalerFields = type.allFields.filter(fieldName => !type.fields[fieldName].isScaler);

  // Add default basic renderer for type
  type.renderers = _.merge({
    inline: node => `ID: ${node.id}`,
  }, type.renderers);

  // Add default selection set
  type.selections = _.merge(
    {
      default: type.scalerFields.join(' '),
    },
    type.selections,
  );
};

const normalizeType2ndPass = (type, schema) => {
  // Add field-specific stuff
  _.forEach(type.fields, (field, fieldName) => {
    field.name = fieldName;
    normalizeField(field, type, schema);
  });
};

const normalizeSchema = (schema) => {
  schema.getSchemaAtPath = buildGetSchemaAtPath(schema);

  _.forEach(schema.types, (type, typeName) => {
    type.name = typeName;
    normalizeType1stPass(type, schema);
  });
  _.forEach(schema.types, (type, typeName) => {
    normalizeType2ndPass(type, schema);
  });

  Object.freeze(schema);
  return schema;
};

export default normalizeSchema;
