/* eslint-disable camelcase */
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
// import { BatchHttpLink } from 'apollo-link-batch-http';
import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { onError } from 'apollo-link-error';
import { withClientState } from 'apollo-link-state';
import _ from 'lodash';

const isServer = typeof window === 'undefined';

const createHttpLinkSettings = (options, req) => {
  const credentialPolicy = options.origin === (new URL(options.apiEndpoint)).origin
    ? 'same-origin' : 'include';

  const httpLinkSettings = {
    uri: isServer ? options.ssrApiEndpoint : options.apiEndpoint,
    batchInterval: 100, // Hasura don't support batch mode yet
    batchMax: 10,
    fetch: isServer ? options.ssrOnlyCode.fetch : undefined,
    credentials: credentialPolicy,
    headers: {
      'x-requested-with': 'XmlHttpRequest',
    },
  };

  console.log('Creating apollo client with UIR:', httpLinkSettings.uri);

  if (isServer) {
    const { authCookieName, authHttpOnlyCookieName } = options;
    const cookieLib = options.ssrOnlyCode.cookie;
    const receivedCookies = cookieLib.parse(_.get(req, 'headers.Cookie') || _.get(req, 'headers.cookie', ''));
    const token = receivedCookies[authCookieName];
    const httpOnlyToken = receivedCookies[authHttpOnlyCookieName];

    let cookie = `${cookieLib.serialize(authCookieName, token)};`;
    cookie += ` ${cookieLib.serialize(authHttpOnlyCookieName, httpOnlyToken)}`;
    httpLinkSettings.headers.Cookie = cookie;
  }

  return httpLinkSettings;
};

const handleLinkError = (error, options, request) => {
  const { graphQLErrors, networkError } = error;
  if (options.onError) {
    options.onGraphqlError({ graphQLErrors, networkError });
  }

  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.error('\n[GraphQL Link Error]');
      console.error('\tMessage: ', message);
      if (locations) {
        console.error('\tLocations: ');
        locations.forEach(({ line, column }) => {
          console.error(`\t\tLine: ${line} Column: ${column}`);
        });
      }
      console.error(`\tPath: ${path}`);
    });
  }

  if (networkError) {
    console.error('\n[GraphQL Link Network Error]');
    console.error(`\tMessage: ${networkError}`);
  }
};

const createErrorLink = (options, request) => {
  const errorLink = onError((error) => {
    const next = () => handleLinkError(error, options, request);
    if (options.onError) {
      options.onError({ error, options, request, next });
    } else {
      next();
    }
  });

  return errorLink;
};

const createLocalStateLink = (options, cache, request) => {
  const stateLinkDefaults = {};
  const localStateLink = withClientState({
    cache,
    defaults: stateLinkDefaults,
  });
  return localStateLink;
};

const createApolloClient = (options, request) => {
  const cache = isServer
    ? new InMemoryCache()
    : new InMemoryCache().restore(window.__APOLLO_STATE__);

  const httpLinkSettings = createHttpLinkSettings(options, request);
  const errorLink = createErrorLink(options, request);
  const localStateLink = createLocalStateLink(options, cache, request);
  const httpLink = createHttpLink(httpLinkSettings);
  // const httpLink = new BatchHttpLink(httpLinkSettings);

  //* ************** Build composed link **************
  const links = [errorLink, localStateLink, httpLink];
  const link = ApolloLink.from(links);

  const apolloClientOptions = {
    ssrMode: isServer,
    link,
    cache,
  };

  const client = new ApolloClient(apolloClientOptions);
  return client;
};

export default createApolloClient;
