import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MuiTextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';

import splitFieldName from '../splitFieldName';
import HelperText from './HelperText';
import { MutationContext } from '../MutationContext';

const styles = theme => ({
  root: {
    width: '100%',
  },
});

class TextField extends Component {
  static contextType = MutationContext;

  static propTypes = {
    name: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
    helperText: PropTypes.node,
    formatter: PropTypes.func,
    multiline: PropTypes.bool,
    rows: PropTypes.number,
    label: PropTypes.string,
    initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    disabled: PropTypes.bool,
    type: PropTypes.string,
  }

  static defaultProps = {
    helperText: undefined,
    multiline: false,
    rows: 0,
    formatter: null,
    label: '',
    initialValue: '',
    disabled: false,
    type: undefined,
  }

  constructor(props, context) {
    super(props, context);
    const { registerField } = context;
    const { name, initialValue } = props;
    const { rootFieldName, extFieldName, valueFieldName } = splitFieldName(name);
    this.rootName = rootFieldName; this.extName = extFieldName; this.valueName = valueFieldName;

    registerField(props.name, { initialValue });
  }

  handleChange = (event) => {
    const { onChange } = this.context;
    const { name, formatter, type } = this.props;
    let value = event.target.value;

    if (type === 'number') {
      value = Number(value);
    }

    if (formatter) value = formatter(value);

    onChange(name, value);
  }

  handleKeyPress = (event) => {
    const { runDefaultMutation } = this.context;
    const { multiline, rows } = this.props;

    if ((!multiline && !rows) && event.key === 'Enter') {
      runDefaultMutation();
    }
  }

  render() {
    const { name, classes, helperText, rows, formatter, label: propsLabel, initialValue, type: inputType, disabled, ...rest } = this.props;
    const { fields, pendingMutation, schema, type } = this.context;
    const value = _.get(fields, this.valueName) || '';
    const schemaField = schema.types[type].fields[this.rootName];
    const label = propsLabel || schemaField.label;
    const errors = fields[this.rootName].errors;

    return (
      <MuiTextField
        name={name}
        value={`${value}`}
        label={label}
        error={!!errors.length}
        onChange={this.handleChange}
        disabled={disabled || pendingMutation}
        helperText={<HelperText text={helperText} errors={errors} />}
        onKeyPress={this.handleKeyPress}
        className={classes.root}
        rows={rows}
        multiline={!!rows}
        type={inputType}
        variant={rows ? 'outlined' : undefined}
        {...rest}
      />
    );
  }
}

TextField = withStyles(styles)(TextField);

export default TextField;
