import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { compose } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemText from '@material-ui/core/ListItemText';
import UsersIcon from 'mdi-material-ui/AccountGroup';
import AdminUsersIcon from 'mdi-material-ui/ShieldAccount';
import StairsIcon from 'mdi-material-ui/Stairs';
import BuildIcon from 'mdi-material-ui/Hammer';
import ResultsIcon from 'mdi-material-ui/ChartBar';
import TableIcon from 'mdi-material-ui/TableLarge';
import DatabaseIcon from 'mdi-material-ui/Database';
import UnknownUserIcon from 'mdi-material-ui/AccountQuestion';
import SetIcon from 'mdi-material-ui/CodeBraces';
import ParticipantIcon from 'mdi-material-ui/TestTube';
import AssignIcon from 'mdi-material-ui/ClipboardAccountOutline';
import SettingsIcon from 'mdi-material-ui/Settings';
import SiteIcon from 'mdi-material-ui/Earth';
import SessionIcon from 'mdi-material-ui/Train';
import InvitationsIcon from 'mdi-material-ui/AccountPlus';
import grey from '@material-ui/core/colors/grey';

const menu = [
  { type: 'header', icon: <BuildIcon />, text: 'Build Stuff' },
  { icon: <StairsIcon />, primary: 'Staircases', to: '/admin/staircases' },
  { icon: <SetIcon />, primary: 'Staircase Sets', secondary: 'Group into sets', to: '/admin/staircase-sets' },
  { icon: <AssignIcon />, primary: 'Assignments', secondary: 'Assign sets to users', to: '/admin/staircase-set-assignment' },

  { type: 'header', icon: <ResultsIcon />, text: 'Results & Data' },
  { icon: <TableIcon />, primary: 'Results Table', to: '/admin/sessions' },
  // { icon: <DatabaseIcon />, primary: 'Raw Database', to: '/admin/database' },

  { type: 'header', icon: <UsersIcon />, text: 'Users' },
  { icon: <InvitationsIcon />, primary: 'User Invitations', to: '/admin/user-invitations' },
  { icon: <ParticipantIcon />, primary: 'Participants', to: '/admin/users-by-role/participant' },
  { icon: <AdminUsersIcon />, primary: 'Administrators', to: '/admin/users-by-role/administrator' },
  { icon: <UnknownUserIcon />, primary: 'Other Users', secondary: 'Users without a role', to: '/admin/users-by-role/user' },

  { type: 'header', icon: <SettingsIcon />, text: 'Settings' },
  { icon: <SessionIcon />, primary: 'Training Session', to: '/admin/settings/session' },
  // { icon: <SiteIcon />, primary: 'Website', to: '/admin/settings/website' },
];

const styles = theme => ({
  root: {

  },
  item: {
    paddingTop: 5,
    paddingBottom: 5,
    '&.active': {
      // backgroundColor: theme.palette.primary[900],
      backgroundColor: theme.palette.background.default,
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper,
    '&:not(:first-child)': {
      marginTop: theme.spacing.unit * 2,
    },
    '& svg': {
      marginRight: theme.spacing.unit * 2,
    },
  },
});

// eslint-disable-next-line react/prop-types
const Item = withStyles(styles)(({ classes, icon, primary, secondary, to }) => {
  return (
    <ListItem button component={NavLink} to={to} className={classes.item}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={primary} secondary={secondary} />
    </ListItem>
  );
});

class AdminMenu extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
  }

  render() {
    const { classes } = this.props;

    return (
      <List>
        {menu.map((item, index) => {
          if (item.type === 'header') {
            return (
              <React.Fragment key={index}>
                <ListSubheader className={classes.header}>{item.icon} {item.text}</ListSubheader>
                <Divider />
              </React.Fragment>
            );
          }

          return <Item key={index} {...item} />;
        })}
      </List>
    );
  }
}

AdminMenu = compose(
  withStyles(styles),
)(AdminMenu);

export default AdminMenu;
