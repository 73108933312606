import React, { Component, createContext } from 'react';
import PropTypes from 'prop-types';
import { compose, withApollo } from 'react-apollo';
import _ from 'lodash';
import to from 'await-to-js';

import splitFieldName from './splitFieldName';
import { SchemaContext } from '../contexts/SchemaContext'; // eslint-disable-line import/no-extraneous-dependencies
import { transformNodeValueToFormValue, transformFormValueToMutationVar } from './transforms';
import { atLeastOneError, validate } from './validation';

const MutationContext = createContext({});
const MutationContextListener = MutationContext.Listener;

class MutationProvider extends Component {
  static contextType = SchemaContext;

  static propTypes = {
    schemaOverrides: PropTypes.object,
    liveValidation: PropTypes.bool,
    type: PropTypes.string.isRequired,
    node: PropTypes.object,
    client: PropTypes.object.isRequired,
    onSuccess: PropTypes.func,
    onError: PropTypes.func,
    defaultMutation: PropTypes.string,
    children: PropTypes.node,
    mutationBuilders: PropTypes.object.isRequired,
  }


  static defaultProps = {
    liveValidation: true,
    schemaOverrides: {},
    node: undefined,
    onSuccess: null,
    defaultMutation: '',
    children: null,
    onError: null,
  }

  eventListeners = {
    success: {},
    error: {},
    complete: {},
    validationFailed: {},
    validationSuccess: {},
  }

  lastMutationAttempted = null

  cancelMutationHandlers = false

  constructor(props, context) {
    super(props, context);

    this.state = {
      pendingMutation: false,
      globalErrors: [],
      fields: {},
    };

    this.schema = _.merge(context, {
      ...props.schemaOverrides,
    });
  }

  componentWillUnmount = () => {
    this.cancelMutationHandlers = true;
  }

  handleChange = (fieldName, value) => {
    const { liveValidation } = this.props;
    const newState = { ...this.state };
    const { valueFieldName } = splitFieldName(fieldName);

    _.set(newState.fields, valueFieldName, value);
    this.setState(newState);

    if (liveValidation && this.lastMutationAttempted) {
      this.validate(this.lastMutationAttempted, true);
    }
  }

  setErrors = (errors = {}) => {
    const newState = { ...this.state };

    newState.globalErrors = errors.globalErrors || [];

    _.forEach(newState.fields, (field, fieldName) => {
      field.errors = _.get(errors, `fieldErrors.${fieldName}`, []);
    });

    this.setState(newState);
  }

  clearErrors = () => this.setErrors();

  extractFieldValues = () => {
    const { fields } = this.state;
    const values = {};

    _.forEach(fields, (field, fieldName) => {
      values[fieldName] = field.value;
    });

    return values;
  }

  fieldValuesToMutationVars = (fieldValues) => {
    const schema = this.schema;
    const { type, node } = this.props;
    const values = {};

    _.forEach(fieldValues, (fieldValue, fieldName) => {
      values[fieldName] = transformFormValueToMutationVar({ formValue: fieldValue, fieldName, node, type, schema });
    });

    return values;
  }

  eventListenerId = 0

  addEventListener = (eventType, listener) => {
    if (!this.eventListeners[eventType]) throw new Error(`Unknown event type "${eventType}"`);
    this.eventListenerId += 1;
    this.eventListeners[eventType][this.eventListenerId] = listener;
    return this.eventListenerId;
  }

  removeEventListener = (eventType, id) => {
    delete this.eventListeners[eventType][id];
  }

  dispatchEvent = (eventType, ...args) => {
    _.forEach(this.eventListeners[eventType], listener => listener(...args));
  }

  handleMutationStart = (mutation) => {
    this.setState({ pendingMutation: true });
  }

  handleMutationSuccess = (mutation, result) => {
    if (this.cancelMutationHandlers) return;

    const { client, onSuccess } = this.props;

    console.log('Mutation completed successfully.');

    // Brute force update store
    client.reFetchObservableQueries();

    this.dispatchEvent('success', mutation, result);
    if (onSuccess) {
      onSuccess(mutation, result);
    }
  }

  handleMutationError = (mutation, error) => {
    if (this.cancelMutationHandlers) return;

    const { onError } = this.props;
    console.error('Mutation error: ', error);

    this.dispatchEvent('error', mutation, error);
    if (onError) {
      onError(mutation, error);
    }
  }

  handleMutationComplete = (mutation) => {
    if (this.cancelMutationHandlers) return;

    this.setState({
      pendingMutation: false,
    });
    this.dispatchEvent('complete', mutation);
  }

  validate = async (mutation, live) => {
    const schema = this.schema;
    const { node, type } = this.props;
    const { fields } = this.state;

    if (mutation.requiresValidation === false) {
      return true;
    }

    const [unexpectedError, validationErrors] = await to(validate({ fields, node, type, schema }));

    if (unexpectedError) {
      console.error('Unexpected error while validating: ', unexpectedError);
      this.dispatchEvent('validationFailed', mutation);
      return false;
    }

    if (atLeastOneError(validationErrors)) {
      if (!live) {
        console.log('Mutation attempt failed validation: ', validationErrors);
        this.dispatchEvent('validationFailed', mutation, validationErrors);
      }
      this.setErrors(validationErrors);
      return false;
    }

    if (!live) {
      this.dispatchEvent('validation-passed', mutation);
    }

    this.clearErrors();

    return true;
  }

  handleMutate = async (mutationName, mutationMeta) => {
    const schema = this.schema;
    const { node, client, type } = this.props;
    const { fields } = this.state;
    const buildMutation = this.props.mutationBuilders[mutationName];
    const values = this.extractFieldValues();
    const valuesAsMutationVars = this.fieldValuesToMutationVars(values);

    const mutation = buildMutation({ fields, type, node, schema, values, valuesAsMutationVars });
    mutation.meta = mutationMeta;

    this.lastMutationAttempted = mutation;

    if (!mutation.skipValidation) {
      const validationPassed = await this.validate(mutation, false);
      if (!validationPassed) {
        return;
      }
    }

    if (!mutation.variables) {
      mutation.variables = valuesAsMutationVars;
    }

    this.handleMutationStart(mutation);
    client.mutate({
      mutation: mutation.query,
      variables: mutation.variables,
    })
      .then(result => this.handleMutationSuccess(mutation, result))
      .catch(error => this.handleMutationError(mutation, error))
      .finally(() => this.handleMutationComplete(mutation));
  }

  getDefaultFieldValue = (fieldName, options = {}) => {
    const schema = this.schema;
    const { type } = this.props;

    const { extFieldName } = splitFieldName(fieldName);

    if (extFieldName && (options.initialValue !== undefined)) {
      return _.set({}, extFieldName, options.initialValue);
    }

    if (options.initialValue !== undefined) return options.initialValue;

    const schemaDefault = _.get(schema.types, `${type}.fields.${fieldName}.defaultValue`);
    if (schemaDefault !== undefined) return schemaDefault;

    const schemaFieldType = _.get(schema.types, `${type}.fields.${fieldName}.type`);
    if (schemaFieldType) {
      switch (schemaFieldType) {
        case 'ID': return '';
        case 'String': return '';
        case 'Int': return 0;
        case 'Float': return 0;
        case 'Timestamp': return new Date();
        case 'Boolean': return false;
        case 'Json': return null;
        default: return '';
      }
    }

    return null;
  }

  getInitialFieldValue = (fieldName, options = {}) => {
    const schema = this.schema;
    const { node, type } = this.props;

    const { rootFieldName, extFieldName } = splitFieldName(fieldName);
    const nodeValue = _.get(node, rootFieldName);

    const fieldType = schema.types[type].fields[rootFieldName].type;

    if ((fieldType !== 'Json') && (nodeValue !== undefined)) {
      const value = transformNodeValueToFormValue({ nodeValue, node, fieldName: rootFieldName, type, schema });
      const extValue = _.get(value, extFieldName);
      if (extValue === undefined) _.set(value, extFieldName, options.initialValue);
      return value;
    } if ((fieldType === 'Json') && (nodeValue !== undefined)) {
      return nodeValue;
    }
    return this.getDefaultFieldValue(fieldName, options);
  }

  registerField = (fieldName, options = {}) => {
    const schema = this.schema;
    const { type, node } = this.props;

    const { rootFieldName, valueFieldName, extFieldName } = splitFieldName(fieldName);

    if (!_.get(schema.types, `${type}`)) {
      console.warn(`Registering field "${rootFieldName}" for node type "${type}" in MutationForm but "${type}" does not appear in schema.`);
    } else if (!_.get(schema.types, `${type}.fields.${rootFieldName}`)) {
      console.warn(`Registering field "${rootFieldName}" in MutationForm but "${rootFieldName}" does not appear in schema.`);
    }

    const fields = this.state.fields; // eslint-disable-line react/no-access-state-in-setstate

    const initialValue = this.getInitialFieldValue(fieldName, options);
    if (!fields[rootFieldName]) {
      fields[rootFieldName] = {
        value: initialValue,
        errors: [],
      };
    } else {
      _.set(fields, valueFieldName, _.get(initialValue, extFieldName));
    }

    this.setState({ fields });
  }

  runDefaultMutation = () => {
    const { defaultMutation } = this.props;
    if (defaultMutation) this.handleMutate(defaultMutation);
  }

  createContextValue = () => {
    const schema = this.schema;
    const { globalErrors, fields, pendingMutation } = this.state;
    const { node, type } = this.props;

    return {
      // Functions
      onChange: this.handleChange,
      mutate: this.handleMutate,
      registerField: this.registerField,
      addEventListener: this.addEventListener,
      removeEventListener: this.removeEventListener,
      runDefaultMutation: this.runDefaultMutation,

      // Vars
      globalErrors,
      fields,
      node,
      pendingMutation,
      schema,
      schemaType: schema.types[type],
      type,
    };
  }

  render() {
    const { children } = this.props;
    const contextValue = this.createContextValue();

    return (
      <MutationContext.Provider value={contextValue}>
        {children}
      </MutationContext.Provider>
    );
  }
}

MutationProvider = compose(
  withApollo,
)(MutationProvider);

export { MutationProvider, MutationContext, MutationContextListener };
