import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import Divider from '@material-ui/core/Divider';

import editors from '/components/editors';
import CrudForm from '/../../kiska/src/components/MutationContext/CrudForm';
import SaveButton from '/../../kiska/src/components/MutationContext/SaveButton';
import DeleteButton from '/../../kiska/src/components/MutationContext/DeleteButton';
import MutationSnackbars from '/../../kiska/src/components/MutationContext/MutationSnackbars';
import OneNode from '/../../kiska/src/components/OneNode';

const styles = theme => ({
  root: {
    width: '100%',
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    // borderTop: 'solid 1px rgba(255,255,255,.1)',
    marginTop: theme.spacing.unit * 6,
    paddingTop: theme.spacing.unit * 2,
  },
});

class CrudPageForm extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    onTitleChange: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    renderTitle: PropTypes.func.isRequired,
    crudFormProps: PropTypes.object,
    type: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
    nodeId: PropTypes.string,
    editorProps: PropTypes.object,
    where: PropTypes.object,
    redirectOnInsert: PropTypes.bool,
  }

  static defaultProps = {
    crudFormProps: null,
    nodeId: undefined,
    editorProps: undefined,
    where: undefined,
    redirectOnInsert: true,
  }

  buildEditUrl = (node) => {
    const { match: { path } } = this.props;
    const newPath = `${path.split('/').slice(0, -2).join('/')}/${node.id}/edit`;
    return newPath;
  }

  redirect = (mutation, result, node) => this.props.history.replace(this.buildEditUrl(node))

  renderNode = (node) => {
    const { nodeId, where, classes, onTitleChange, type, crudFormProps, renderTitle, editorProps, redirectOnInsert } = this.props;

    const title = renderTitle(node);
    if (title !== this.title) {
      setTimeout(() => onTitleChange(title), 1);
    }
    this.title = title;

    const Editor = editors[`${_.upperFirst(_.camelCase(type))}Editor`];

    return (
      <div className={classes.root}>
        <Helmet>
          <title>{title}</title>
        </Helmet>

        <CrudForm type={type} node={node} {...crudFormProps} onSuccess={redirectOnInsert && !node ? this.redirect : null}>
          <Editor {...editorProps} nodeId={nodeId} where={where} />
          <div className={classes.actions}>
            <DeleteButton />
            <div style={{ width: 0, height: 0 }} />
            <SaveButton />
          </div>
          <MutationSnackbars />
        </CrudForm>
      </div>
    );
  }

  render() {
    const { type, nodeId, where, match: { params, path }, renderTitle, onTitleChange } = this.props;
    const id = nodeId || params.id;

    if (!where && !nodeId && (path.split('/').pop() === 'insert')) {
      return this.renderNode(null);
    }

    if (!where && !id) {
      const title = renderTitle(null);
      if (title !== this.title) {
        setTimeout(() => onTitleChange(title), 1);
      }
      this.title = title;
      return null;
    }

    return (
      <OneNode type={type} id={id} where={where} renderNode={this.renderNode} renderEmpty={this.renderNode} />
    );
  }
}

CrudPageForm = compose(
  withStyles(styles),
  withRouter,
)(CrudPageForm);

export default CrudPageForm;
