import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withApollo, compose } from 'react-apollo';
import _ from 'lodash';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

import NodeSelector from '../NodeSelector';
import { NodeListContext } from '.';

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
});

class NodeFilter extends Component {
  static contextType = NodeListContext

  static propTypes = {
    client: PropTypes.object.isRequired,
    label: PropTypes.string,
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    path: PropTypes.string.isRequired,
  }

  static defaultProps = {
    label: 'Is one of these:',
    className: '',
  }

  constructor(props, context) {
    super(props, context);

    const { type, schema: { getSchemaAtPath } } = context;
    const { path } = this.props;
    this.relatedSchema = getSchemaAtPath(`${type}.${path}`);
    this.type = this.relatedSchema.name;
  }

  handleChange = (value) => {
    value = value.map(node => node.id);
    if (!value.length) value = undefined;

    this.change(value);
  }

  change = (value) => {
    const { setVariables } = this.context;
    const { path } = this.props;

    setVariables(({ where }) => {
      if (value) {
        _.set(where, `${path}.id._in`, value);
      } else {
        _.set(where, `${path}`, undefined);
      }
      return { where };
    });
  }

  clear = (event) => {
    this.change(undefined);
  }

  render() {
    const { variables: { where } } = this.context;
    const { client, classes, className, path, label } = this.props;
    const valueIds = _.get(where, `${path}.id._in`, undefined);
    const value = valueIds ? valueIds.map(id => client.cache.data.get(`${this.type}:${id}`)) : undefined;

    return (
      <div className={classNames(classes.root, className)}>
        <NodeSelector value={value} type={this.type} isMulti onChange={this.handleChange} label={label} />
      </div>
    );
  }
}

NodeFilter = compose(
  withStyles(styles),
  withApollo,
)(NodeFilter);
export default NodeFilter;
