export default {
  types: {
    user_invitation: {

      renderers: {
        inline: node => `Invite for role "${node.role.label}"`,
      },

      selections: {
        default: 'id token role{id name label} usesRemaining expiresAt',
      },

      fields: {
        token: {
          label: 'Code',
          type: 'String',
        },

        role: {
          type: 'role',
        },

        usesRemaining: {
          type: 'Int',
        },

        expiresAt: {
          type: 'Timestamp',
        },

      },
    },
  },
};
