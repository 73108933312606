const transformNodeValueToFormValue = ({ nodeValue, node, fieldName, type, schema }) => {
  const field = schema.types[type].fields[fieldName];

  // All scaler fields
  if (field.isScaler) {
    if (field.type === 'String') {
      return nodeValue || '';
    }
    return nodeValue;
  }

  // Many relationships
  if (field.hasMany) {
    return nodeValue.map(v => v[field.type]);
  }

  // One relationships
  return nodeValue;
};

const transformRelationFormValueToMuationVar = ({ formValue, fieldName, node, type, schema }) => {
  const schemaField = schema.types[type].fields[fieldName];
  // const relatedSchemaType = schema.types[schemaField.type];

  // Connections to many other objects
  if (schemaField.hasMany) {
    const nvKey = schemaField.type;

    const formValues = Array.isArray(formValue) ? formValue : [];
    const formValuesNotInNodeValues = node && node[fieldName] ? formValues.filter(fv => !node[fieldName].find(nv => nv[nvKey].id === fv.id)) : formValues;
    const nodeValuesNotInFormValues = node && node[fieldName] ? node[fieldName].filter(nv => !formValues.find(fv => fv.id === nv[nvKey].id)) : [];
    const connect = formValuesNotInNodeValues.map(v => v);
    const disconnect = nodeValuesNotInFormValues.map(v => v);
    const isNew = !(node && node.id);

    return { connect, disconnect: !isNew ? disconnect : undefined };
  }

  // Connection to one other object
  if (formValue) {
    const id = typeof formValue === 'object' ? formValue.id : formValue;
    return { connect: { id } };
  } if (node && node[fieldName]) {
    return { disconnect: true };
  }
  return undefined;
};

const transformFormValueToMutationVar = (arg) => {
  const { formValue, fieldName, node, type, schema } = arg;
  const field = schema.types[type].fields[fieldName];

  switch (field.type) {
    case 'Int':
    case 'Float':
      return Number(formValue);

    case 'ID':
    case 'String':
    case 'Timestamp':
    case 'Boolean':
    case 'Json':
      return formValue;

    // All other types, should be non-scalar here
    default:
      return transformRelationFormValueToMuationVar(arg);
  }
};

export { transformNodeValueToFormValue, transformFormValueToMutationVar };
