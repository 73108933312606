const staircaseSetSelections = `
  id label config
  staircases { staircase{
    id label config
    images { file{
      id name url
    }}
  }}
`;

const sessionSelections = `
  id createdAt updatedAt state status
  responses(order_by: {createdAt: asc}, limit: 5000){
    id updatedAt data staircaseId
  }
  staircaseSet{
    ${staircaseSetSelections}
  }
  user{
    id
    displayName
    email
    participantId,
  }
`;

export default {
  types: {
    training_session: {

      renderers: {
        inline: node => `${node.staircaseSet.label} - ${node.user.displayName}`,
      },

      selections: {
        default: sessionSelections,
        table: `id status user{id displayName} staircaseSet{id label} updatedAt createdAt`,
      },

      fields: {
        status: {
          type: 'String',
        },

        state: {
          type: 'Json',
        },

        responses: {
          type: 'response',
          hasMany: true,
        },

        staircaseSet: {
          type: 'staircase_set',
        },

        user: {
          type: 'user',
        },

      },
    },
  },
};
