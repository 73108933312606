/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { compose, withApollo } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Fade from '@material-ui/core/Fade';
import OkIcon from 'mdi-material-ui/Check';
import IncorrectIcon from 'mdi-material-ui/Close';
import _ from 'lodash';
import classNames from 'classnames';
import gql from 'graphql-tag';
import ReloadIcon from 'mdi-material-ui/History';
import shortid from 'shortid';

import { pickRandomElement, getRandomIntInclusive, getLevelFaces, getMaxLevel } from '/utils';
import { withThemeManager, MuiThemeProvider } from '/../../kiska/src/components/contexts/ThemeManagerContext';
import { withCurrentUser } from '/../../kiska/src/components/contexts/CurrentUserContext';
import { AppSettingsContext } from '/../../kiska/src/components/contexts/AppSettingsContext';
import SessionDebugBar from './SessionDebugBar';
import SessionCharts from './SessionCharts';

let transitionCoeff = 1;
const firstTrialDelay = 2000;
const feedbackDuration = 500;

const styles = theme => ({
  root: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 10,
  },
  body: {
    display: 'flex',
    backgroundColor: '#FFF',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    flex: 1,
    position: 'relative',
  },
  instructions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing.unit * 3,
    height: '100vh',
    position: 'absolute',
  },
  complete: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing.unit * 3,
    height: '100vh',
    position: 'absolute',
  },
  saveError: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing.unit * 3,
    height: '100vh',
    position: 'absolute',
  },
  debugBar: {
    width: 300,
  },
  faces: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    width: '100%',
    position: 'absolute',
  },
  options: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'stretch',
    flex: 1,
    width: '100%',
  },
  faceWrapper: {
    position: 'relative',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '50%',
    // padding: 16,
  },
  optionWrapper: {

  },
  keyWrapper: {

  },
  faceDebug: {
    paddingTop: 8,
  },
  incorrectDebug: {
    borderBottom: 'solid 2px #C00',
  },
  correctDebug: {
    borderBottom: 'solid 2px #0C0',
  },
  face: {
    flex: 1,
    backgroundSize: 'contain',
    width: '100%',
    backgroundRepeat: 'no-repeat',
  },
  keyFace: {
    backgroundPosition: '50% 100%',
  },
  optionFace: {
    backgroundPosition: '50% 0%',
  },
  incorrectFeedback: {
    position: 'absolute',
    top: 'calc(50% - 100px)',
    left: 'calc(50% - 100px)',
    fontSize: 200,
    color: '#C00',
  },
  correctFeedback: {
    position: 'absolute',
    top: 'calc(50% - 100px)',
    left: 'calc(50% - 100px)',
    fontSize: 200,
    color: '#0C0',
  },
  squisher: {
    flex: 0.5,
  },
});

const saveSessionMutation = gql`
  mutation SaveSession($id: String!, $data: training_session_set_input!){
    updateSession: update_training_session(
      where: {id: {_eq: $id}},
      _set: $data
    ){
      returning{id}
      affected_rows
    }
  }
`;

const saveResponseMutation = gql`
  mutation InsertResponse($objects: [response_insert_input!]!){
    session: insert_response(objects: $objects){
      returning{
       id
      }
    }
  }
`;

const removeSetFromAssignmentMutation = gql`
  mutation RemoveSetFromAssignment($id: String!, $data: jsonb!){
    updateAssignment: update_set_assignment(
      where: {id: {_eq: $id}},
      _set: {data: $data}
    ){
      returning{id}
      affected_rows
    }
  }
`;

const normalizeKeyEvent = (event) => {
  let side = '';
  if (event.location === 1) side = 'Left';
  if (event.location === 2) side = 'Right';

  let key = '';
  switch (event.which) {
    case 16: key = 'Shift'; break;
    case 17: key = 'Control'; break;
    case 37: key = 'ArrowLeft'; break;
    case 39: key = 'ArrowRight'; break;
    case 65: key = 'KeyA'; break;
    case 81: key = 'KeyQ'; break;
    case 76: key = 'KeyL'; break;
    case 80: key = 'KeyP'; break;
    case 70: key = 'KeyF'; break;
    case 74: key = 'KeyJ'; break;
    default:
  }

  const code = key ? `${key}${side}` : null;
  return code;
};

class SessionRunner extends Component {
  static contextType = AppSettingsContext;

  static propTypes = {
    classes: PropTypes.object.isRequired,
    session: PropTypes.object.isRequired,
    themeManager: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    client: PropTypes.object.isRequired,
  }

  static defaultProps = {
  }

  constructor(props, context) {
    super(props);

    this.stageTimeoutIds = [];

    if (typeof window !== 'undefined') {
      document.addEventListener('keydown', this.handleDocumentKeyPress);
      // document.addEventListener('keyup', this.handleDocumentKeyUp);
    }

    this.state = {
      stage: 'instructions',
      // stage: 'complete',
      keyFaceTimedOut: false,
      optionFaceTimedOut: false,
      allowChoice: false,
      feedback: null,
      trialDisplaying: false,
      latestResponse: {
        responseTime: 0,
        result: 'none',
        staircase: null,
        level: 0,
      },
      savingTrialResponse: false,
      savingSessionState: false,
      chartTab: -1,
      saveErrorDisplaying: false,
    };

    console.log('Settings: ', context);

    // setTimeout(this.removeSetFromAssignment, 3000);
  }

  componentWillUnmount = () => {
    // document.removeEventListener(this.handleDocumentKeyPress);
    // document.removeEventListener(this.handleDocumentKeyUp);
    this.clearAllStageTimeouts();
  }

  handleDocumentKeyPress = (event) => {
    if (!this.state.allowChoice) return;
    const { session: { json: settings } } = this.context;
    const code = normalizeKeyEvent(event);

    if (code === settings.chooseLeftKey) this.chooseFace('left');
    if (code === settings.chooseRightKey) this.chooseFace('right');
  }

  // handleDocumentKeyUp = (event) => {
  //   if (!this.state.allowChoice) return;
  //   const { session: { json: settings } } = this.context;
  //   const code = normalizeKeyEvent(event);
  //   if ((code === settings.chooseLeftKey) || (code === settings.chooseRightKey)) {
  //     console.log('Released Face!');
  //   }
  // }

  getFaceSet = () => {
    const staircase = this.currentStaircase();
    const staircaseState = this.getStaircaseState(staircase);

    const { keys, options } = getLevelFaces(staircase, staircaseState.level);
    const keyIndex = getRandomIntInclusive(0, 1);
    const leftIndex = getRandomIntInclusive(0, 1);
    const rightIndex = leftIndex === 0 ? 1 : 0;
    const correctOption = leftIndex === keyIndex ? 'left' : 'right';
    const incorrectOption = leftIndex !== keyIndex ? 'left' : 'right';
    const keyImage = keys[keyIndex];
    const optionImages = {
      left: options[leftIndex],
      right: options[rightIndex],
    };
    optionImages.correct = optionImages[correctOption];
    optionImages.incorrect = optionImages[incorrectOption];

    const optionSizes = staircase.config.optionSizes ? staircase.config.optionSizes.split(',').map(n => Number(n)) : [0];
    let optionSize = pickRandomElement(optionSizes);
    const maxSize = Math.max(...optionSizes);
    optionSize = 100 * (optionSize + 100) / (maxSize + 100);
    const keySize = 100 * (0 + 100) / (maxSize + 100);

    return { key: keyImage, options: optionImages, correctOption, incorrectOption, optionSize, keySize };
  }

  handleClickFace = (which) => {
    if (!this.state.allowChoice) return;
    // const { session: { staircaseSet: { config } } } = this.props;
    const { session: { json: settings } } = this.context;
    if (settings.clickToChoose) {
      this.chooseFace(which);
    }
  }

  buildLevelState = level => ({
    level,
    successfullAttempts: 0,
    failedAttempts: 0,
    positiveInflections: 0,
    negativeInflections: 0,
    reversals: 0,
  })

  chooseFace = (which) => {
    const { faceSet } = this.state;
    const { session, currentUser: { user } } = this.props;
    const staircase = this.currentStaircase();
    const state = this.getStaircaseState(staircase);
    const config = staircase.config;
    const attempt = state.attempt;
    const stateLevel = state.levels[state.level];
    const maxLevel = getMaxLevel(staircase);

    this.lastFaceChosen = which;

    let result;
    if (which === 'timeout') result = 'timeout';
    else if (which === this.state.faceSet.correctOption) result = 'correct';
    else result = 'incorrect';

    const responseTime = Date.now() - this.trialStartTime;

    if (state.countdownThresholdReached) {
      state.postCountdownTrials += 1;
      if (state.postCountdownTrials >= config.postReversalThresholdTrialsCount) {
        state.status = 'complete';
      }
    }

    // Set charts tab to this staircase
    session.staircaseSet.staircases.forEach((s, i) => {
      if (s.staircase.id === staircase.id) this.setState({ chartTab: i });
    });

    const now = new Date();
    const response = {
      staircaseId: staircase.id,
      sessionId: session.id,
      userId: user.id,
      data: {
        responseTime,
        result,
        level: state.level,
        faceSet,
      },
      createdAt: now,
      updatedAt: now,
    };

    let promote = false;
    let demote = false;

    // Add to correct/incorrect/timeout totals and consecs
    // and decide if we should promote or demote or neigther
    if (result === 'correct') {
      attempt.totalCorrect += 1;
      attempt.consecutiveIncorrect = 0;
      attempt.consecutiveTimeout = 0;

      if (attempt.previousResult === 'correct') {
        attempt.consecutiveCorrect += 1;
      } else {
        attempt.consecutiveCorrect = 1;
      }

      if (config.promotionMode === 'total') {
        if (attempt.totalCorrect >= config.promotionThreshold) {
          promote = true;
        }
      } else if (config.promotionMode === 'consecutive') {
        if (attempt.consecutiveCorrect >= config.promotionThreshold) {
          promote = true;
        }
      }
    } else if (result === 'incorrect') {
      attempt.totalIncorrect += 1;
      attempt.consecutiveCorrect = 0;
      attempt.consecutiveTimeout = 0;

      if (attempt.previousResult === 'incorrect') {
        attempt.consecutiveIncorrect += 1;
      } else {
        attempt.consecutiveIncorrect = 1;
      }

      if (config.demotionMode === 'total') {
        if (attempt.totalIncorrect >= config.demotionThreshold) {
          demote = true;
        }
      } else if (config.demotionMode === 'consecutive') {
        if (attempt.consecutiveIncorrect >= config.demotionThreshold) {
          demote = true;
        }
      }
    } else if (result === 'timeout') {
      attempt.totalTimeouts += 1;
      attempt.consecutiveCorrect = 0;
      attempt.consecutiveIncorrect = 0;

      if (attempt.previousResult === 'timeout') {
        attempt.consecutiveTimeouts += 1;
      } else {
        attempt.consecutiveTimeouts = 1;
      }
    }
    attempt.previousResult = result;

    // Handle promotion or demotion
    if (promote) {
      stateLevel.successfullAttempts += 1;
      if (state.level === maxLevel) {
        state.countdownThresholdReached = true;
      } else {
        console.log(`Promoting ${config.promotionSteps} step from Level ${state.level} to Level ${Math.min(state.level + config.promotionSteps, maxLevel)}`);
        state.level = Math.min(Number(state.level) + Number(config.promotionSteps), maxLevel);
      }

      if (state.previousMotion === 'demote') {
        stateLevel.positiveInflections += 1;
        state.positiveInflections += 1;
        state.inflections += 1;
      }
      state.previousMotion = 'promote';
    } else if (demote) {
      // Increment reversals count for all level between here and where we're demoting too
      for (let i = 0; i <= config.demotionSteps; i += 1) {
        if (state.level - i < 0) break;

        if (!state.levels[state.level - i]) {
          state.levels[state.level - i] = this.buildLevelState(state.level - i);
        }
        state.levels[state.level - i].reversals += 1;
      }

      stateLevel.failedAttempts += 1;
      state.level = Math.max(Number(state.level) - Number(config.demotionSteps), 0);

      if (state.previousMotion === 'promote') {
        stateLevel.negativeInflections += 1;
        state.negativeInflections += 1;
        state.inflections += 1;
      }
      state.previousMotion = 'demote';
    }

    // Make a new level state if we haven't been here before
    if (!state.levels[state.level]) {
      state.levels[state.level] = this.buildLevelState(state.level);
    }

    // Reset the attemt if we're leaving this level
    if (promote || demote) {
      state.attempt = {
        previousResult: 'none',
        totalCorrect: 0,
        consecutiveCorrect: 0,
        totalIncorrect: 0,
        consecutiveIncorrect: 0,
        totalTimeouts: 0,
        consecutiveTimeouts: 0,
      };
    }

    // Calculate inflection averages
    state.positiveInflectionAvg = 0;
    state.negativeInflectionAvg = 0;
    state.inflectionAvg = 0;
    state.levels.forEach((l) => {
      state.positiveInflectionAvg += l.level * l.positiveInflections;
      state.negativeInflectionAvg += l.level * l.negativeInflections;
      state.inflectionAvg += l.level * (l.negativeInflections + l.positiveInflections);
    });
    if (state.positiveInflections) state.positiveInflectionAvg /= state.positiveInflections;
    if (state.negativeInflections) state.negativeInflectionAvg /= state.negativeInflections;
    if (state.inflections) state.inflectionAvg /= state.inflections;

    // Calculate reversals max count and average reversal level
    state.avgReversalLevel = 0;
    state.unweightedAvgReversalLevel = 0;
    state.maxReversals = 0;
    state.totalReversals = 0;
    state.totalReversalLevels = 0;

    state.levels.forEach((l) => {
      state.avgReversalLevel += l.level * l.reversals;
      state.totalReversals += l.reversals;
      if (l.reversals) {
        state.unweightedAvgReversalLevel += l.level;
        state.totalReversalLevels += 1;
      }
      if (l.reversals >= state.maxReversals) {
        state.maxReversals = l.reversals;
        state.maxReversalLevel = l.level;
      }
    });
    if (state.totalReversals) state.avgReversalLevel /= state.totalReversals;
    if (state.totalReversalLevels) state.unweightedAvgReversalLevel /= state.totalReversalLevels;

    // Detect if we've reached the reversal threshold and start countdown if so
    if (state.maxReversals >= config.postReversalThresholdTrialsCount) {
      if (!state.countdownThresholdReached) {
        response.data.countdownThresholdReached = true;
      }
      state.countdownThresholdReached = true;
    }

    // Add reversal count to response for convenience
    response.data.reversals = stateLevel.reversals;

    session.responses.push(response);

    this.setState({
      feedback: result,
      latestResponse: response,
    });

    this.saveSession();
    this.saveResponse(response);

    // Go to the next staircase
    // First check if all staircases are complete
    const allDone = !_.find(session.state.staircases, s => s.status !== 'complete');
    if (allDone && session.status !== 'complete') {
      session.status = 'complete';
      this.saveSession(() => {
        this.setStage('complete');
      });
      this.removeSetFromAssignment();
    } else {
      do {
        session.state.sequenceIndex += 1;
        if (session.state.sequenceIndex >= session.state.sequence.length) {
          session.state.sequenceIndex = 0;
        }
      } while (this.getStaircaseState(this.currentStaircase()).status === 'complete');
      this.setStage('feedback-displaying');
    }
  }

  handleSaveError = (error) => {
    console.error('Error saving: ', error);
    this.setStage('save-error');
  }

  saveSession = (onSuccessCb) => {
    const { client, session } = this.props;

    this.setState({ savingSessionState: true });

    client.mutate({
      mutation: saveSessionMutation,
      variables: {
        id: session.id,
        data: {
          state: session.state,
          status: session.status,
          updatedAt: new Date(),
        },
      },
    })
      .then(({ data, error }) => {
        if (error) { this.handleSaveError(error); return; }
        if (onSuccessCb) onSuccessCb();
      })
      .catch(error => this.handleSaveError(error))
      .finally(() => {
        this.setState({ savingSessionState: false });
      });
  }

  saveResponse = (response) => {
    const { client } = this.props;

    this.setState({ savingTrialResponse: true });

    client.mutate({
      mutation: saveResponseMutation,
      variables: {
        objects: [{
          id: shortid.generate(),
          ...response,
        }],
      },
    })
      .then(({ data, error }) => {
        if (error) { this.handleSaveError(error); }
      })
      .catch(error => this.handleSaveError(error))
      .finally(() => {
        this.setState({ savingTrialResponse: false });
      });
  }

  removeSetFromAssignment = () => {
    const { session, currentUser: { user }, client } = this.props;
    const set = session.staircaseSet;

    const assignment = user.setAssignment;
    if (!assignment) return;
    let setIndex;
    assignment.data.sets.forEach((s, i) => {
      if (!s) return;
      if (s.id === set.id && setIndex === undefined) {
        setIndex = i;
      }
    });
    if (setIndex === undefined) return;

    assignment.data.sets.splice(setIndex, 1);

    client.mutate({
      mutation: removeSetFromAssignmentMutation,
      variables: {
        id: assignment.id,
        data: assignment.data,
      },
    })
      .then(({ data, error }) => {
        if (error) { this.handleSaveError(error); }
      })
      .catch(error => this.handleSaveError(error))
      .finally(() => {
        this.setState({ savingTrialResponse: false });
      });
  }

  getStaircaseById = (id) => {
    if (!id) {
      console.error('Tried to get staircase with blank id.');
      return null;
    }
    const { session } = this.props;
    const staircase = _.find(session.staircaseSet.staircases, s => s.staircase.id === id).staircase;
    return staircase;
  }

  getStaircaseState = (staircase) => {
    const { session } = this.props;
    return session.state.staircases[staircase.id];
  }

  getStaircaseAtIndex = (index) => {
    const { session } = this.props;
    const state = session.state;
    const staircaseId = state.sequenceKeys[state.sequence[index]];
    const staircase = this.getStaircaseById(staircaseId);
    return staircase;
  }

  currentStaircase = () => {
    const { session } = this.props;
    const staircase = this.getStaircaseAtIndex(session.state.sequenceIndex);
    return staircase;
  }

  clearAllStageTimeouts = () => {
    _.forEach(this.stageTimeoutIds, (id) => {
      clearTimeout(id);
    });
    this.stageTimeoutIds = {};
  }

  addStageTimeoutId = (name, id) => {
    if (this.stageTimeoutIds[name]) {
      console.error(`########## Trying to add stage timeout "${name}" when it has not first been cleared! ##########`);
    }

    this.stageTimeoutIds[name] = id;
  }

  setStageTimeout = (name, nextStage, timeout, stateChange) => {
    if (!name) name = nextStage;

    const id = setTimeout(() => {
      if (nextStage) this.setStage(nextStage);
      if (stateChange) this.setState(stateChange);
    }, timeout * transitionCoeff);
    this.addStageTimeoutId(name, id);
  }

  reshuffleFaceSet = () => {
    this.setState({
      faceSet: this.getFaceSet(),
    });
  }

  setStage = (stage) => {
    const { session } = this.props;
    const staircaseSet = session.staircaseSet;
    const prevStage = this.state.stage;
    const staircase = this.currentStaircase();

    if (stage === prevStage) {
      console.error(`Attempted to set stage to "${stage}" but is already is!`);
      return;
    }

    this.clearAllStageTimeouts();

    switch (stage) {
      case 'first-trial-delay':
        this.setStageTimeout(null, 'between-trial-delay', firstTrialDelay);
        break;
      case 'between-trial-delay':
        this.setState({
          trialDisplaying: false,
          allowChoice: false,
        });
        this.setStageTimeout(null, 'trial-displaying', staircaseSet.config.betweenTrialsDelay);
        break;
      case 'trial-displaying':
        this.setState({
          trialDisplaying: true,
          optionFaceTimedOut: false,
          keyFaceTimedOut: false,
          selectionFeedbackIn: false,
          feedbackIn: false,
          faceSet: this.getFaceSet(),
          allowChoice: true,
          feedbackDisplaying: false,
        });
        this.trialStartTime = Date.now();
        if (!staircase.config.neverResponseTimeout) {
          this.setStageTimeout(null, 'response-timeout', staircase.config.responseTimeout);
        }
        if (!staircase.config.neverOptionFaceTimeout) {
          this.setStageTimeout('option-face-timeout', null, staircase.config.optionFaceTimeout, { optionFaceTimedOut: true });
        }
        if (!staircase.config.neverKeyFaceTimeout) {
          this.setStageTimeout('key-face-timeout', null, staircase.config.keyFaceTimeout, { keyFaceTimedOut: true });
        }
        break;
      case 'response-timeout':
        this.chooseFace('timeout');
        break;
      case 'feedback-displaying':
        this.setStageTimeout(null, 'between-trial-delay', feedbackDuration);
        this.setState({
          allowChoice: false,
          feedbackDisplaying: true,
        });
        this.setStageTimeout('feedback-timeout', null, feedbackDuration, { feedbackDisplaying: false });
        break;
      case 'complete':
        this.setState({
          trialDisplaying: false,
          allowChoice: false,
        });
        break;
      case 'save-error':
        this.setState({
          trialDisplaying: false,
          allowChoice: false,
          saveErrorDisplaying: true,
        });
        break;
      default:
        console.error(`Unknown stage "${stage}"!`);
    }

    this.setState({ stage });
  }

  renderInstructions = () => {
    const { classes } = this.props;

    return (
      <div className={classes.instructions}>
        <Typography variant="h4" align="center" gutterBottom>
          Instructions
        </Typography>
        <Typography variant="body1">
          Press some buttons, make some magic. Lorem ipsum.
        </Typography>
        <div style={{ alignSelf: 'flex-end', marginTop: 32 }}>
          <Button variant="contained" color="primary" onClick={() => this.setStage('first-trial-delay')}>
            <OkIcon /><span>Ok, I'm ready!</span>
          </Button>
        </div>
      </div>
    );
  }

  renderComplete = () => {
    const { classes } = this.props;

    return (
      <div className={classes.complete}>
        <Typography variant="h4" align="center" gutterBottom>
          You're Done!
        </Typography>
        <Typography variant="body1">
          You have completed this training session. Please continue having a pleasant day. Thank meaow.
        </Typography>
        <div style={{ alignSelf: 'flex-end', marginTop: 32 }}>
          <Button variant="contained" color="primary" component={RouterLink} to="/">
            <OkIcon /><span>Ok</span>
          </Button>
        </div>
      </div>
    );
  }

  renderSaveError = () => {
    const { classes } = this.props;

    return (
      <div className={classes.saveError}>
        <Typography variant="h4" align="center" gutterBottom>
          Network Error
        </Typography>
        <Typography variant="body1">
          There seems to be a problem saving your session. This could be  network/wifi connectivity issue.
          Please reload this page to start the session again.
        </Typography>
        <div style={{ alignSelf: 'flex-end', marginTop: 32 }}>
          <Button variant="contained" color="primary" onClick={() => window.location.reload()}>
            <ReloadIcon /><span>Reload Now</span>
          </Button>
        </div>
      </div>
    );
  }

  runRobot = () => {
    transitionCoeff = 0;

    setInterval(() => {
      const { faceSet, allowChoice } = this.state;
      if (!allowChoice) return;
      const dice = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
      const incorrect = pickRandomElement(dice) === 5;
      if (incorrect) this.chooseFace(faceSet.incorrectOption);
      else this.chooseFace(faceSet.correctOption);
    }, 500);
  }

  render() {
    const { stage, keyFaceTimedOut, faceSet, optionFaceTimedOut, saveErrorDisplaying, savingSessionState, savingTrialResponse, latestResponse, feedbackDisplaying, feedback, trialDisplaying, chartTab } = this.state;
    const { classes, themeManager: { inverseTheme }, session, currentUser: { user } } = this.props;
    const staircase = this.currentStaircase();
    const config = staircase.config;

    const timeout = {
      enter: config.fadeInDuration * transitionCoeff,
      exit: config.fadeInDuration * transitionCoeff,
    };

    let leftFeedbackIn = false;
    let rightFeedbackIn = false;
    if (feedbackDisplaying) {
      if (this.lastFaceChosen === 'left') {
        if (feedback === 'correct' && config.correctFeedback && faceSet.correctOption === 'left') leftFeedbackIn = true;
        if (feedback === 'incorrect' && config.incorrectFeedback && faceSet.correctOption !== 'left') leftFeedbackIn = true;
      } else if (this.lastFaceChosen === 'right') {
        if (feedback === 'correct' && config.correctFeedback && faceSet.correctOption === 'right') rightFeedbackIn = true;
        if (feedback === 'incorrect' && config.incorrectFeedback && faceSet.correctOption !== 'right') rightFeedbackIn = true;
      }
    }

    return (
      <div className={classes.root}>
        {user.isAdmin ? (
          <MuiThemeProvider theme={inverseTheme}>
            <SessionDebugBar
              savingSessionState={savingSessionState}
              savingTrialResponse={savingTrialResponse}
              session={session}
              stage={stage}
              className={classes.debugBar}
              latestResponse={latestResponse}
              onReshuffleFaceSet={this.reshuffleFaceSet}
              onRunRobot={this.runRobot}
            />
            <SessionCharts session={session} tab={chartTab} onTabChange={tab => this.setState({ chartTab: tab })} />
          </MuiThemeProvider>
        ) : null}

        <div className={classes.body}>
          <Fade in={stage === 'instructions'} timeout={firstTrialDelay * transitionCoeff} unmountOnExit>
            {this.renderInstructions()}
          </Fade>
          <Fade in={stage === 'complete'} timeout={3000 * transitionCoeff} unmountOnExit>
            {this.renderComplete()}
          </Fade>
          <Fade in={saveErrorDisplaying} timeout={1000 * transitionCoeff} unmountOnExit>
            {this.renderSaveError()}
          </Fade>

          {faceSet ? (
            <Fade in={trialDisplaying} timeout={timeout} unmountOnExit>
              <div className={classes.faces}>
                <Fade in={!keyFaceTimedOut} timeout={timeout}>
                  <div className={classNames(classes.faceWrapper, classes.keyWrapper)}>
                    <div style={{ backgroundImage: `url("${faceSet.key.url}")`, width: `${faceSet.keySize}%` }} className={classNames(classes.keyFace, classes.face)} />
                    {user.isAdmin ? (
                      <div className={classes.faceDebug}>
                        <Typography align="center" color="textSecondary">
                          {faceSet.key.name}
                        </Typography>
                      </div>
                    ) : null}
                  </div>
                </Fade>
                <div className={classes.options}>
                  <Fade in={!optionFaceTimedOut} timeout={timeout}>
                    <div className={classNames(classes.faceWrapper, classes.optionWrapper)}>
                      <div style={{ backgroundImage: `url("${faceSet.options.left.url}")`, width: `${faceSet.optionSize}%` }} className={classNames(classes.leftFace, classes.face, classes.optionFace)} onClick={() => this.handleClickFace('left')} />
                      <Fade in={leftFeedbackIn} timeout={300 * transitionCoeff} unmountOnExit>
                        {feedback === 'correct' ? <OkIcon className={classes.correctFeedback} /> : <IncorrectIcon className={classes.incorrectFeedback} />}
                      </Fade>
                      {user.isAdmin ? (
                        <div className={classNames(classes.faceDebug, { [`${classes.correctDebug}`]: faceSet.correctOption === 'left', [`${classes.incorrectDebug}`]: faceSet.correctOption !== 'left' })}>
                          <Typography align="center" color="textSecondary">
                            {faceSet.options.left.name}&nbsp;
                          </Typography>
                        </div>
                      ) : null}
                    </div>
                  </Fade>
                  <Fade in={!optionFaceTimedOut} timeout={timeout}>
                    <div className={classNames(classes.faceWrapper, classes.optionWrapper)}>
                      <div style={{ backgroundImage: `url("${faceSet.options.right.url}"`, width: `${faceSet.optionSize}%` }} className={classNames(classes.rightFace, classes.face, classes.optionFace)} onClick={() => this.handleClickFace('right')} />
                      <Fade in={rightFeedbackIn} timeout={300 * transitionCoeff} unmountOnExit>
                        {feedback === 'correct' ? <OkIcon className={classes.correctFeedback} /> : <IncorrectIcon className={classes.incorrectFeedback} />}
                      </Fade>
                      {user.isAdmin ? (
                        <div className={classNames(classes.faceDebug, { [`${classes.correctDebug}`]: faceSet.correctOption === 'right', [`${classes.incorrectDebug}`]: faceSet.correctOption !== 'right' })}>
                          <Typography align="center" color="textSecondary">
                            {faceSet.options.right.name}&nbsp;
                          </Typography>
                        </div>
                      ) : null}
                    </div>
                  </Fade>
                </div>

              </div>
            </Fade>
          ) : null}

        </div>

      </div>
    );
  }
}

SessionRunner = compose(
  withStyles(styles),
  withThemeManager,
  withCurrentUser,
  withApollo,
)(SessionRunner);
SessionRunner.displayName = 'SessionRunner';
export default SessionRunner;
