import gql from 'graphql-tag';
import _ from 'lodash';
import shortid from 'shortid';

import { addIdsToSelections } from '../../utils';

const defaultValidationFailedMessage = (mutation, validationErrors) => 'There are errors in the data you have entered.';
const pickFieldValuesOnly = (values, schemaType) => _.pick(values, schemaType.allFields);

const getSelections = (mutationName, schemaType) => {
  const mutations = schemaType.selections.mutations;
  let selections = '';

  if (typeof mutations === 'string') {
    selections = mutations;
  } if (typeof mutations === 'object' && mutations[mutationName]) {
    selections = mutations[mutationName];
  } else {
    selections = schemaType.selections.default;
  }

  selections = addIdsToSelections(selections);

  return selections;
};

const transformOneToOneRelations = ({ type, schemaType, schema, object, node }) => {
  _.forEach(object, (value, field) => {
    const schemaField = schemaType.fields[field];
    if (schemaField.isScaler || schemaField.hasMany) return;

    if (value.connect) {
      object[`${field}Id`] = value.connect.id;
    } else {
      object[`${field}Id`] = null;
    }

    delete object[field];
  });
};

const getManyToManyRelationQueries = ({ type, schemaType, schema, object, node }) => {
  const relations = {
    queries: [],
    variables: {},
    variableDeclarations: [],
  };

  _.forEach(object, (value, field) => {
    if (!schemaType.fields[field].hasMany) return;

    value.connect = value.connect || [];
    value.disconnect = value.disconnect || [];

    const relatedSchemaType = schema.types[schemaType.fields[field].type];
    const types = [relatedSchemaType.name, type].sort();
    const table = `${types[0]}__x__${types[1]}`;

    const connectAlias = `connect${_.upperFirst(types[0])}to${_.upperFirst(types[1])}`;
    const connectVariableName = `${field}ConnectObjects`;
    const connectVariableDeclaration = `$${connectVariableName}: [${table}_insert_input!]!`;

    const connectQuery = `
      ${connectAlias}: insert_${table}(objects: $${connectVariableName}){
        returning{id}
      }
    `;

    const connectVariable = value.connect.map(({ id: relatedId }) => ({
      id: shortid.generate(),
      [`${type}Id`]: node ? node.id : object.id,
      [`${relatedSchemaType.name}Id`]: relatedId,
    }));

    const disconnectAlias = `disconnect${_.upperFirst(types[0])}to${_.upperFirst(types[1])}`;
    const disconnectVariableName = `${field}DisconnectWhere`;
    const disconnectVariableDeclaration = `$${disconnectVariableName}: ${table}_bool_exp!`;

    const disconnectQuery = `
      ${disconnectAlias}: delete_${table}(where: $${disconnectVariableName}){
        returning{id}
      }
    `;

    const disconnectVariable = {
      id: { _in: value.disconnect.map(({ id }) => id) },
    };

    relations.queries.push(connectQuery, disconnectQuery);
    relations.variables[connectVariableName] = connectVariable;
    relations.variables[disconnectVariableName] = disconnectVariable;
    relations.variableDeclarations.push(connectVariableDeclaration, disconnectVariableDeclaration);

    delete object[field];
  });

  return relations;
};

/** ******************* Insert  *************************** */
const insertMutationBuilderFactory = ({ modifyVars, selections }) => ({ fields, type, node, schema, values, valuesAsMutationVars }) => {
  const schemaType = schema.types[type];
  const typeLabel = schemaType.label;
  const pascalType = _.upperFirst(type);
  const alias = `insert${pascalType}`;
  const object = pickFieldValuesOnly(valuesAsMutationVars, schemaType);
  selections = selections || getSelections('insert', schemaType);

  object.id = shortid.generate();
  if (schemaType.fields.createdAt) object.createdAt = (new Date()).toISOString();
  if (schemaType.fields.updatedAt) object.updatedAt = (new Date()).toISOString();

  const relations = getManyToManyRelationQueries({ type, schemaType, schema, object, node });
  transformOneToOneRelations({ type, schemaType, schema, object, node });

  const query = gql`
    mutation Insert${pascalType}(
      $objects: [${type}_insert_input!]!
      ${relations.variableDeclarations.join(', \n')}
      ){

      ${alias}: insert_${type}(objects: $objects){
        returning {
          ${selections}
        }
      }

      ${relations.queries.join('\n\n')}

    }
  `;

  const variables = {
    objects: [object],
    ...relations.variables,
  };

  return {
    query,
    alias,
    variables,
    renderSuccessMessage: (mutation, result) => `Successfully created ${typeLabel}`,
    renderErrorMessage: (mutation, error) => `Error creating ${typeLabel}`,
    renderValidationFailedMessage: defaultValidationFailedMessage,
  };
};

/** ******************* Update  *************************** */
const updateMutationBuilderFactory = ({ modifyVars, selections }) => ({ fields, type, node, schema, values, valuesAsMutationVars }) => {
  const schemaType = schema.types[type];
  const typeLabel = schemaType.label;
  const pascalType = _.upperFirst(type);
  const alias = `update${pascalType}`;
  const object = pickFieldValuesOnly(valuesAsMutationVars, schemaType);
  selections = selections || getSelections('update', schemaType);

  const relations = getManyToManyRelationQueries({ type, schemaType, schema, object, node });
  transformOneToOneRelations({ type, schemaType, schema, object, node });

  if (schemaType.fields.updatedAt) object.updatedAt = (new Date()).toISOString();

  const query = gql`
    mutation Update${pascalType}(
      $where: ${type}_bool_exp!, 
      $set: ${type}_set_input, 
      ${relations.variableDeclarations.join(', \n')}
      ){

      ${relations.queries.join('\n\n')}
      
      ${alias}: update_${type}(where: $where, _set: $set){
        returning {
          ${selections}
        }
        affected_rows
      }
    }
  `;

  const variables = {
    set: object,
    where: { id: { _eq: node.id } },
    ...relations.variables,
  };

  return {
    query,
    alias,
    variables,
    renderSuccessMessage: (mutation, result) => `Successfully updated ${typeLabel}`,
    renderErrorMessage: (mutation, error) => `Error updating ${typeLabel}`,
    renderValidationFailedMessage: defaultValidationFailedMessage,
  };
};

/** ******************* Delete  *************************** */
const deleteMutationBuilderFactory = ({ modifyVars, selections }) => ({ fields, type, node, schema, values, valuesAsMutationVars }) => {
  const schemaType = schema.types[type];
  const typeLabel = schemaType.label;
  const pascalType = _.upperFirst(type);
  const alias = `delete${pascalType}`;
  selections = selections || getSelections('delete', schemaType);

  const query = gql`
    mutation Delete${pascalType}($where: ${type}_bool_exp!){
      ${alias}: delete_${type}(where: $where){
        returning {
          ${selections}
        }
        affected_rows
      }
    }
  `;

  const variables = {
    where: { id: { _eq: node.id } },
  };

  return {
    query,
    alias,
    variables,
    skipValidation: true,
    renderSuccessMessage: (mutation, result) => `Successfully deleted ${typeLabel}`,
    renderErrorMessage: (mutation, error) => `Error deleting ${typeLabel}`,
    renderValidationFailedMessage: defaultValidationFailedMessage,
  };
};

export { insertMutationBuilderFactory, updateMutationBuilderFactory, deleteMutationBuilderFactory };
