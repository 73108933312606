export default {
  types: {
    file: {

      selections: {
        default: 'id size mimeType name originalId alternateName url',
      },

      fields: {
        size: {
          type: 'Int',
        },

        mimeType: {
          type: 'String',
        },

        name: {
          type: 'String',
        },

        originalId: {
          type: 'String',
        },

        alternateName: {
          type: 'String',
        },

        url: {
          type: 'String',
        },

      },
    },
  },
};
