import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'react-apollo';
import Button from '@material-ui/core/Button';
import UploadIcon from 'mdi-material-ui/Upload';
import NoSsr from '@material-ui/core/NoSsr';
import Portal from '@material-ui/core/Portal';
import _ from 'lodash';

import { withSettings } from '../../../contexts/SettingsContext';

const isSsr = typeof window === 'undefined';

if (!isSsr) {
  require('@uppy/core/dist/style.css');
  require('@uppy/dashboard/dist/style.css');
  require('@uppy/url/dist/style.css');
  require('@uppy/webcam/dist/style.css');
}

const styles = (theme) => {
  const color = theme.palette.secondary;
  const activeColor = theme.palette.secondary;

  return {
    '@global': {
      '.uppy-Root': {
        zIndex: 5000,
      },
      'a.uppy-Dashboard-poweredBy': {
        display: 'none',
      },
    },
    dropzone: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      border: 'dashed 2px',
      borderColor: color.main,
      borderRadius: 10,
      backgroundColor: color[50],
      padding: theme.spacing.unit * 2,
    },
    dropzoneDragHover: {
      borderColor: activeColor.main,
      backgroundColor: color[300],
    },
    uploadIcon: {
      fontSize: 75,
      color: color.main,
    },
    button: {
      width: 180,
    },
  };
};

class FileUploader extends React.Component {
  static propTypes = {
    settings: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    multiple: PropTypes.bool,
    allowedFileTypes: PropTypes.array,
    restrictions: PropTypes.object,
    meta: PropTypes.object,
    isPublic: PropTypes.bool.isRequired,
    onUploadComplete: PropTypes.func,
    className: PropTypes.string,
  }

  static defaultProps = {
    multiple: true,
    allowedFileTypes: null,
    restrictions: {},
    meta: {},
    onUploadComplete: null,
    className: '',
  }

  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };

    if (!isSsr) {
      this.setupUppy(props);
    } else {
      this.DashboardModal = 'div';
    }
  }

  setupUppy = (props) => {
    const { settings, multiple, allowedFileTypes, restrictions } = props;
    const Uppy = require('@uppy/core');
    const Webcam = require('@uppy/webcam');
    const XHRUpload = require('@uppy/xhr-upload');

    this.DashboardModal = require('@uppy/react/lib/DashboardModal');

    this.uppy = Uppy({
      id: 'MyUppy',
      allowMultipleUploads: true,
      restrictions: {
        maxFileSize: 50 * 1024 * 1024,
        maxNumberOfFiles: multiple ? 50 : 1,
        allowedFileTypes,
        ...restrictions,
      },
      onBeforeUpload: this.handleBeforeUpload,
    });
    this.uppy.use(Webcam, { id: 'MyWebcam' });
    this.uppy.use(XHRUpload, {
      id: 'MyXHRUpload',
      endpoint: `${settings.kiskaApiEndpoint}/uploads/xhr-upload`,
      withCredentials: true,
      headers: {
        'x-requested-with': 'XmlHttpRequest',
      },
    });

    this.uppy.on('complete', this.handleComplete);
  }

  handleBeforeUpload = (files) => {
    const { meta, isPublic } = this.props;
    _.forEach(files, file => this.uppy.setFileMeta(file.id, { ...meta, isPublic }));
    return files;
  }

  handleComplete = ({ failed, successful }) => {
    const { onUploadComplete } = this.props;
    console.log('successful files:', successful);
    console.log('failed files:', failed);

    const files = successful.map(f => f.response.body.dbFile);
    if (onUploadComplete && files.length) {
      onUploadComplete(files);
    }

    if (!failed.length) {
      this.handleClose();
    }
  }

  componentWillUnmount() {
    this.uppy.close();
  }

  handleUploadClick = () => {
    this.setState({ open: true });
  }

  handleClose = () => {
    this.setState({ open: false });
  }

  render() {
    const { open } = this.state;
    const { classes, className } = this.props;

    return (
      <>
        <Button variant="contained" color="primary" onClick={this.handleUploadClick} className={classNames(classes.button, className)}>
          <UploadIcon /><span>Upload Files</span>
        </Button>
        <NoSsr>
          <Portal>
            <this.DashboardModal
              open={open}
              uppy={this.uppy}
              onRequestClose={this.handleClose}
              plugins={['MyWebcam', 'MyXHRUpload']}
            />
          </Portal>
        </NoSsr>
      </>
    );
  }
}

FileUploader = compose(
  withSettings,
  withStyles(styles),
)(FileUploader);

export default FileUploader;
