import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'react-apollo';
import { withThemeManager, MuiThemeProvider } from '/../../kiska/src/components/contexts/ThemeManagerContext'; // eslint-disable-line import/no-extraneous-dependencies
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router';
import { Redirect } from 'react-router-dom';

import { withCurrentUser } from '/../../kiska/src/components/contexts/CurrentUserContext'; // eslint-disable-line import/no-extraneous-dependencies
import Scrollbar from '/../../kiska/src/components/Scrollbar'; // eslint-disable-line import/no-extraneous-dependencies
import SiteFooter from './components/SiteFooter';
import SiteHeader from './components/SiteHeader';

const styles = theme => ({
  root: {
    '& .ScrollbarsCustom-Content': {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'stretch',
    },
  },
  content: {
    flex: 1,
    backgroundColor: theme.palette.background.default,
  },
  title: {
    marginTop: theme.spacing.unit * 2,
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
  },
  body: {
    padding: theme.spacing.unit * 4,
  },
});

class NormalLayout extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    children: PropTypes.node,
    title: PropTypes.string,
    showTitle: PropTypes.bool,
    icon: PropTypes.node,
    themeManager: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
  }

  static defaultProps = {
    showTitle: true,
    children: null,
    title: '',
    icon: null,
  }

  render() {
    const { classes, children, title, showTitle, icon, themeManager: { inverseTheme }, currentUser: { user }, match: { path } } = this.props;
    const titlePrefix = '';

    const htmlTitle = title ? `${titlePrefix ? `${titlePrefix} - ` : ''}${title}` : `${titlePrefix}`;
    const titleHeader = showTitle ? (
      <Typography variant="h2" gutterBottom align="center" className={classes.title}>
        {icon}{title}
      </Typography>
    ) : null;

    return (
      <>
        <Helmet>
          <title>{htmlTitle}</title>
        </Helmet>
        <Scrollbar height="100vh" width="100%" className={classes.root}>
          <SiteHeader />
          <div className={classes.content}>
            {titleHeader}
            <div className={classes.body}>
              {children}
            </div>
          </div>
          <MuiThemeProvider theme={inverseTheme}>
            <SiteFooter className={classes.footer} style={{ backgroundColor: inverseTheme.palette.background.default }} />
          </MuiThemeProvider>
        </Scrollbar>
      </>
    );
  }
}

NormalLayout = compose(
  withStyles(styles),
  withThemeManager,
  withCurrentUser,
  withRouter,
)(NormalLayout);
NormalLayout.displayName = 'NormalLayout';
export default NormalLayout;
