import React, { Component, createContext } from 'react';
import PropTypes from 'prop-types';

const ReqResContext = createContext({});

class ReqResProvider extends Component {
  static propTypes = {
    req: PropTypes.object,
    res: PropTypes.object,
    children: PropTypes.node.isRequired,
  }

  static defaultProps = {
    req: null,
    res: null,
  }

  render() {
    const { req, res, children } = this.props;

    return (
      <ReqResContext.Provider value={{ req, res }}>
        {children}
      </ReqResContext.Provider>
    );
  }
}

const withReqRes = WrappedComponent => props => (
  <ReqResContext.Consumer>
    {({ req, res }) => <WrappedComponent req={req} res={res} {...props} />}
  </ReqResContext.Consumer>
);

export { withReqRes, ReqResProvider, ReqResContext };
