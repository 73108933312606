import React from 'react';
import { withRouter } from 'react-router-dom';

const withRouterWorkaround = (Inner) => {
  const Wrapped = props => <Inner {...props} />;
  Wrapped.displayName = `WithRouterWorkaround(${Inner.displayName || Inner.name || '?'})`;
  return withRouter(Wrapped);
};

export default withRouterWorkaround;
