import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { SchemaContext } from '../contexts/SchemaContext'; // eslint-disable-line import/no-extraneous-dependencies
import CrudForm from './CrudForm';
import MutationDialog from './MutationDialog';
import OnMutationEvent from './OnMutationEvent';
import SaveButton from './SaveButton';
import DeleteButton from './DeleteButton';


export default class CrudDialog extends Component {
  static contextType = SchemaContext;

  static propTypes = {
    onSuccess: PropTypes.func,
    maxWidth: PropTypes.string,
    title: PropTypes.string,
    trigger: PropTypes.node,
    type: PropTypes.string.isRequired,
    editor: PropTypes.node.isRequired,
    node: PropTypes.object,
    schemaOverrides: PropTypes.object,
    modifyVars: PropTypes.func,
    onNodeChange: PropTypes.func,
    liveValidation: PropTypes.bool,
    onError: PropTypes.func,
    showSaveAndClone: PropTypes.bool,
    children: PropTypes.node,
  }

  static defaultProps = {
    onSuccess: null,
    maxWidth: 'md',
    title: '',
    node: undefined,
    schemaOverrides: null,
    modifyVars: null,
    onNodeChange: null,
    liveValidation: true,
    onError: null,
    showSaveAndClone: false,
    trigger: null,
    children: null,
  }

  constructor(props) {
    super(props);

    this.state = {
      node: props.node,
    };
  }

  onNodeChange = (node) => {
    this.setState({ node });
  }

  decideToCloseOnSuccess = (mutation, result) => !_.get(mutation, 'meta.saveAndClone')

  render() {
    const schema = this.context;
    const { node: stateNode } = this.state;
    const { children, title, editor, node: propsNode, type, onNodeChange, schemaOverrides, modifyVars, onSuccess, onError, showSaveAndClone, ...rest } = this.props;
    const typeLabel = schema.types[type].label;

    let node;
    if (onNodeChange && (propsNode !== undefined)) {
      // Controlled component
      node = propsNode;
    } else {
      // Uncontrolled component
      node = stateNode;
    }

    const isNew = !(node && node.id);

    return (
      <MutationDialog
        {...rest}
        closeOnSuccess={this.decideToCloseOnSuccess}
        mutationProvider={<CrudForm type={type} node={node} onSuccess={onSuccess} onError={onError} onNodeChange={this.handleNodeChange} modifyVars={modifyVars} schemaOverrides={schemaOverrides} />}
        title={title || (isNew ? `Create New ${typeLabel}` : `Edit ${typeLabel}`)}
        actions={(
          <>
            <DeleteButton />
            {showSaveAndClone ? <SaveButton saveAndClone /> : null}
            <SaveButton />
          </>
        )}
      >
        <OnMutationEvent success={this.handleSuccess} />
        {children}
        {editor}
      </MutationDialog>
    );
  }
}
