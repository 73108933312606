import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';

import CrudPageForm from '/components/CrudPageForm';

const styles = theme => ({
  root: {
  },
});

class StaircaseEditPage extends Component {
  static propTypes = {
    onTitleChange: PropTypes.func.isRequired,
  }

  static defaultProps = {
  }

  render() {
    const { onTitleChange } = this.props;

    return (
      <CrudPageForm
        type="staircase"
        onTitleChange={onTitleChange}
        renderTitle={node => (node ? `Staircase: ${node.label}` : 'Create New Staircase')}
      />
    );
  }
}

StaircaseEditPage = compose(
  withStyles(styles),
  withRouter,
)(StaircaseEditPage);

export default StaircaseEditPage;
