import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Button from '@material-ui/core/Button';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker as MuiDatePicker } from 'material-ui-pickers';
import { withStyles } from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';
import CloseIcon from 'mdi-material-ui/Close';


import { NodeListContext } from '.';

const styles = theme => ({
  root: {
    padding: theme.spacing.unit * 1,
    border: 'solid 1px rgba(0,0,0,.25)',
    borderRadius: 5,
  },
  dates: {
    display: 'flex',
    alignItems: 'center',
  },
});

class DateBetweenFilter extends Component {
  static contextType = NodeListContext

  static propTypes = {
    format: PropTypes.string,
    beforeLabel: PropTypes.string,
    afterLabel: PropTypes.string,
    showClear: PropTypes.bool,
    classes: PropTypes.object.isRequired,
    path: PropTypes.string.isRequired,
    label: PropTypes.string,
  }

  static defaultProps = {
    format: 'dd-MMM-yyyy',
    beforeLabel: 'Is before:',
    afterLabel: 'Is after:',
    label: '',
    showClear: true,
  }

  handleGteChange = value => this.handleChange('_gte', value)

  handleLteChange = value => this.handleChange('_lte', value)

  handleChange = (condition, value) => {
    const filterValue = value ? value.toISOString() : undefined;

    this.change(condition, filterValue);
  }

  change = (condition, value) => {
    const { setVariables } = this.context;
    const { path } = this.props;

    setVariables(({ where }) => {
      let and = _.get(where, `_and`, []);
      and = and.filter(c => !_.get(c, `${path}.${condition}`));
      and.push(_.set({}, `${path}.${condition}`, value));
      where._and = and;
      return { where };
    });
  }

  clear = (event) => {
    this.change('_lte', undefined);
    this.change('_gte', undefined);
  }

  render() {
    const { variables: { where } } = this.context;
    const { format, classes, path, beforeLabel, afterLabel, label, showClear } = this.props;
    const lteValue = _.get(_.get(where, `_and`, []).find(c => _.get(c, `${path}._lte`)), `${path}._lte`) || null;
    const gteValue = _.get(_.get(where, `_and`, []).find(c => _.get(c, `${path}._gte`)), `${path}._gte`) || null;

    return (
      <div className={classes.root}>
        <FormLabel>{label}</FormLabel>
        <div className={classes.dates}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <>
              <MuiDatePicker value={gteValue} format={format} label={afterLabel} onChange={this.handleGteChange} clearable />
              &nbsp;&nbsp;&nbsp;
              <MuiDatePicker value={lteValue} format={format} label={beforeLabel} onChange={this.handleLteChange} clearable />
            </>
          </MuiPickersUtilsProvider>
          {showClear ? <Button color="secondary" onClick={this.clear}><CloseIcon /><span>Clear</span></Button> : null}
        </div>
      </div>
    );
  }
}

DateBetweenFilter = withStyles(styles)(DateBetweenFilter);
export default DateBetweenFilter;
