import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'react-apollo';
import Button from '@material-ui/core/Button';
import UploadIcon from 'mdi-material-ui/Upload';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import _ from 'lodash';

import FilePreviewList from './FilePreviewList';
import FileUploader from './FileUploader';
import { withSettings } from '../../../contexts/SettingsContext';
import HelperText from '../HelperText';
import { MutationContext } from '../../MutationContext';

const isSsr = typeof window === 'undefined';

const styles = theme => ({
  root: {
    display: 'flex',
    flexBasis: 'column',
    justifyContent: 'center',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: `0 ${theme.spacing.unit * 2}px 0 ${theme.spacing.unit * 2}px`,
  },
  toolbarButton: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    marginBottom: 0,
    boxShadow: 'none',
  },
});

class FileField extends React.Component {
  static contextType = MutationContext;

  static propTypes = {
    classes: PropTypes.object.isRequired,
    multiple: PropTypes.bool,
    allowedFileTypes: PropTypes.array,
    restrictions: PropTypes.object,
    isPublic: PropTypes.bool,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    helperText: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }

  static defaultProps = {
    multiple: true,
    allowedFileTypes: null,
    restrictions: {},
    isPublic: true,
    helperText: undefined,
    label: undefined,
    height: undefined,
  }

  constructor(props, context) {
    super(props, context);
    const { registerField } = context;

    registerField(props.name);
  }

  handleUploadComplete = (files) => {
    const { onChange, fields } = this.context;
    const { name } = this.props;

    let value = fields[name].value;
    value = [...value, ...files];

    onChange(name, value);
  }

  handleRemove = (file) => {
    const { onChange, fields } = this.context;
    const { name } = this.props;

    let value = fields[name].value;
    value = value.filter(f => f.id !== file.id);

    onChange(name, value);
  }

  render() {
    const { name, classes, helperText, label: propsLabel, allowedFileTypes, multiple, restrictions, isPublic, height } = this.props;
    const { fields, pendingMutation, schema, type } = this.context;
    const schemaField = schema.types[type].fields[name];
    const label = propsLabel !== undefined ? propsLabel : schemaField.label;
    const errors = fields[name].errors;
    const value = fields[name].value || undefined;

    return (
      <FormControl error={!!errors.length} disabled={pendingMutation} className={classes.root}>
        <FormLabel className={classes.label}>{label}</FormLabel>
        <div className={classes.toolbar}>
          <FileUploader
            onUploadComplete={this.handleUploadComplete}
            allowedFileTypes={allowedFileTypes}
            multiple={multiple}
            restrictions={restrictions}
            isPublic={isPublic}
            className={classes.toolbarButton}
          />
        </div>
        <HelperText text={helperText} errors={errors} />
        <FilePreviewList files={value} onRemove={this.handleRemove} height={height} />
      </FormControl>
    );
  }
}

FileField = compose(
  withSettings,
  withStyles(styles),
)(FileField);

export default FileField;
