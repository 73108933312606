import React, { Component } from 'react';
import { compose } from 'react-apollo';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import CloseIcon from 'mdi-material-ui/Close';
import OkIcon from 'mdi-material-ui/Check';
import green from '@material-ui/core/colors/green';

import NodeFilter from './NodeFilter';
import LikeFilter from './LikeFilter';
import DateBetweenFilter from './DateBetweenFilter';
import BooleanFilter from './BooleanFilter';
import NullFilter from './NullFilter';
import { NodeListContext } from '.';

const styles = theme => ({
  closeButton: {
    position: 'absolute',
    top: theme.spacing.unit * 0,
    right: theme.spacing.unit * 0,
  },
  okButton: {
    backgroundColor: green[700],
    color: '#FFF',

    '&:hover': {
      backgroundColor: green[900],
    },
  },
  title: {
    paddingRight: theme.spacing.unit * 6,
  },
});

class FilterDialog extends Component {
  static contextType = NodeListContext;

  static propTypes = {
    field: PropTypes.string.isRequired,
    maxWidth: PropTypes.string,
    dialogProps: PropTypes.object,
    showCloseButton: PropTypes.bool,
    open: PropTypes.bool,
    classes: PropTypes.object.isRequired,
    fullScreen: PropTypes.bool.isRequired,
    trigger: PropTypes.node.isRequired,
    title: PropTypes.node,
    children: PropTypes.node,
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
  }

  static defaultProps = {
    showCloseButton: true,
    maxWidth: 'md',
    dialogProps: null,
    title: undefined,
    children: null,
    open: undefined,
    onOpen: null,
    onClose: null,
  }

  constructor(props, context) {
    super(props, context);

    this.state = {
      open: false,
    };

    this.filters = this.getFilters();
  }

  handleOpen = () => {
    const { open, onOpen } = this.props;
    if (open === undefined) this.setState({ open: true });
    else onOpen();
  };

  handleClose = () => {
    const { open, onClose } = this.props;
    if (open === undefined) this.setState({ open: false });
    else onClose();
  };

  getFilters = () => {
    const { schemaType } = this.context;
    const { field } = this.props;
    const schemaField = schemaType.fields[field];

    const filters = schemaField.filters.map((filter) => {
      const { type, path, ...rest } = filter;
      const filterProps = { ...rest, path };

      let content = null;
      switch (type) {
        case 'date-between':
          content = <DateBetweenFilter {...filterProps} />;
          break;
        case 'boolean':
          content = <BooleanFilter {...filterProps} />;
          break;
        case 'like':
          content = <LikeFilter {...filterProps} />;
          break;
        case 'node':
          content = <NodeFilter {...filterProps} />;
          break;
        case 'null':
          content = <NullFilter {...filterProps} />;
          break;
        default:
          throw new Error(`Unknown filter type "${type}".`);
      }

      if (!content) return null;
      return (
        <Grid item xs={12} key={`${type}_${path}`}>
          {content}
        </Grid>
      );
    });

    return (
      <Grid container spacing={24}>
        {filters}
      </Grid>
    );
  }

  render() {
    const { schemaType } = this.context;
    const { open: stateOpen } = this.state;
    const { field, maxWidth, dialogProps, showCloseButton, open, classes, fullScreen, trigger, title:
      propsTitle, children, onOpen, onClose, ...rest } = this.props;
    const schemaField = schemaType.fields[field];
    const isOpen = open === undefined ? stateOpen : open;
    const filters = this.filters;

    let title = propsTitle;
    if (title === undefined) {
      title = `Only show ${schemaType.pluralLabel} where ${schemaField.label}:`;
    }

    return (
      <>
        {trigger ? React.cloneElement(trigger, { onClick: this.handleOpen }) : null}
        <Dialog fullScreen={fullScreen} open={isOpen} onClose={this.handleClose} {...dialogProps} maxWidth={maxWidth} {...rest}>
          {showCloseButton ? <IconButton className={classes.closeButton} onClick={this.handleClose}><CloseIcon /></IconButton> : null}
          {title ? <DialogTitle className={classes.title}>{title}</DialogTitle> : null}
          <DialogContent>
            {filters}
          </DialogContent>
          <DialogActions className={classes.actions}>
            <Button className={classes.okButton} onClick={this.handleClose}>
              <OkIcon />
              <span>Ok</span>
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

FilterDialog = compose(
  withMobileDialog(),
  withStyles(styles),
)(FilterDialog);

export default FilterDialog;
