import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import { NodeListContext } from '.';
import ResultsCount from './ResultsCount';
import PageButton from './PageButton';
import FirstSelector from './LimitSelector';

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    padding: theme.spacing.unit * 1,
  },
  limitSelector: {
    marginRight: theme.spacing.unit * 0,
  },
  left: {

  },
  middle: {

  },
  right: {
    display: 'flex',
    alignItems: 'center',
  },
});

class PageControls extends Component {
  static contextType = NodeListContext

  static propTypes = {
    classes: PropTypes.object.isRequired,
  }

  static defaultProps = {
  }

  render() {
    const { classes, ...rest } = this.props;
    const { variables: { limit }, count } = this.context;

    return (
      <div className={classes.root} {...rest}>
        <div className={classes.left}>
          <ResultsCount />
        </div>
        {count > limit ? (
          <div className={classes.right}>
            <FirstSelector className={classes.limitSelector} />
            <div className={classes.buttons}>
              <PageButton type="previous" />
              <PageButton type="next" />
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

PageControls = withStyles(styles)(PageControls);
export default PageControls;
