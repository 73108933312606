import React, { Component, createContext } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { SchemaContext } from '../contexts/SchemaContext'; // eslint-disable-line import/no-extraneous-dependencies
import { MutationProvider } from './MutationContext';
import { insertMutationBuilderFactory, updateMutationBuilderFactory, deleteMutationBuilderFactory } from './mutationBuilderFactories';

export default class CrudForm extends Component {
  static contextType = SchemaContext;

  static propTypes = {
    modifyVars: PropTypes.func,
    node: PropTypes.object,
    onSuccess: PropTypes.func,
    onNodeChange: PropTypes.func,
    children: PropTypes.node,
    type: PropTypes.string.isRequired,
  }

  static defaultProps = {
    modifyVars: null,
    node: undefined,
    onSuccess: null,
    onNodeChange: null,
    children: null,
  }

  constructor(props, context) {
    super(props, context);
    const schema = context;

    const builderArg = {
      modifyVars: props.modifyVars,
    };

    this.state = {
      node: props.node,
    };

    this.mutations = {
      insert: insertMutationBuilderFactory(builderArg),
      update: updateMutationBuilderFactory(builderArg),
      delete: deleteMutationBuilderFactory(builderArg),
    };
  }

  handleSuccess = (mutation, result) => {
    const { onSuccess, onNodeChange } = this.props;

    let node = _.get(result, `data.${mutation.alias}.returning[0]`);

    if (mutation.meta && mutation.meta.saveAndClone) {
      node = JSON.parse(JSON.stringify(node));
      delete node.id;
    }
    this.setState({ node });

    if (onNodeChange) {
      onNodeChange(node, mutation, result);
    }

    if (onSuccess) {
      onSuccess(mutation, result, node);
    }
  }

  render() {
    const { modifyVars, node: propsNode, onNodeChange, children, type, ...rest } = this.props;
    const { node: stateNode } = this.state;

    let node;
    if (onNodeChange && (propsNode !== undefined)) {
      // Component is controlled
      node = propsNode;
    } else {
      // Component is uncontrolled
      node = stateNode;
    }

    const isNew = !(node && node.id);
    const defaultMutation = isNew ? 'insert' : 'update';

    return (
      <MutationProvider
        {...rest}
        key={node ? node.id : undefined}
        type={type}
        node={node}
        onSuccess={this.handleSuccess}
        mutationBuilders={this.mutations}
        defaultMutation={defaultMutation}
      >
        {children}
      </MutationProvider>
    );
  }
}
