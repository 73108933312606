import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { compose } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import ReloadIcon from 'mdi-material-ui/Reload';
import RobotIcon from 'mdi-material-ui/RobotIndustrial';
import classNames from 'classnames';
import _ from 'lodash';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CircularProgress from '@material-ui/core/CircularProgress';

import { AppSettingsContext } from '/../../kiska/src/components/contexts/AppSettingsContext';
import Scrollbar from '/../../kiska/src/components/Scrollbar';

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing.unit * 1,
    boxShadow: '2px 0 5px rgba(0,0,0,1)',
    zIndex: 11,
    height: '100vh',
    overflow: 'hidden',
    paddingBottom: 40,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  labeledValue: {
    display: 'flex',
    alignItems: 'center',
    padding: 0,
  },
  label: {
    marginRight: 8,
  },
  staircaseStates: {
  },
  staircaseState: {
    width: '100%',
  },
  panelHeading: {
    padding: 0,
  },
  panelSummary: {
    padding: `0 8px`,
    minHeight: `0 !important`,
    '& > div:first-child': {
      padding: 0,
      margin: `8px 0 !important`,
    },
  },
  panelDetails: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: `8px`,
  },
  levelStates: {
    width: '100%',
  },
  levelState: {
    width: '100%',
    backgroundColor: 'rgba(255,255,255,.15)',
  },
});

const LabeledValue = withStyles(styles)(({ classes, label, value }) => (
  <div className={classes.labeledValue}>
    <Typography variant="body2" color="textSecondary" className={classes.label}>{label}:</Typography>
    <Typography variant="body2" color="textPrimary" className={classes.value} component="div">{value}</Typography>
  </div>
));

const LevelState = withStyles(styles)(({ classes, level, label, current }) => (
  <ExpansionPanel className={classes.levelState}>
    <ExpansionPanelSummary className={classes.panelSummary} expandIcon={<ExpandMoreIcon />}>
      <Typography className={classes.panelHeading} color={current ? 'primary' : undefined}>
        {label}
      </Typography>
    </ExpansionPanelSummary>
    <ExpansionPanelDetails className={classes.panelDetails}>
      <LabeledValue label="Successfull Attempts" value={level.successfullAttempts} />
      <LabeledValue label="Failed Attempts" value={level.failedAttempts} />
      <LabeledValue label="Reversals" value={level.reversals} />
      <LabeledValue label="Positive Inflections" value={level.positiveInflections} />
      <LabeledValue label="Negative Inflections" value={level.negativeInflections} />
    </ExpansionPanelDetails>
  </ExpansionPanel>
));

const StaircaseState = withStyles(styles)(({ classes, state, current, label }) => (
  <ExpansionPanel className={classes.staircaseState}>
    <ExpansionPanelSummary className={classes.panelSummary} expandIcon={<ExpandMoreIcon />}>
      <Typography className={classes.panelHeading} color={current ? 'primary' : undefined}>
        {label}
      </Typography>
    </ExpansionPanelSummary>
    <ExpansionPanelDetails className={classes.panelDetails}>
      <LabeledValue label="Status" value={state.status} />
      <LabeledValue label="Level" value={state.level + 1} />
      <LabeledValue label="Previous Level" value={state.previousLevel !== undefined ? state.previousLevel + 1 : 'none'} />

      <LabeledValue label="Avg. Reversal Level" value={state.avgReversalLevel.toFixed(2) + 1} />
      <LabeledValue label="Avg. Reversal Level (unweighted)" value={state.unweightedAvgReversalLevel.toFixed(2) + 1} />
      <LabeledValue label="Max Reversals" value={`${state.maxReversals} on Level ${state.maxReversalLevel + 1}`} />
      <LabeledValue label="Total Reversals" value={state.totalReversals} />
      <LabeledValue label="Reversal Level Count" value={state.totalReversalLevels} />

      <LabeledValue label="Countdown Threshold Reached" value={state.countdownThresholdReached ? 'Yes' : 'No'} />
      <LabeledValue label="Post Countdown Trials" value={state.postCountdownTrials} />
      <br />
      <Typography variant="body2" color="textSecondary"><b>This Attempt at Level {state.level + 1}:</b></Typography>
      <LabeledValue label="Total Correct" value={state.attempt.totalCorrect} />
      <LabeledValue label="Consecutive Correct" value={state.attempt.consecutiveCorrect} />
      <LabeledValue label="Total Incorrect" value={state.attempt.totalIncorrect} />
      <LabeledValue label="Consecutive Incorrect" value={state.attempt.consecutiveIncorrect} />
      <LabeledValue label="Total Timeouts" value={state.attempt.totalTimeouts} />
      <LabeledValue label="Consecutive Timeouts" value={state.attempt.consecutiveTimeouts} />
      <br />
      <Typography variant="body2" color="textSecondary"><b>Per Level Totals:</b></Typography>
      <div className={classes.levelStates}>
        {state.levels.map((level, index) => <LevelState key={index} level={level} label={`Level ${index + 1}`} current={index === state.level} />)}
      </div>
    </ExpansionPanelDetails>
  </ExpansionPanel>
));

class SessionDebugBar extends Component {
  static contextType = AppSettingsContext;

  static propTypes = {
    classes: PropTypes.object.isRequired,
    session: PropTypes.object.isRequired,
    stage: PropTypes.string.isRequired,
    className: PropTypes.string,
    onReshuffleFaceSet: PropTypes.func.isRequired,
    onRunRobot: PropTypes.func.isRequired,
    latestResponse: PropTypes.object.isRequired,
    savingTrialResponse: PropTypes.bool.isRequired,
    savingSessionState: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    className: '',
  }

  constructor(props) {
    super(props);

    this.state = {
    };
  }

  getStaircaseById = (id) => {
    const { session } = this.props;
    const staircase = _.find(session.staircaseSet.staircases, s => s.staircase.id === id).staircase;
    return staircase;
  }

  currentStaircase = () => {
    const { session } = this.props;
    const state = session.state;
    const staircaseId = state.sequenceKeys[state.sequence[state.sequenceIndex]];
    const staircase = this.getStaircaseById(staircaseId);
    return staircase;
  }

  render() {
    const { onRunRobot, classes, stage, session, className, onReshuffleFaceSet, latestResponse, savingTrialResponse, savingSessionState } = this.props;
    const currentStaircase = this.currentStaircase();
    const currentStaircaseState = session.state.staircases[currentStaircase.id];

    return (
      <div className={classNames(classes.root, className)}>
        <Scrollbar height="100vh" width="100%">
          <Typography variant="h6" className={classes.title} gutterBottom>
            {/* <DebugIcon />&nbsp;&nbsp; */}
            Testing Info
          </Typography>
          <LabeledValue label="Session save in progress" value={savingSessionState ? <><CircularProgress size={12} /> Yes...</> : 'No'} />
          <LabeledValue label="Response save in progress" value={savingTrialResponse ? <><CircularProgress size={12} /> Yes...</> : 'No'} />
          <LabeledValue label="Staircase Set" value={session.staircaseSet.label} />
          <LabeledValue label="Stage" value={stage} />
          <br />
          <Typography variant="body2" color="textSecondary"><b>Latest Response:</b></Typography>
          <LabeledValue label="Result" value={latestResponse.result} />
          <LabeledValue label="Response Time" value={latestResponse.responseTime} />
          <br />
          <Button variant="outlined" size="small" onClick={onReshuffleFaceSet}><ReloadIcon /><span>Reshuffle this trial</span></Button>
          <br /><br />
          <Button variant="outlined" size="small" onClick={onRunRobot}><RobotIcon /><span>Let the robot choose</span></Button>
          <br />
          <br />
          <div className={classes.staircaseStates}>
            {_.map(session.state.staircases, (state, id) => {
              const staircase = this.getStaircaseById(id);
              return <StaircaseState key={id} state={state} label={`${staircase.label} - Level ${state.level + 1}`} current={currentStaircase.id === id} />;
            })}
          </div>
          <br /><br /><br /><br /><br /><br /><br /><br /><br />
        </Scrollbar>
      </div>
    );
  }
}

SessionDebugBar = compose(
  withStyles(styles),
)(SessionDebugBar);
SessionDebugBar.displayName = 'SessionDebugBar';
export default SessionDebugBar;
