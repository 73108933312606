import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'react-apollo';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import PdfIcon from 'mdi-material-ui/FilePdf';
import WordIcon from 'mdi-material-ui/FileWord';
import ExcelIcon from 'mdi-material-ui/FileExcel';
import FileIcon from 'mdi-material-ui/File';
import ImageIcon from 'mdi-material-ui/FileImage';
import CopyIcon from 'mdi-material-ui/ContentCopy';
import DownloadIcon from 'mdi-material-ui/Download';
import RemoveIcon from 'mdi-material-ui/Delete';
import red from '@material-ui/core/colors/red';
import CircularProgress from '@material-ui/core/CircularProgress';
import _ from 'lodash';
import Tooltip from '@material-ui/core/Tooltip';


const styles = theme => ({
  card: {
    position: 'relative',
    width: 200,
  },
  content: {
    padding: '0 8px',
  },
  actions: {
    padding: '0 8px',
    justifyContent: 'space-between',
  },
  mediaArea: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: 100,
  },
  icon: {
    fontSize: 75,
    // color: '#444',
  },
  media: {
    height: 100,
    width: '100%',
    backgroundSize: 'contain',
  },
  progress: {
    // position: 'absolute',
    // top: 10,
    // right: 0,
    padding: theme.spacing.unit * 1,
  },
  remove: {
    color: red[500],
    '&:hover': {
      backgroundColor: red[100],
    },
  },
});

const getExt = name => name.split('.').pop().toLowerCase();
const isImage = file => _.startsWith(file.mimeType, 'image/');

const getIcon = (file) => {
  switch (file.mimeType) {
    case 'application/msword':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return WordIcon;
    case 'application/vnd.ms-excel':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return ExcelIcon;
    case 'application/pdf':
      return PdfIcon;
    default:
      if (isImage(file)) return ImageIcon;
      return FileIcon;
  }
};

class FilePreview extends React.Component {
  static propTypes = {
    file: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    onRemove: PropTypes.func,
  }

  static defaultProps = {
    onRemove: null,
  }

  handleCopy = () => {
    window.navigator.clipboard.writeText(this.props.file.url)
      // eslint-disable-next-line no-alert
      .catch(error => window.alert('Your browser is not allowing copying to the clipboard.'));
  }

  handleRemove = () => {
    const { file, onRemove } = this.props;
    if (onRemove) {
      onRemove(file);
    }
  }

  render() {
    const { classes, file } = this.props;
    const Icon = getIcon(file);

    const media = isImage(file) && file.url
      ? <CardMedia className={classes.media} image={file.url} title="File preview" />
      : <Icon className={classes.icon} />;

    return (
      <Card className={classes.card}>
        <div className={classes.mediaArea}>
          {media}
        </div>

        <CardContent className={classes.content}>
          <Typography component="p" variant="body2">{file.name}</Typography>
        </CardContent>

        <CardActions className={classes.actions}>
          <Tooltip title="Download">
            <IconButton component="a" href={file.url} download size="small" className={classes.copy}>
              <DownloadIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Copy URL">
            <IconButton size="small" className={classes.copy} onClick={this.handleCopy}>
              <CopyIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Remove" onClick={this.handleRemove}>
            <IconButton size="small" className={classes.remove}>
              <RemoveIcon />
            </IconButton>
          </Tooltip>
        </CardActions>
      </Card>
    );
  }
}

FilePreview = compose(
  withStyles(styles),
)(FilePreview);


export default FilePreview;
