import React from 'react';
import _ from 'lodash';

import ViewDialog from './ViewDialog';
import views from './*View.js'; // eslint-disable-line

_.forEach(views, ({ default: view }, type) => {
  const camelType = _.lowerFirst(type);
  module.exports[`${type}View`] = view;
  module.exports[`${type}ViewDialog`] = props => <ViewDialog type={camelType} view={view} {...props} />;
});
