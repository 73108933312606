import React from 'react';
import { Query, compose } from 'react-apollo';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import CloseIcon from 'mdi-material-ui/Close';
import _ from 'lodash';

import { SchemaContext } from '/../../kiska/src/components/contexts/SchemaContext'; // eslint-disable-line import/no-extraneous-dependencies
import buildQuery from '/../../kiska/src/components/NodeList/buildQuery'; // eslint-disable-line import/no-extraneous-dependencies
import { addIdsToSelections } from '/../../kiska/src/utils'; // eslint-disable-line import/no-extraneous-dependencies

const styles = theme => ({
  root: {
    minWidth: '50vw',
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacing.unit * 0,
    right: theme.spacing.unit * 0,
  },
});

class ViewDialog extends React.Component {
  static contextType = SchemaContext;

  static propTypes = {
    type: PropTypes.string.isRequired,
    view: PropTypes.any.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    fullScreen: PropTypes.bool.isRequired,
    classes: PropTypes.object.isRequired,

    onOpen: PropTypes.func,
    showCloseButton: PropTypes.bool,
    maxWidth: PropTypes.string,
    dialogProps: PropTypes.object,
    selections: PropTypes.string,
    selectionSet: PropTypes.string,
    where: PropTypes.object,
    nodeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    trigger: PropTypes.node,
    title: PropTypes.string,
    viewProps: PropTypes.object,
    renderTitle: PropTypes.func,
    prefixTitleWithType: PropTypes.bool,
  }

  static defaultProps = {
    showCloseButton: true,
    maxWidth: 'md',
    dialogProps: null,
    where: null,
    nodeId: '',
    title: '',
    renderTitle: null,
    trigger: null,
    selectionSet: 'default',
    selections: '',
    prefixTitleWithType: false,
    viewProps: {},
    onOpen: null,
  }

  constructor(props, context) {
    super(props, context);

    this.state = {
      open: false,
    };

    this.schemaType = context.types[props.type];
    this.selections = props.selections || _.get(this.schemaType, `selections.${props.selectionSet}`, '');
    this.selections = addIdsToSelections(this.selections);
    this.trigger = props.trigger ? React.cloneElement(props.trigger, { onClick: this.handleOpen }) : null;
    this.query = buildQuery({ type: props.type, schema: context, selections: this.selections });
  }

  handleOpen = () => {
    const { open, onOpen } = this.props;
    if (open === undefined) this.setState({ open: true });
    else onOpen();
  };

  handleClose = () => {
    const { open, onClose } = this.props;
    if (open === undefined) this.setState({ open: false });
    else onClose();
  };

  renderError = (error) => {
    console.error('Error fetching node for ViewDialog: ', error);

    return (
      <Typography variant="body1">
        Sorry, there was an error getting that {this.schemaType.label}.
      </Typography>
    );
  }

  renderEmptyData = data => (
    <Typography variant="body1">
      There doesn't seem to be any {this.schemaType.label} matching that criteria.
    </Typography>
  )

  renderLoading = data => (
    <Typography variant="body1">
      Loading...
    </Typography>
  )

  render() {
    const { open: stateOpen } = this.state;
    const { prefixTitleWithType, maxWidth, dialogProps, showCloseButton, open: propsOpen,
      where: propsWhere, nodeId, classes, fullScreen,
      title: propsTitle, renderTitle, view: View, viewProps } = this.props;
    const open = propsOpen === undefined ? stateOpen : propsOpen;

    let where;
    if (nodeId) {
      where = { id: { _eq: nodeId } };
    }
    where = _.merge(propsWhere, where);
    const variables = { where };

    return (
      <>
        {this.trigger}
        <Query query={this.query} variables={variables} skip={!(nodeId || where)}>
          {({ loading, error, data }) => {
            const node = _.get(data, 'nodes[0]');
            const haveData = !error && node;
            let content;
            let title;

            if (haveData) {
              title = propsTitle;
              if (!title && renderTitle) title = renderTitle(node);
              if (!title) title = this.schemaType.label;
              if (prefixTitleWithType) title = `${this.schemaType.label}: ${title}`;
            }

            if (haveData) {
              content = <View node={node} {...viewProps} />;
            } else if (error) {
              content = this.renderError(error);
              title = 'Error';
            } else if (loading) {
              content = this.renderLoading();
              title = 'Loading...';
            } else {
              content = this.renderEmptyData();
              title = 'No Data';
            }

            return (
              <Dialog PaperProps={{ className: classes.root }} fullScreen={fullScreen} open={open} onClose={this.handleClose} {...dialogProps} maxWidth={maxWidth}>
                {showCloseButton
                  ? <IconButton className={classes.closeButton} onClick={this.handleClose}><CloseIcon /></IconButton>
                  : null
                }
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                  {content}
                </DialogContent>
              </Dialog>
            );
          }}
        </Query>
      </>
    );
  }
}

ViewDialog = compose(
  withMobileDialog(),
  withStyles(styles),
)(ViewDialog);

export default ViewDialog;
