import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import qs from 'query-string';

import CrudPageForm from '/components/CrudPageForm';

const styles = theme => ({
  root: {
  },
});

class UserInvitationEditPage extends Component {
  static propTypes = {
    onTitleChange: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
  }

  static defaultProps = {
  }

  render() {
    const { onTitleChange, location: { search } } = this.props;

    const query = qs.parse(search);

    let role = query.role;
    let roleLabel;
    if (role === 'administrator') {
      role = 'site-owner';
      roleLabel = 'Administrator';
    }
    if (role === 'participant') roleLabel = 'Participant';

    return (
      <CrudPageForm
        type="user_invitation"
        onTitleChange={onTitleChange}
        renderTitle={node => (node ? `Edit Invitation for ${node.role.label}` : `Invite New ${roleLabel}`)}
        editorProps={{ role, roleLabel }}
      />
    );
  }
}

UserInvitationEditPage = compose(
  withStyles(styles),
  withRouter,
)(UserInvitationEditPage);
UserInvitationEditPage.displayName = 'UserInvitationEditPage';
export default UserInvitationEditPage;
