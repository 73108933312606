export default {
  types: {
    set_assignment: {
      renderers: {
        inline: node => node.id,
      },

      selections: {
        default: 'user{displayName email} data',
      },

      fields: {
        user: {
          type: 'user',
        },
        userId: {
          type: 'String',
        },
        data: {
          type: 'Json',
        },
      },
    },

  },
};
