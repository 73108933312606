/* eslint-disable react/prop-types */
import React from 'react';
import _ from 'lodash';
import Button from '@material-ui/core/Button';
import PlusIcon from 'mdi-material-ui/Plus';

import CrudForm from '/../../kiska/src/components/MutationContext/CrudForm'; // eslint-disable-line import/no-extraneous-dependencies
import CrudDialog from '/../../kiska/src/components/MutationContext/CrudDialog'; // eslint-disable-line import/no-extraneous-dependencies
import MutationSnackbars from '/../../kiska/src/components/MutationContext/MutationSnackbars'; // eslint-disable-line import/no-extraneous-dependencies
import { withSchema } from '/../../kiska/src/components/contexts/SchemaContext'; // eslint-disable-line import/no-extraneous-dependencies
import editors from './*Editor.js'; // eslint-disable-line

_.forEach(editors, ({ default: Editor }, type) => {
  const camelType = _.lowerFirst(type);

  module.exports[`${type}Editor`] = Editor;

  const EditorDialog = props => (
    <CrudDialog {...props} type={camelType} editor={<Editor />}>
      <MutationSnackbars />
    </CrudDialog>
  );
  module.exports[`${type}EditorDialog`] = EditorDialog;

  module.exports[`Insert${type}Button`] = withSchema(({ dialogProps, children, schema, ...rest }) => {
    const content = children || (
      <>
        <PlusIcon />
        <span>Create new {schema.types[camelType].label}</span>
      </>
    );

    return (
      <EditorDialog
        trigger={<Button variant="outlined" color="primary" {...rest}>{content}</Button>}
        {...dialogProps}
      />
    );
  });
});
