import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker as MuiDatePicker } from 'material-ui-pickers';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';

import HelperText from './HelperText';
import { MutationContext } from '../MutationContext';

const styles = theme => ({
  root: {
    // width: '100%',
  },
});

class DatePicker extends Component {
  static contextType = MutationContext;

  static propTypes = {
    name: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
    helperText: PropTypes.node,
    format: PropTypes.string,
    label: PropTypes.string,
    initialValue: PropTypes.instanceOf(Date),
  }

  static defaultProps = {
    format: 'dd-MMM-yyyy',
    helperText: undefined,
    label: '',
    initialValue: undefined,
  }

  constructor(props, context) {
    super(props, context);
    const { registerField } = context;

    registerField(props.name, { initialValue: props.initialValue });
  }

  handleChange = (value) => {
    const { onChange } = this.context;
    const { name } = this.props;

    onChange(name, value);
  }

  render() {
    const { name, classes, helperText, format, label: propsLabel, initialValue, ...rest } = this.props;
    const { fields, pendingMutation, schema, type } = this.context;
    const schemaField = schema.types[type].fields[name];
    const label = propsLabel || schemaField.label;
    const value = fields[name].value;
    const errors = fields[name].errors;

    return (
      <FormControl error={!!errors.length} disabled={pendingMutation} className={classes.root}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <MuiDatePicker
            name={name}
            value={value}
            label={label}
            error={!!fields[name].errors.length}
            onChange={this.handleChange}
            disabled={pendingMutation}
            helperText={<HelperText text={helperText} errors={errors} />}
            className={classes.root}
            clearable
            format={format}
            {...rest}
          />
        </MuiPickersUtilsProvider>
      </FormControl>
    );
  }
}

DatePicker = withStyles(styles)(DatePicker);

export default DatePicker;
