import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import addDays from 'date-fns/addDays';
import nanoid from 'nanoid';

import { buildSignUpByInvitationUrl } from '/utils';
import { MutationContext } from '/../../kiska/src/components/MutationContext/MutationContext'; // eslint-disable-line import/no-extraneous-dependencies
import TextField from '/../../kiska/src/components/MutationContext/FormFields/TextField'; // eslint-disable-line import/no-extraneous-dependencies
import DatePicker from '../../../../kiska/src/components/MutationContext/FormFields/DatePicker';
import NodeField from '/../../kiska/src/components/MutationContext/FormFields/NodeField';
import Fixture from '/../../kiska/src/components/MutationContext/FormFields/Fixture';
import CopyToClipboardButton from '/../../kiska/src/components/CopyToClipboardButton';

class UserInvitationEditor extends React.Component {
  static contextType = MutationContext;

  static propTypes = {
    role: PropTypes.string,
    roleLabel: PropTypes.string,
  }

  static defaultProps = {
    role: undefined,
    roleLabel: undefined,
  }

  render() {
    const { node } = this.context;
    const { role, roleLabel } = this.props;

    let roleId = '2';
    if (role === 'participant') roleId = '3';
    else if (role === 'site-owner') roleId = 'asdf';

    let link;
    if (node) {
      link = buildSignUpByInvitationUrl(node.token);
    }

    return (
      <Grid container spacing={24}>
        {!node || !node.id ? (
          <>
            <Fixture name="token" value={nanoid(8)} />
            <Fixture name="role" value={roleId} />
          </>
        ) : null}

        {node && node.id ? (
          <Grid item xs={12}>
            <Typography variant="body1">
              Copy and paste this link into an email or text message to invite users:
            </Typography>
            {/* <Typography variant="h6">
              {link}
            </Typography> */}
            <CopyToClipboardButton title="Copy link to clipboard" text={link} color="primary" variant="outlined">
              {link}
            </CopyToClipboardButton>
          </Grid>
        ) : null}

        {!role ? (
          <Grid item xs={12}>
            <NodeField name="role" helperText="When the user signs up, what permissions will they be granted on the site?" />
          </Grid>
        ) : null}
        {role ? (
          <Grid item xs={12}>
            <Typography variant="body1">
              You are creating an invitation link that you can email to anyone.
              When they click on the link, they will be able to sign up using a variety of methods.
              Once signed up, they will have the role of <b>{roleLabel}</b> on the site.
            </Typography>
          </Grid>
        ) : null}
        {role === 'site-owner' ? (
          <Grid item xs={12}>
            <Typography variant="body1">
              Be careful with these invitations! Anyone with this link can become an administrator on the site and make
              all the changes you can make and see all the data you can see. It is <i>strongly recommended</i> that you
              set "maximum uses" to 1 (one) and a short expiry time (as are the defaults below).
            </Typography>
          </Grid>
        ) : null}

        <Grid item xs={6}>
          <TextField
            name="usesRemaining"
            helperText="This sets how many new users can sign up with this invitation."
            initialValue="1"
            type="number"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <DatePicker
            name="expiresAt"
            label="Expires On"
            helperText="Invitation will be invalid after this data"
            initialValue={addDays(new Date(), 1)}
          />
        </Grid>


      </Grid>
    );
  }
}

export default UserInvitationEditor;
