import { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { MutationContext } from './MutationContext';

class OnMutationEvent extends Component {
  static contextType = MutationContext;

  static propTypes = {
    onSuccess: PropTypes.func,
    onError: PropTypes.func,
    onValidationFailed: PropTypes.func,
    onComplete: PropTypes.func,
  }

  static defaultProps = {
    onSuccess: null,
    onError: null,
    onValidationFailed: null,
    onComplete: null,
  }

  eventListenerIds = {}

  constructor(props, context) {
    super(props, context);

    OnMutationEvent.listenerId += 1;
    ['success', 'error', 'complete', 'validationFailed'].forEach((event) => {
      this.eventListenerIds[event] = context.addEventListener(event, this.handleEvent(event));
    });
  }

  componentWillUnmount = () => {
    const { removeEventListener } = this.context;
    _.forEach(this.eventListenerIds, (id, event) => removeEventListener(event, id));
  }

  handleEvent = event => (...args) => {
    const handler = `on${_.upperFirst(event)}`;
    if (this.props[handler]) {
      this.props[handler](...args);
    }
  }

  render() {
    return null;
  }
}

export default OnMutationEvent;
