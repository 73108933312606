import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';

import SelectField from '/../../kiska/src/components/MutationContext/FormFields/SelectField';
import { MutationContext } from '/../../kiska/src/components/MutationContext/MutationContext'; // eslint-disable-line import/no-extraneous-dependencies
import TextField from '/../../kiska/src/components/MutationContext/FormFields/TextField'; // eslint-disable-line import/no-extraneous-dependencies
import NodeField from '/../../kiska/src/components/MutationContext/FormFields/NodeField';
import StaircaseInheritedConfigEditor from '../StaircaseInheritedConfigEditor';

import robin from '/images/robin.png';

const robinImg = <img style={{ height: 20, marginRight: 8 }} src={robin} alt="Robin bird" />;

class StaircaseSetEditor extends React.Component {
  static contextType = MutationContext;

  render() {
    return (
      <Grid container spacing={24}>

        <Grid item xs={12}>
          <Typography variant="h5">Set-Specific Settings</Typography>
        </Grid>

        <Grid item xs={12}>
          <TextField name="label" />
        </Grid>

        <Grid item xs={12}>
          <NodeField name="staircases" multiple />
        </Grid>

        <Grid item xs={12} md={6}>
          <SelectField
            name="config.sequenceMode"
            label="Sequence Mode"
            options={[
              { value: 'round-robin', primary: 'Round Robin', secondary: 'Perfect sequence - eg: A, B, C, A, B, C' },
              { value: 'random-robin', primary: <span style={{ display: 'flex', alignItems: 'center' }}>{robinImg}Random Robin</span>, secondary: 'Random within blocks - eg: B, C, A, A, B, C' },
              { value: 'random', primary: 'Random', secondary: 'Random - eg: B, C, A, B, C, B' },
            ]}
            helperText="This mode affects how Promotion Threshold is interpreted."
            initialValue="random-robin"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            name="config.betweenTrialsDelay"
            label="Between Trials Delay"
            helperText="Time between when faces disapear and new ones appear."
            type="number"
            initialValue="500"
            InputProps={{ endAdornment: <InputAdornment position="end">milliseconds</InputAdornment> }}
          />
        </Grid>


        {/* <Grid item xs={12} />
        <Grid item xs={12}>
          <Typography variant="h5">Inheritable Settings</Typography>
          <Typography variant="body2" color="textSecondary">
            These settings can either be set here or each staircase could use it's own, potentially different settings.
            Use the "inherit" setting in each section to control this behaviour.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <StaircaseInheritedConfigEditor showForceInherit />
        </Grid> */}
      </Grid>
    );
  }
}

export default StaircaseSetEditor;
