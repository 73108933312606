import React, { Component, createContext } from 'react';
import PropTypes from 'prop-types';

const SettingsContext = createContext({});

class SettingsProvider extends Component {
  static propTypes = {
    settings: PropTypes.object.isRequired,
    secretSettings: PropTypes.object,
    children: PropTypes.node.isRequired,
  }

  static defaultProps = {
    secretSettings: null,
  }

  render() {
    const { settings, secretSettings, children } = this.props;

    return (
      <SettingsContext.Provider value={{ settings, secretSettings }}>
        {children}
      </SettingsContext.Provider>
    );
  }
}

const withSettings = WrappedComponent => props => (
  <SettingsContext.Consumer>
    {({ settings, secretSettings }) => <WrappedComponent settings={settings} secretSettings={secretSettings} {...props} />}
  </SettingsContext.Consumer>
);

export { withSettings, SettingsProvider, SettingsContext };
