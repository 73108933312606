import React from 'react';
import formatDate from 'date-fns/format';
import parseISO from 'date-fns/parseISO';

const scalerRenderers = {
  ID: value => value,
  String: value => value,
  Int: value => value,
  Float: value => value,
  Boolean: (value) => {
    if (value === true) return 'Yes';
    if (value === false) return 'No';
    return 'No value';
  },
  Timestamp: (value) => {
    if (!value) return '';
    const date = parseISO(value);
    return (
      <>
        {formatDate(date, 'dd-MMM-yyyy')}<br />
        {formatDate(date, 'h:mma').toLowerCase()}
      </>
    );
  },
  Json: value => (
    <pre style={{ fontFamily: '"Courier New", Courier, monospace' }}>
      {JSON.stringify(value, null, 2)}
    </pre>
  ),
};

export default scalerRenderers;
