import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-apollo';
import _ from 'lodash';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from 'mdi-material-ui/Pencil';
import { withStyles } from '@material-ui/core/styles';
import MuiTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import Paper from '@material-ui/core/Paper';
import classNames from 'classnames';
import FilterIcon from 'mdi-material-ui/Filter';
import { CircularProgress } from '@material-ui/core';

import withRouterWorkaround from '../withRouterWorkaround';
import { NodeListContext } from '.';
import PageControls from './PageControls';
import FilterDialog from './FilterDialog';

const styles = theme => ({
  root: {
    width: '100%',
  },
  tableWrapper: {
    position: 'relative',
    overflowX: 'auto',
  },
  longText: {
    maxWidth: 300,
    display: 'inline-block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  loadingScreen: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10,
    backgroundColor: 'rgba(255,255,255,.7)',
  },
  bodyRow: {
    height: 'auto',
  },
  headRow: {
    height: 'auto',
  },
  bodyCell: {
    padding: `${theme.spacing.unit * 0.5}px ${theme.spacing.unit * 1}px`,
    fontSize: '1em',
    verticalAlign: 'bottom',
  },
  headCell: {
    padding: `${theme.spacing.unit * 0.5}px ${theme.spacing.unit * 1}px`,
    fontSize: '1em',
    verticalAlign: 'bottom',
  },
  actionButton: {
    margin: `-10px ${theme.spacing.unit * 1}px -10px 0`,
  },
  tableActions: {
    display: 'flex',
    justifyContent: 'flex-start',
    padding: `${theme.spacing.unit * 1}px ${theme.spacing.unit * 1}px`,
  },
  filterButton: {
    margin: `-5px -5px`,
    padding: `0`,
    '& svg': {
      fontSize: '.8em',
    },
  },
  editButton: {
    padding: 0,
  },
  nonSortingHeadCellContent: {
    paddingRight: theme.spacing.unit * 1,
  },
});

class Table extends Component {
  static contextType = NodeListContext

  static propTypes = {
    firstOrderByDirection: PropTypes.string,
    height: PropTypes.number,
    containerComponent: PropTypes.func,
    editDialog: PropTypes.node,
    classes: PropTypes.object.isRequired,
    viewDialog: PropTypes.any,
    actions: PropTypes.node,
    className: PropTypes.string,
    columns: PropTypes.array,
    getRowClickUrl: PropTypes.func,
    history: PropTypes.object.isRequired,
  }

  static defaultProps = {
    firstOrderByDirection: 'asc_nulls_last',
    height: 440,
    containerComponent: Paper,
    className: '',
    actions: null,
    viewDialog: null,
    editDialog: null,
    columns: undefined,
    getRowClickUrl: undefined,
  }

  static fieldOrderByDirection = (field, orderBy) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const criteria of orderBy) {
      if (criteria[field]) return criteria[field];
    }
    return null;
  }

  static buildDefaultColumn = (fieldName, schemaType) => {
    const schemaField = schemaType.fields[fieldName];
    if (!schemaField) {
      console.error(`Cannot find schemaField for field name "${fieldName}`);
    }

    return {
      fieldName,
      label: schemaField.label,
      render: schemaField.renderers.inline,
      schemaField,
      isDefaultColumn: true,
    };
  }

  constructor(props, context) {
    super(props, context);
    const { topLevelSelections, schemaType } = context;
    const { columns } = props;

    this.columns = columns;
    if (!this.columns) {
      this.columns = topLevelSelections.map(fieldName => Table.buildDefaultColumn(fieldName, schemaType));
    }
    this.columns = this.columns.map((c) => {
      if (typeof c === 'string') return Table.buildDefaultColumn(c, schemaType);
      return c;
    });

    if (!context || !_.size(context)) throw new Error('Cannot find enclosing NodeList context for Table.');

    this.hasRowActions = !!props.editDialog;

    this.state = {
      viewNodeId: null,
      editNode: null,
    };
  }

  renderRow = (node) => {
    const { classes, editDialog, getRowClickUrl } = this.props;

    return (
      <TableRow hover key={node.id} className={classes.bodyRow} onClick={getRowClickUrl ? this.handleRowClick(node) : undefined}>
        {this.renderCells(node)}
        {editDialog ? (
          <>
            <TableCell>
              <IconButton className={classes.editButton} onClick={() => this.openEditor(node)}>
                <EditIcon />
              </IconButton>
            </TableCell>
          </>
        ) : null}
      </TableRow>
    );
  }

  renderCells = (node) => {
    const { type } = this.context;
    const { classes, viewDialog, getRowClickUrl } = this.props;

    const cells = this.columns.map(({ fieldName, render, schemaField, isDefaultColumn }) => {
      let content;

      if (isDefaultColumn) {
        const value = node[fieldName];
        if (value === undefined || value === null) {
          content = '';
        } if (render) {
          content = render(value, node);
          if (typeof content === 'string') {
            content = <span className={classes.longText}>{content}</span>;
          }
        } else {
          console.error(`Could not find render function for ${type}.${fieldName}.`);
          content = '[rendering error]';
        }
      } else {
        content = render(node);
      }

      return (
        <TableCell
          key={fieldName}
          align="left"
          className={classes.bodyCell}
          onClick={() => this.openView(node.id)}
          style={{ cursor: viewDialog || getRowClickUrl ? 'pointer' : 'initial' }}
        >
          {content}
        </TableCell>
      );
    });

    return cells;
  }

  toggleOrderByDirection = (direction) => {
    const { firstOrderByDirection } = this.props;

    switch (direction) {
      case 'asc': return 'desc';
      case 'desc': return 'asc';
      case 'asc_nulls_first': return 'desc_nulls_first';
      case 'desc_nulls_first': return 'asc_nulls_first';
      case 'asc_nulls_last': return 'desc_nulls_last';
      case 'desc_nulls_last': return 'asc_nulls_last';
      case null:
      default:
        return firstOrderByDirection;
    }
  }

  handleOrderByField = field => () => {
    const { setVariables } = this.context;

    setVariables(({ orderBy }) => {
      const currentDirection = Table.fieldOrderByDirection(field, orderBy);
      const newDirection = this.toggleOrderByDirection(currentDirection);

      // Remove all orderBy elements that have this field name
      _.remove(orderBy, v => !!v[field]);
      // Add the new orderBy element at the beginning of array
      orderBy.unshift({ [`${field}`]: newDirection });

      return { orderBy: JSON.parse(JSON.stringify(orderBy)) };
    });
  }

  filterClauseIsEmpty = (clause) => {
    if (typeof clause === 'object') {
      return _.every(clause, this.filterClauseIsEmpty);
    }

    if (clause === undefined) return true;
    if (clause === '') return true;
    return false;
  }

  fieldHasActiveFilters = (field) => {
    const { variables: { where } } = this.context;
    const clause = _.get(where, field);

    return !this.filterClauseIsEmpty(clause);
  }

  renderHeadCells = () => {
    const { schemaType, variables: { orderBy } } = this.context;
    const { classes } = this.props;

    const cells = this.columns.map(({ fieldName, label, schemaField, isDefaultColumn }) => {
      const fieldHasActiveFilters = isDefaultColumn ? this.fieldHasActiveFilters(fieldName) : false;
      const content = label;
      const orderByDirection = isDefaultColumn ? Table.fieldOrderByDirection(fieldName, orderBy) : null;

      return (
        <TableCell className={classes.headCell} key={fieldName} align="left">
          {isDefaultColumn && schemaField.isScaler ? (
            <Tooltip title="Sort" placement="bottom-start" enterDelay={300}>
              <TableSortLabel
                active={!!orderByDirection}
                direction={_.startsWith(orderByDirection, 'asc') ? 'asc' : 'desc'}
                onClick={this.handleOrderByField(fieldName)}
              >
                {content}
              </TableSortLabel>
            </Tooltip>
          ) : <span className={classes.nonSortingHeadCellContent}>{content}</span>}
          {isDefaultColumn && schemaField.filters.length ? (
            <FilterDialog
              field={fieldName}
              trigger={(
                <Tooltip title="Apply filters" placement="bottom-start" enterDelay={300}>
                  <IconButton className={classes.filterButton} color={fieldHasActiveFilters ? 'secondary' : undefined}><FilterIcon /></IconButton>
                </Tooltip>
              )}
            />
          ) : null}
        </TableCell>
      );
    });

    if (this.hasRowActions) {
      cells.push((
        <TableCell className={classes.headCell} key="actions" align="center" />
      ));
    }

    return cells;
  }

  renderLoader = (loading) => {
    if (!loading) return null;
    const { classes } = this.props;

    return (
      <div className={classes.loadingScreen}>
        <CircularProgress size={50} thickness={5} color="primary" />
      </div>
    );
  }

  closeView = () => {
    this.setState({ viewNodeId: null });
  }

  openView = (nodeId) => {
    this.setState({ viewNodeId: nodeId });
  }

  closeEditor = () => {
    this.setState({ editNode: null });
  }

  openEditor = (node) => {
    this.setState({ editNode: node });
  }

  renderEmpty = () => {
    const { topLevelSelections, schemaType } = this.context;

    return (
      <TableRow>
        <TableCell colSpan={topLevelSelections.length}>
          <Typography variant="body1" align="center">
            <br />
            No {schemaType.pluralLabel} found.<br />
            Try adjusting your filters or search criteria.
            <br />
            <br />
            <br />
          </Typography>
        </TableCell>
      </TableRow>
    );
  }

  handleRowClick = node => () => {
    const { getRowClickUrl, history } = this.props;
    if (getRowClickUrl) {
      const url = getRowClickUrl(node);
      history.push(url);
    }
  }

  render() {
    const { nodes, result: { loading } } = this.context;
    const { viewNodeId, editNode } = this.state;
    const { actions, containerComponent, className, viewDialog, height, classes, editDialog } = this.props;

    const viewDialogWithProps = viewDialog ? React.cloneElement(viewDialog, {
      nodeId: viewNodeId,
      open: !!viewNodeId,
      onClose: this.closeView,
    }) : null;

    const editDialogWithProps = editDialog ? React.cloneElement(editDialog, {
      node: editNode,
      open: !!editNode,
      onClose: this.closeEditor,
      onNodeChange: this.openEditor,
    }) : null;

    const ContainerComponent = containerComponent;

    return (
      <>
        {viewDialogWithProps}
        {editDialogWithProps}
        <ContainerComponent className={classNames(classes.root, className)}>
          <div className={classes.tableWrapper} style={{ maxHeight: height }}>
            {this.renderLoader(loading)}
            {actions ? (
              <div className={classes.tableActions}>
                {actions}
              </div>
            ) : null}
            <MuiTable className={classes.table}>
              <TableHead className={classes.head}>
                <TableRow className={classes.headRow}>
                  {this.renderHeadCells()}
                </TableRow>
              </TableHead>
              <TableBody className={classes.body}>
                {(!nodes || !nodes.length) ? this.renderEmpty() : null}
                {(nodes && nodes.length) ? nodes.map(this.renderRow) : null}
              </TableBody>
            </MuiTable>
          </div>
          <PageControls />
        </ContainerComponent>
      </>
    );
  }
}

Table = compose(
  withStyles(styles),
  withRouterWorkaround,
)(Table);
export default Table;
